import React from "react";
import Box from "../../../ProductView/Common/Box/Box";
import Common from "../Common/Common";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../../../Store";
import {changeActiveProductsByBrand, changeActiveProductsByCategory} from "../../../../ActionCreators/Admin";

const DeactivateProducts: React.FC = () => {
    const dispatch = useDispatch<ThunkDispatch<AppState, never, never>>();

    return <Box title={"Dezaktywywacja produtków"}>
        <Common
            texts={{
                byCategory: "Czy na pewno chcesz zdezaktywować wszystkie produkty z tej kategorii?",
                byBrand: "Czy na pewno chcesz zdezaktywować wszystkie produkty z tej marki?"
            }}
            icon={<RemoveCircleOutlineIcon/>}
            color={"default"}
            actions={{
                byCategory: async (id) => await dispatch(changeActiveProductsByCategory(id, false)),
                byBrand: async (id) => await dispatch(changeActiveProductsByBrand(id, false)),
            }}
        />
    </Box>
}

export default DeactivateProducts;