import React, {Component} from "react";
import styles from "./UserForm.module.scss";
import {TextFieldNotConnected} from "../../../ProductView/Common/TextField/TextField";
import {CheckboxNotConnected} from "../../../ProductView/Common/Checkbox/Checkbox";
import {UserData} from "../../../../ActionCreators/User";

interface Props {
    userData?: UserData,
    type: "ADD" | "EDIT",
    onChange: (user: UserData) => void,
    disabled?: boolean
}

class UserForm extends Component<Props> {

    private onChange = (value: boolean | string | undefined, field: "login" | "password" | "admin") => {
        this.props.onChange({
            ...this.props.userData || {},
            [field]: value
        })
    };

    public render = (): JSX.Element =>
        <div className={styles.userForm}>
            {this.props.type === "ADD" &&
            <TextFieldNotConnected
                autoFocus={this.props.type === "ADD"}
                label={"Podaj login"}
                disabled={this.props.disabled}
                value={this.props.userData?.login}
                onChange={value => this.onChange(value, "login")}
                type={"text"}
            />}
            <TextFieldNotConnected
                label={this.props.type === "ADD" ? "Podaj hasło" : "Podaj nowe hasło (opcjonalne)"}
                value={this.props.userData?.password}
                disabled={this.props.disabled}
                onChange={value => this.onChange(value, "password")}
                type={"text"}
            />
            <CheckboxNotConnected
                value={this.props.userData?.admin}
                label={"Administrator"}
                disabled={this.props.disabled}
                onChange={value => this.onChange(value, "admin")}
            />
        </div>
}

export default UserForm;