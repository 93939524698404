import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../../../../Store";
import _ from "lodash";
import {updateValue} from "../../../../ActionCreators/ProductData";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import "./autocompleteSelectField.scss";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {CircularProgress} from "@material-ui/core";

interface ExternalProps {
    path: string,
    label: string | JSX.Element,
    optionsProvider: () => Promise<any>,
    optionsMapper: (item: any, index?: number, array?: any) => unknown,
    disableRemove?: boolean,
    multi?: boolean,
    onNewItem?: (name: string) => Promise<any>,
    disabledByPath?: string,
    renderOption?: (item: any) => JSX.Element,
    invalid?: boolean
}

interface StateProps {
    value: any,
    label: string | JSX.Element,
    optionsProvider: () => Promise<any>,
    optionsMapper: (item: any, index?: number, array?: any) => any,
    disableRemove?: boolean,
    multi?: boolean,
    onNewItem?: (name: string) => Promise<any>,
    disabled?: boolean,
    renderOption?: (item: any) => JSX.Element,
    invalid?: boolean
}

interface DispatchProps {
    onChange: (value: any) => void
}

type AutocompleteSelectFieldProps = StateProps & DispatchProps;

type OptionType = {
    [key: string]: any
}

interface AutocompleteSelectFieldState {
    loaded: boolean,
    options: OptionType[]
}

class AutocompleteSelectField extends Component<AutocompleteSelectFieldProps, AutocompleteSelectFieldState> {
    constructor(props: AutocompleteSelectFieldProps) {
        super(props);

        this.state = {
            loaded: false,
            options: (this.props.multi ? this.props.value || [] : [this.props.value]).filter(Boolean).map(this.props.optionsMapper)
        };
    }

    async componentDidMount() {
        await this.updateOptions();
    }

    private updateOptions = async () => {
        this.setState({
            loaded: false
        });
        const result = await this.props.optionsProvider();
        const options = result.map(this.props.optionsMapper);
        this.setState({
            options,
            loaded: true
        });
    }

    private filterOptions = (options: OptionType[], params: any): OptionType[] => {
        const filtered = options.filter(option => option.label.toLowerCase().includes(params.inputValue.toLowerCase()));
        if (!filtered.length) {
            filtered.push({
                inputValue: params.inputValue,
                label: `Dodaj "${params.inputValue}"`,
                value: "NEW"
            });
        }
        return filtered;
    };

    private onChange = async (ev: any, newValue: OptionType, onChange: any): Promise<void> => {
        if (newValue?.value === "NEW") {
            const item = await this.props.onNewItem?.(newValue.inputValue);
            if (item) {
                newValue = {value: item};
                await this.updateOptions();
                onChange(ev, newValue);
            }
            return;
        }

        onChange(ev, newValue);
    };

    public render = () => {

        let props: any;
        if (this.props.multi) {
            props = {
                multiple: true,
                value: this.state.options.filter(option => (this.props.value || []).find((selected: any) => selected._id === option.value?._id)),
                onChange: (event: any, newValue: any) => this.props.onChange(newValue?.map((newItems: any) => newItems.value))
            }
        } else {
            props = {
                value: this.state.options.find(option => option.value?._id === this.props.value?._id) || {},
                onChange: (event: any, newValue: any) => this.props.onChange(newValue?.value)
            }
        }
        return <Autocomplete
            className={"autocomplete"}
            options={this.state.options}
            getOptionLabel={option => {
                // @ts-ignore
                return option.label || ""
            }}
            noOptionsText={"Brak opcji"}
            disableClearable={this.props.disableRemove}
            loading={!this.state.loaded}
            disabled={this.props.disabled}
            onOpen={() => this.updateOptions()}
            renderOption={this.props.renderOption}
            renderInput={(params) =>
                <TextField {...params}
                           label={this.props.label}
                           variant="outlined"
                           error={this.props.invalid}
                           size={"small"}
                           InputProps={{
                               ...params.InputProps,
                               endAdornment: (
                                   <React.Fragment>
                                       {!this.state.loaded ? <CircularProgress color="inherit" size={20}/> : null}
                                       {params.InputProps.endAdornment}
                                   </React.Fragment>
                               ),
                           }}
                />
            }
            filterOptions={this.props.onNewItem ? (options, params) => this.filterOptions(options as OptionType[], params) : undefined}
            {...props}
            onChange={this.props.onNewItem ? (ev, value) => this.onChange(ev, value as any, props.onChange) : props.onChange}
        />
    }
}

export const AutocompleteSelectFieldNotConnected = AutocompleteSelectField;

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    value: _.get(state.ProductData.productData, externalProps.path),
    label: externalProps.label,
    optionsMapper: externalProps.optionsMapper,
    optionsProvider: externalProps.optionsProvider,
    disableRemove: externalProps.disableRemove,
    multi: externalProps.multi,
    onNewItem: externalProps.onNewItem,
    disabled: externalProps.disabledByPath ? !_.get(state.ProductData.productData, externalProps.disabledByPath) : false,
    renderOption: externalProps.renderOption,
    invalid: externalProps.invalid
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onChange: (value: any) => dispatch(updateValue(externalProps.path, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteSelectField)