import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../../../../Store";
import _ from "lodash";
import {updateValue} from "../../../../ActionCreators/ProductData";
import {Checkbox as MaterialCheckbox, FormControlLabel} from "@material-ui/core";
import "./checkbox.scss";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";

interface ExternalProps {
    path: string,
    label?: string,
    disabled?: boolean,
    className?: string,
    indeterminate?: boolean,
    size?: 'small' | 'medium'
}

interface StateProps {
    value: any,
    label?: string,
    disabled?: boolean,
    className?: string,
    indeterminate?: boolean,
    size?: 'small' | 'medium'
}

interface DispatchProps {
    onChange: (value: any) => void
}

type CheckboxProps = StateProps & DispatchProps;

class Checkbox extends Component<CheckboxProps> {
    public render = () =>
        <FormControlLabel
            className={`checkbox ${this.props.className || ""}`}
            disabled={this.props.disabled}
            control={
                <MaterialCheckbox
                    disabled={this.props.disabled}
                    size={this.props.size || "small"}
                    checked={!!this.props.value}
                    indeterminate={this.props.indeterminate}
                    onChange={event => this.props.onChange(event.target.checked ? true : undefined)}
                />}
            label={this.props.label}
        />

}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    value: _.get(state.ProductData.productData, externalProps.path, ""),
    ...externalProps
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onChange: (value: any) => dispatch(updateValue(externalProps.path, value))
});

export const CheckboxNotConnected = Checkbox;

export default connect(mapStateToProps, mapDispatchToProps)(Checkbox)