import React, {Component} from "react";
import {Button} from "@material-ui/core";
import {connect} from "react-redux";
import {AppState} from "../../Store";
import {revertBrandChanges, saveBrand} from "../../ActionCreators/BrandData";

type DispatchProps = {
    revertBrandChanges: () => void,
    saveBrand: () => void
}

type StateProps = {
    brandLoaded: boolean,
    brandId: string,
    isSaving: boolean,
    changesDetected: boolean
}

type ProductControlsProps = DispatchProps & StateProps;

class BrandControls extends Component<ProductControlsProps> {

    public render = (): JSX.Element | null =>
        this.props.brandLoaded ?
            <div className={"product-controls"}>
                <Button
                    variant="contained"
                    disabled={!this.props.changesDetected}
                    onClick={this.props.isSaving ? undefined : this.props.saveBrand}
                >
                    {this.props.isSaving ? "Zapisuję" : "Zapisz"}
                </Button>
                <Button
                    disabled={!this.props.changesDetected || this.props.isSaving}
                    variant="contained"
                    color={"secondary"}
                    onClick={this.props.revertBrandChanges}>
                    Odrzuć
                </Button>
            </div> : null;
}

const mapStateToProps = (state: AppState): StateProps => ({
    brandLoaded: state.BrandData.brandData !== undefined,
    brandId: state.BrandData.brandData?._id || "",
    isSaving: state.BrandData.saveInProgress,
    changesDetected: state.BrandData.changesDetected
});

const mapDispatchToProps: DispatchProps = ({
    revertBrandChanges,
    saveBrand
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandControls);

