import React, {Component} from 'react'
import {Dictionary, ProductAttribute, ProductAttributeType} from "mesmetric-v2-common/models";
import _ from "lodash";
import {AppState} from "../../../../Store";
import {connect} from "react-redux";
import AttributeDictionary from "../../Common/AttributeDictionary/AttributeDictionary";
import AttributeSize from "../../Common/AttributeSize/AttributeSize";

interface ExternalProps {
    path: string
    onDictionaryUpdated: (attributeId: number, value: Dictionary) => void
    attributesProvided: ProductAttribute.Any[]
}

interface StateProps {
    path: string
    attributesProvided: ProductAttribute.Any[]
    attributes: any
    onDictionaryUpdated: (attributeId: number, value: Dictionary) => void
}

class Attributes extends Component<StateProps> {
    private onDictionaryItemAdded = (attributeIndex: number, value: any): any => {
        value.type = ProductAttributeType.Dictionary;
        this.props.onDictionaryUpdated(attributeIndex, value)
    };

    render = (): JSX.Element => {
        return <div className={"attributes"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"attributes-elements"}>
                        {this.props.attributesProvided?.map((providedAttribute, index) => {
                                if (providedAttribute.type === ProductAttributeType.Dictionary) {
                                    return <AttributeDictionary
                                        markWhenIncomplete
                                        key={index}
                                        index={index}
                                        providedAttribute={providedAttribute as ProductAttribute.Dictionary}
                                        path={`${this.props.path}.${providedAttribute._id}`}
                                        onDictionaryItemAdded={this.onDictionaryItemAdded}/>
                                } else {
                                    return <AttributeSize
                                        key={index}
                                        index={index}
                                        providedAttribute={providedAttribute as ProductAttribute.Size}
                                        path={`${this.props.path}.${providedAttribute._id}`}/>
                                }
                            }
                        )}
                    </div>
                </div>
            </div>
        </div>

    }
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => {
    return ({
        attributes: _.get(state.ProductData.productData, externalProps.path),
        attributesProvided: externalProps.attributesProvided,
        path: externalProps.path,
        onDictionaryUpdated: externalProps.onDictionaryUpdated
    });
};

export default connect(mapStateToProps, {})(Attributes);