import React, {useEffect, useState} from "react";
import {AutocompleteSelectFieldNotConnected} from "../../ProductView/Common/AutocompleteSelectField/AutocompleteSelectField";
import {ProductElement, ProductElementVariant} from "mesmetric-v2-common/models";
import Dictionary from "../../../DataProviders/Dictionary";
import {multiLanguageNameMapper} from "../../../Utils/DataUtils";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../../Store";
import TranslationInputs from "./TranslationInputs/TranslationInputs";
import {AutoSizer, List} from "react-virtualized";
import styles from "./ProductElementsDictionary.module.scss";
import {
    findElementsVariants,
    removeProductElements,
    removeProductElementsVariants,
    saveProductElements,
    saveProductElementsVariants
} from "../../../ActionCreators/ProductElements";
import commonStyles from "../../CommonComponents/CommonStyles.module.scss";
import {Button, LinearProgress} from "@material-ui/core";
import AbsoluteSpinner from "../../../Components/UI/AbsoluteSpinner/AbsoluteSpinner";
import DeleteIcon from "@material-ui/icons/Delete";
import {cls} from "../../../Utils/StyleUtils";

const ProductElementsDictionary: React.FC = () => {
    const [saving, setSaving] = useState(false);
    const [variantsPerElement, setVariantsPerElement] = useState<Map<ProductElement, ProductElementVariant[]>>();
    const [elementsIdsToRemove, setElementsIdsToRemove] = useState<Set<string>>(new Set());
    const [variantsIdsToRemove, setVariantsIdsToRemove] = useState<Set<string>>(new Set());
    const [_, forceRerender] = useState({});
    useEffect(() => {
        (async () => fetchData())()
    }, []);

    const fetchData = async (id?: string) => {
        const map: Map<ProductElement, ProductElementVariant[]> = new Map();
        const productElements = await Dictionary.getProductsElements();
        for (let i = 0; i < productElements.length; i++) {
            const productElement = productElements[i];
            const variants = await dispatch(findElementsVariants(productElement));
            if (variants) {
                map.set(productElement, variants);
            }
        }
        setVariantsPerElement(map);
        if (id) {
            setProductElement(productElements.find(el => el._id === id));
        }
    }

    const [productElement, setProductElement] = useState<ProductElement | undefined>();
    const dispatch = useDispatch<ThunkDispatch<AppState, never, never>>();

    if (!variantsPerElement) {
        return <AbsoluteSpinner noBackground/>
    }

    const variants = (productElement ? variantsPerElement.get(productElement) || [] : []).filter(variant => !Array.from(variantsIdsToRemove).some(id => id === variant._id));
    const elements = Array.from(variantsPerElement.keys()).filter(el => !Array.from(elementsIdsToRemove).some(id => id === el._id));
    const noTranslations = elements.some(el => !el.name.en) || variants.some(va => !va.name.en);

    const renderLabel = () => <>
        Wybierz element produktu{noTranslations ?
        <span className={commonStyles.incompleteOption}>(Brak tłumaczeń)</span> : ""}</>

    const renderOption = (item: any) => {
        const variants = variantsPerElement.get(item.value) || [];
        const noTranslations = !item.value.name.en || variants.some(va => !va.name.en);
        return <>
            {item.value.name.pl}
            {noTranslations ? <span className={commonStyles.incompleteOption}>(Brak tłumaczeń)</span> : ""}
        </>;
    }

    const saveEverything = async () => {
        setSaving(true);
        await dispatch(saveProductElements(elements, false));
        await dispatch(saveProductElementsVariants(Array.from(variantsPerElement.values()).flat().filter(variant => !Array.from(variantsIdsToRemove).some(id => id === variant._id))));
        await dispatch(removeProductElements(Array.from(elementsIdsToRemove), true));
        await dispatch(removeProductElementsVariants(Array.from(variantsIdsToRemove)));
        setSaving(false);
        const currentSelectedId = productElement?._id;
        setProductElement(undefined);
        setVariantsPerElement(undefined);
        await fetchData(currentSelectedId)
    }

    return <>
        <>
            <div className={"row"}>
                <div className={"col-4"}>
                    <AutocompleteSelectFieldNotConnected
                        label={renderLabel()}
                        disabled={saving}
                        onChange={item => setProductElement(item)}
                        value={productElement}
                        invalid={noTranslations}
                        optionsMapper={multiLanguageNameMapper}
                        renderOption={renderOption}
                        optionsProvider={async () => elements}
                    />
                </div>
                {productElement &&
                <Button
                    size={"small"}
                    variant={"contained"}
                    color={"secondary"}
                    title={"Usuń"}
                    className={cls(styles.buttonWithIcon, styles.removeElementButton)}
                    onClick={() => {
                        const variantsToRemove = new Set(variantsIdsToRemove);
                        variants.forEach(v => variantsToRemove.add(v._id || ""));
                        setVariantsIdsToRemove(variantsToRemove);
                        const elementsToRemove = new Set(elementsIdsToRemove);
                        elementsToRemove.add(productElement?._id || "");
                        setElementsIdsToRemove(elementsToRemove)
                        setProductElement(undefined);
                    }}
                ><DeleteIcon/>
                </Button>}
                <div className={"col-4"}>
                    {productElement &&
                    <TranslationInputs
                        label={productElement.name}
                        disabled={saving}
                        onChange={label => {
                            if (productElement) {
                                productElement.name = label;
                                forceRerender({});
                            }
                        }}
                    />}
                </div>

            </div>
            {productElement && <div className={styles.title}>{variants.length ? "Warianty" : "Brak wariantów"}</div>}
            {variants.length ? <>
                <div className={styles.variants}>
                    <AutoSizer>
                        {({height, width}) => (
                            <List
                                rowCount={variants.length}
                                rowHeight={65}
                                width={width}
                                height={height}
                                rowRenderer={props =>
                                    <div
                                        key={props.key}
                                        className={styles.row}
                                        style={props.style}>
                                        <TranslationInputs
                                            disabled={saving}
                                            className={styles.translation}
                                            label={variants[props.index].name}
                                            onChange={label => {
                                                variants[props.index].name = label;
                                                forceRerender({});
                                            }}
                                        />
                                        <Button
                                            size={"small"}
                                            variant={"contained"}
                                            color={"secondary"}
                                            title={"Usuń"}
                                            className={cls(styles.buttonWithIcon, styles.removeButton)}
                                            onClick={() => {
                                                variantsIdsToRemove.add(variants[props.index]._id || "");
                                                setVariantsIdsToRemove(new Set(variantsIdsToRemove));
                                            }}
                                        ><DeleteIcon/>
                                        </Button>
                                    </div>}/>
                        )}
                    </AutoSizer>
                </div>
            </> : <div className={styles.spacer}/>}
            {saving && <LinearProgress className={styles.button}/>}
            {!saving &&
            <Button
                variant={"contained"}
                color={"primary"}
                className={styles.button}
                onClick={saveEverything}
            >
                Zapisz
            </Button>}

        </>
    </>
};

export default ProductElementsDictionary;