import axios from "axios";
import {getAxiosConfig} from "./User";
import {parseError} from "./Error";
import {ThunkAction} from "redux-thunk";
import {AppState} from "../Store";
import {ItemData} from "../Views/Designers/ItemForm/ItemForm";
import {Designer} from "mesmetric-v2-common/models";

export interface BackendDesigner extends Designer {
    isRemovable?: boolean
}

export enum ActionTypes {
    setDesigners = "setDesigners",
    setIsSearchingDesigners = "setIsSearchingDesigners",
}

export interface SetDesignersAction {
    type: ActionTypes.setDesigners
    designers: BackendDesigner[]
}

export interface SetIsSearchingAction {
    type: ActionTypes.setIsSearchingDesigners,
    isSearching: boolean
}

function setDesigners(designers: BackendDesigner[]): SetDesignersAction {
    return {
        type: ActionTypes.setDesigners,
        designers
    };
}

export const MESSAGES_FROM_SERVER: { [key: string]: string } = {
    DESIGNER_NOT_FOUND: "Nie ma takiego projektanta",
    DESIGNER_HAVE_PRODUCTS: "Istnieją produkty przypisane do tego projektanta"
};

export const fetchDesigners = (): ThunkAction<void, AppState, {}, SetIsSearchingAction | SetDesignersAction> => {
    return async (dispatch: any) => {
        dispatch({
            type: ActionTypes.setIsSearchingDesigners,
            isSearching: true
        });
        dispatch({
            type: ActionTypes.setDesigners,
            designers: []
        });
        try {
            const designers = (await axios.get<BackendDesigner[]>(`${process.env.REACT_APP_DATA_ENDPOINT}/designers`, getAxiosConfig())).data;
            dispatch(setDesigners(designers));
        } catch (e) {
            parseError(e);
        }
        dispatch({
            type: ActionTypes.setIsSearchingDesigners,
            isSearching: false
        });
    }
};

export const getDesigners = (): ThunkAction<Promise<BackendDesigner[]>, AppState, {}, never> =>
    async () => {
        try {
            return (await axios.get(`${process.env.REACT_APP_DATA_ENDPOINT}/designers`, getAxiosConfig())).data;
        } catch (e) {
            parseError(e);
            return []
        }
    };

export const addDesigner = (userData: ItemData): ThunkAction<Promise<void>, AppState, {}, never> =>
    async (dispatch) => {
        try {
            await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/designers/add`, {...userData}, getAxiosConfig());
            dispatch(fetchDesigners());
        } catch (e) {
            parseError(e);
        }
    };

export const editDesigner = (id: string, userData: ItemData): ThunkAction<Promise<void>, AppState, {}, never> =>
    async (dispatch) => {
        try {
            await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/designers/edit/` + id, {...userData}, getAxiosConfig());
            dispatch(fetchDesigners());
        } catch (e) {
            parseError(e);
        }
    };

export const deleteDesigner = (id: string): ThunkAction<Promise<void>, AppState, {}, never> =>
    async (dispatch) => {
        try {
            await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/designers/delete/` + id, {}, getAxiosConfig());
            dispatch(fetchDesigners());
        } catch (e) {
            parseError(e);
        }
    };

export const isDesignerRemovable = (id: string): ThunkAction<Promise<boolean>, AppState, {}, never> =>
    async () => {
        try {
            return (await axios.get(`${process.env.REACT_APP_DATA_ENDPOINT}/designers/isRemovable/` + id, getAxiosConfig())).data;
        } catch (e) {
            parseError(e);
            return false
        }
    };

export type AllActions =
    SetDesignersAction |
    SetIsSearchingAction;