import {ActionTypes, AllActions} from "../ActionCreators/BrandData";
import {BrandDataState, initialState} from "../State/BrandData";
import {cloneDeep} from "lodash";

export default function BrandDataReducer(state: BrandDataState = initialState, action: AllActions): BrandDataState {
    switch (action.type) {
        case ActionTypes.setBrandData: {
            return {
                ...state,
                brandData: action.brandData,
                fetchedBrandData: cloneDeep(action.brandData)
            };
        }
        case ActionTypes.saveInProgress: {
            return {
                ...state,
                saveInProgress: action.saveInProgress
            };
        }
        case ActionTypes.removeBrandData: {
            return {
                ...state,
                brandData: undefined,
                fetchedBrandData: undefined,
                changesDetected: false
            }
        }
        case ActionTypes.updateValue: {
            return {
                ...state,
                brandData: action.brandData
            }
        }
        case ActionTypes.changesDetected: {
            return {
                ...state,
                changesDetected: action.changesDetected
            }
        }
        default:
            return state;
    }
}

