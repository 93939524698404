import React, {Component} from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import {AddCircleRounded, ExpandMore} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import CardContent from "@material-ui/core/CardContent";
import './attributeCard.scss'
import {ProductAttributeVariant} from "mesmetric-v2-common/models";
import {getObjectId} from "../../../../../Common/Utility";

interface Props {
    title?: string
    addButtonHandler?: (variantId: string, value: ProductAttributeVariant) => void,
    groupId?: string
    expanded?: boolean
    isEmptyContent?: boolean
}

interface State {
    contentExpanded: boolean
}

class AttributeGroupCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            contentExpanded: !this.props.isEmptyContent
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps !== this.props) {
            this.setState({
                contentExpanded: !this.props.isEmptyContent
            });
        }
    }

    private handleExpandClick = () => {
        this.setState({
            contentExpanded: !this.state.contentExpanded
        });
    };

    private addVariant = () => {
        // @ts-ignore
        this.props.addButtonHandler?.(getObjectId(), {label: {pl: "", en: ""}});
        if (!this.state.contentExpanded) {
            this.setState({
                contentExpanded: !this.state.contentExpanded
            });
        }
    };

    render = (): JSX.Element => {
        const prettyPrint = (value: string): string => `${value.toLowerCase().charAt(0).toUpperCase()}${value.slice(1).toLowerCase()}`;
        return (
            <Card className={"card"}>
                <CardHeader
                    className={"card-header"}
                    action={
                        <>
                            {this.props.addButtonHandler &&
                            <IconButton
                                className={"add"}
                                onClick={() => this.addVariant()}
                                aria-label="add">
                                <AddCircleRounded/>
                            </IconButton>}
                            {!this.props.isEmptyContent && <IconButton
                                className={this.state.contentExpanded ? "expandOpen" : "expand"}
                                onClick={this.handleExpandClick}
                                aria-expanded={this.state.contentExpanded}
                                aria-label="show more">
                                <ExpandMore/>
                            </IconButton>}
                        </>
                    }
                    title={this.props.title && prettyPrint(this.props.title)}
                />
                <Collapse in={this.state.contentExpanded} timeout="auto" unmountOnExit>
                    <CardContent className={"card-content"}>
                        {this.props.children}
                    </CardContent>
                </Collapse>
            </Card>
        );
    }
}

export default AttributeGroupCard