import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../../../Store";
import {Category} from "mesmetric-v2-common/models";
import styles from "./Categories.module.scss";
import {CheckboxNotConnected} from "../../ProductView/Common/Checkbox/Checkbox";
import {addCategories, removeCategories} from "../../../ActionCreators/Categories";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";

interface StateProps {
    categories?: Category[],
    selected: string[]
}

interface DispatchProps {
    addCategories: (ids: string[]) => void,
    removeCategories: (ids: string[]) => void
}

type Props = StateProps & DispatchProps;

const sortCategories = (category1: Category, category2: Category) => category1.name.pl.localeCompare(category2.name.pl);

class Categories extends Component<Props> {

    private isSelected = (category: Category): boolean | undefined => {
        if (!category.children?.length) {
            return this.props.selected.includes(category._id as string);
        }

        const allSelected = !category.children.some(category => !this.isSelected(category as Category));
        if (allSelected) {
            return true;
        }
        const noneSelected = !category.children.some(category => this.isSelected(category as Category) === true || this.isSelected(category as Category) === undefined);

        return noneSelected ? false : undefined;
    };

    private getIdWhenPrimary = (ids: string[], category: Category): void => {
        if (!category.children?.length) {
            ids.push(category._id as string);
            return;
        }
        category.children.forEach(category => this.getIdWhenPrimary(ids, category as Category));
    };

    private selectCategory = (select: boolean, category: Category) => {
        const ids: string[] = [];
        this.getIdWhenPrimary(ids, category);
        if (select) {
            this.props.addCategories(ids);
        } else {
            this.props.removeCategories(ids);
        }
    };

    private renderCategory = (category: Category, indent: number) => {
        const selected = this.isSelected(category);
        const indeterminate = selected === undefined;
        return <React.Fragment key={category._id}>
            <CheckboxNotConnected
                className={styles.category + " " + styles["indent" + indent]}
                label={category.name.pl}
                indeterminate={indeterminate}
                onChange={() => this.selectCategory(!selected || indeterminate, category)}
                value={selected}
            />
            {category.children?.sort((c1, c2) => sortCategories(c1 as Category, c2 as Category)).map(child => this.renderCategory(child as Category, indent + 1))}
        </React.Fragment>;
    };

    public render = (): JSX.Element =>
        <div className={styles.categories}>
            {this.props.categories?.sort((c1, c2) => sortCategories(c1 as Category, c2 as Category)).map((category) => this.renderCategory(category, 0))}
        </div>;
}

const mapStateToProps = (state: AppState) => ({
    categories: state.Categories.tree,
    selected: state.ProductsFilters.categories || []
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    addCategories: (ids) => dispatch(addCategories(ids)),
    removeCategories: ids => dispatch(removeCategories(ids))
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);