import {ActionTypes, AllActions} from "../ActionCreators/Notifications";
import {initialState, NotificationsState} from "../State/Notifications";

export default function Notifications(state = initialState, action: AllActions): NotificationsState {
    switch (action.type) {
        case ActionTypes.addNotification: {
            return {
                ...state,
                notifications: [...state.notifications, action.notification]
            }
        }
        case ActionTypes.removeNotification: {
            return {
                ...state,
                notifications: state.notifications.filter(el => el !== action.notification)
            }
        }
        default:
            return state;
    }
}

