import React, {Component} from "react";
import CheckboxButton from "../../Common/CheckboxButton/CheckboxButton";
import "./controls.scss";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloneProductPopper from "./CloneProductPopper";
import DeleteItem from "../../../../Components/DeleteItem/DeleteItem";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../../../Store";
import {Action} from "redux";
import {searchProducts} from "../../../../ActionCreators/Products";
import {connect} from "react-redux";
import axios from "axios";
import {Product} from "mesmetric-v2-common/models";
import {getAxiosConfig} from "../../../../ActionCreators/User";
import {parseError} from "../../../../ActionCreators/Error";

interface ControlProps {
    productId: string
}

interface DispatchProps {
    searchProducts: any
}

class Controls extends Component<ControlProps & DispatchProps> {
    public render = (): JSX.Element =>
        <div className={"controls"}>
            <CheckboxButton
                path={"isActive"}
                label={"Aktywny"}
            />
            <CloneProductPopper
                productId={this.props.productId}
                customIcon={<FileCopyIcon/>}
            />
            <DeleteItem onConfirm={async () => {
                try {
                    await axios.delete<Product>(`${process.env.REACT_APP_DATA_ENDPOINT}/products/${this.props.productId}`, getAxiosConfig());
                    this.props.searchProducts();
                    window.history.back();
                } catch (e) {
                    parseError(e);
                }
            }}/>
        </div>
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>) => ({
    searchProducts: () => dispatch(searchProducts())
});

export default connect(null, mapDispatchToProps)(Controls);