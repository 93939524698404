import axios from "axios";
import {getAxiosConfig} from "./User";
import {parseError} from "./Error";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {AppState} from "../Store";
import {ItemData} from "../Views/Products/ItemForm/ItemForm";
import {getObjectId} from "../Common/Utility";
import {Product, ProductsFilters} from "mesmetric-v2-common/models";
import _, {debounce} from "lodash";
import {LocalStorageKey} from "../Utils/StorageUtils";

export type ViewType = "TABLE" | "GRID";
export const LIMIT = 250;

// @ts-ignore
export const FAKE_PRODUCT: Product = {
    _id: "fake"
};

export const NOT_FETCHED = "notFetched";
export const TO_BE_FETCHED = "toBeFetched";

export enum ActionTypes {
    setViewType = "setViewType",
    setIsSearchingProducts = "setIsSearchingProducts",
    setProducts = "setProducts",
    setHiddenColumns = "setHiddenColumns"
}

export interface SetViewTypeAction {
    type: ActionTypes.setViewType
    viewType: ViewType
}

export interface SetIsSearchingAction {
    type: ActionTypes.setIsSearchingProducts,
    isSearching: boolean
}

export interface SetProductsAction {
    type: ActionTypes.setProducts,
    products: Product[],
    count: number | undefined
}

export interface SetHiddenColumnsAction {
    type: ActionTypes.setHiddenColumns,
    hiddenColumns: (keyof ProductsFilters)[]
}

export type AllActions =
    SetViewTypeAction |
    SetIsSearchingAction |
    SetProductsAction |
    SetHiddenColumnsAction;

let CHECKED_RANGE: { startIndex: number, endIndex: number }[] = [];

export const addProduct = (data: ItemData): ThunkAction<Promise<string | void>, AppState, {}, never> =>
    async () => {
        try {
            const newProductId = getObjectId();
            //@ts-ignore
            const product: Product = {_id: newProductId, isActive: true, primaryCategory: data.categoryId};
            const result = await axios.post(process.env.REACT_APP_DATA_ENDPOINT + '/products', product, getAxiosConfig());
            return newProductId;
        } catch (e) {
            parseError(e);
        }
    };


export const setViewType = (viewType: ViewType): ThunkAction<void, AppState, {}, SetViewTypeAction> =>
    (dispatch, getState) => {
        if (getState().Products.viewType !== viewType) {
            if (viewType !== "TABLE") {
                try {
                    localStorage.setItem(LocalStorageKey.VIEW_TYPE, viewType);
                } catch (e) {
                    parseError(new Error("Nie udało się zachować wybranego widoku."))
                }
            } else {
                localStorage.removeItem(LocalStorageKey.VIEW_TYPE);
            }
            dispatch({
                type: ActionTypes.setViewType,
                viewType
            })
        }
    };

export const setHiddenColumns = (hiddenColumns: (keyof ProductsFilters)[]): ThunkAction<void, AppState, {}, SetHiddenColumnsAction> =>
    (dispatch, getState) => {
        if (hiddenColumns.length) {
            try {
                localStorage.setItem(LocalStorageKey.HIDDEN_COLUMNS, JSON.stringify(hiddenColumns));
            } catch (e) {
                parseError(new Error("Nie udało się zachować informacji o aktualnie wyranych kolumnach."))
            }
        } else {
            localStorage.removeItem(LocalStorageKey.HIDDEN_COLUMNS);
        }
        dispatch({
            type: ActionTypes.setHiddenColumns,
            hiddenColumns
        })
    };

export const search = async (filters: ProductsFilters, limit?: number, skip?: number, id?: string) => {
    const response = await axios.post(process.env.REACT_APP_DATA_ENDPOINT + '/products/search/', {
        filters,
        limit,
        skip,
        id
    }, getAxiosConfig());
    return response.data;
};

export const updateProductInList = (id: string): ThunkAction<void, AppState, {}, SetIsSearchingAction | SetProductsAction> =>
    async (dispatch, getState) => {
        const {products: foundProducts, count} = await search({}, LIMIT, undefined, id);
        if (foundProducts.length === 1) {
            const products = getState().Products.products;
            const index = products.findIndex(el => el._id === id);
            if (index >= 0) {
                products[index] = foundProducts[0]
                dispatch({
                    type: ActionTypes.setProducts,
                    products: [...products],
                    count: getState().Products.count
                });
            }
        }
    };

export const fetchMoreProducts = (startIndex: number, endIndex: number): ThunkAction<void, AppState, {}, SetIsSearchingAction | SetProductsAction> =>
    async (dispatch, getState) => {
        if (CHECKED_RANGE.some(range => range.startIndex === startIndex && range.endIndex === endIndex)) {
            return
        } else {
            CHECKED_RANGE.push({startIndex, endIndex});
        }
        const products = [...getState().Products.products];
        const emptyStart = [];
        const filters = getState().ProductsFilters;

        for (let i = startIndex; i <= endIndex; i = i + LIMIT) {
            if (products[i]._id === NOT_FETCHED) {
                emptyStart.push(i);
            }
        }
        if (!emptyStart.length) {
            return;
        }
        emptyStart.forEach(start => {
            for (let i = start; i < (start + LIMIT > endIndex ? endIndex : start + LIMIT); i++) {
                products[i]._id = TO_BE_FETCHED;
            }
        });
        dispatch({
            type: ActionTypes.setProducts,
            products: products,
            count: getState().Products.count
        });
        for (let i = 0; i < emptyStart.length; i++) {
            try {
                const {products: fetchedProducts, count} = await search(filters, LIMIT, emptyStart[i]);
                if (filters === getState().ProductsFilters) {
                    const newProducts = [...getState().Products.products];
                    newProducts.splice(emptyStart[i], LIMIT, ...fetchedProducts);
                    dispatch({
                        type: ActionTypes.setProducts,
                        products: newProducts,
                        count
                    });
                }
            } catch (e) {
                parseError(new Error("Zbyt wiele wczytanych produtków"));
            }
        }

    };

const debouncedSearch = debounce(async (dispatch: ThunkDispatch<AppState, {}, SetIsSearchingAction | SetProductsAction>, getState: () => AppState) => {
    const filter = getState().ProductsFilters;
    CHECKED_RANGE = [];
    if (getState().Products.products.length) {
        dispatch({
            type: ActionTypes.setProducts,
            products: [],
            count: undefined
        })
    }
    dispatch({
        type: ActionTypes.setIsSearchingProducts,
        isSearching: true
    });
    try {
        const {products, count} = await search(filter, LIMIT);
        if (count > LIMIT) {
            _.range(LIMIT, count).map(product => products.push({_id: NOT_FETCHED}));
        }
        dispatch({
            type: ActionTypes.setProducts,
            products,
            count
        });
    } catch (e) {
        parseError(e);
    } finally {
        dispatch({
            type: ActionTypes.setIsSearchingProducts,
            isSearching: false
        });
    }
}, 500);

export const searchProducts = (): ThunkAction<void, AppState, {}, SetIsSearchingAction | SetProductsAction> =>
    (dispatch, getState) => {
        debouncedSearch(dispatch, getState);
    };

