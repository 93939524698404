import {ThunkAction} from "redux-thunk";
import {AppState} from "../Store";
import {searchProducts} from "./Products";
import {LocalStorageKey} from "../Utils/StorageUtils";
import {parseError} from "./Error";
import {ProductsFilters} from "mesmetric-v2-common/models";

export type ViewType = "TABLE" | "GRID";

export enum ActionTypes {
    setProductsFilter = "setProductsFilter"
}

export interface SetProductsFilterAction {
    type: ActionTypes.setProductsFilter,
    field: keyof ProductsFilters,
    value?: string | number | boolean | string[]
}

export type AllActions =
    SetProductsFilterAction


export const setFilter = (field: keyof ProductsFilters, value?: string | number | boolean | string[]): ThunkAction<void, AppState, {}, SetProductsFilterAction> =>
    (dispatch, getState) => {
        dispatch({
            type: ActionTypes.setProductsFilter,
            field,
            value
        });
        const filters = getState().ProductsFilters;
        if (Object.values(filters).filter(value => value !== undefined).length) {
            try {
                localStorage.setItem(LocalStorageKey.PRODUCTS_FILTERS, JSON.stringify(getState().ProductsFilters));
            } catch (e) {
                parseError(new Error("Nie udało się zachować dodanego filtru."))
            }
        } else {
            localStorage.removeItem(LocalStorageKey.PRODUCTS_FILTERS);
        }
        dispatch(searchProducts())
    };



