import React, {Component} from "react";
import {PhotoPaddingDetails} from "../PhotoCrop";
import {PhotoCropDetails} from "../Cropper/Cropper";
import "./guidelines.scss";
import {TemplateGuideLine} from "mesmetric-v2-common/models";
import Guideline from "./Guideline/Guideline";
import {debounce} from "@material-ui/core";
import {RGBColor} from "react-color";
import {getObjectId} from "../../../../../../../Common/Utility";

type GuidelinesProps = {
    photoPadding: PhotoPaddingDetails,
    photoCrop: PhotoCropDetails,
    photoSrc: string,
    aspect: string,
    turnOffGuidelines?: boolean
} & ({ turnOffGuidelines: true } | {
    turnOffGuidelines?: undefined, guidelines: TemplateGuideLine[],
    onChange: (guidelines: TemplateGuideLine[]) => void,
    selectedColor: RGBColor,
    selectedOrientation: "horizontal" | "vertical",
    showGuidelines: boolean,
})

interface GuidelinesState {
    loading: boolean
}

class Guidelines extends Component<GuidelinesProps, GuidelinesState> {
    private readonly photoContainer: React.RefObject<HTMLDivElement> = React.createRef();
    private readonly guidelinesContainer: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: GuidelinesProps) {
        super(props);

        this.state = {
            loading: true
        }
    }

    private onPhotoLoaded = () => {
        if (this.state.loading) {
            this.setState({loading: false})

        }
    };

    public componentDidMount(): void {
        window.addEventListener("resize", this.debounce);
    }

    public componentWillUnmount(): void {
        window.removeEventListener("resize", this.debounce);
    }

    private resizeListener = () => {
        this.setState({});
    };

    private debounce = debounce(this.resizeListener, 100);

    private onGuidelineChange = (line: TemplateGuideLine) => {
        !this.props.turnOffGuidelines && this.props.onChange([...this.props.guidelines.filter(l => l._id !== line._id), line])
    };

    private onGuidelineRemove = (line: TemplateGuideLine) => {
        !this.props.turnOffGuidelines && this.props.onChange([...this.props.guidelines.filter(l => l._id !== line._id)]);
    };

    private onGuidelineAdded = (line: TemplateGuideLine) => {
        !this.props.turnOffGuidelines && this.props.onChange([...this.props.guidelines, line]);

    };

    public render = () =>
        <div className={"guidelines"} ref={this.guidelinesContainer}>
            <div
                className={"photo-container"}
                ref={this.photoContainer}
                onClick={this.props.turnOffGuidelines ? undefined : (event) => {
                    if (this.props.turnOffGuidelines) {
                        return;
                    }
                    if (event.altKey && this.photoContainer.current && this.props.showGuidelines) {
                        let position = (event.pageX - this.photoContainer.current.getBoundingClientRect().left) / this.photoContainer.current.clientWidth;
                        if (this.props.selectedOrientation === "horizontal") {
                            position = (event.pageY - this.photoContainer.current.getBoundingClientRect().top) / this.photoContainer.current.clientWidth;
                        }
                        event.preventDefault();
                        this.onGuidelineAdded({
                            color: this.props.selectedColor,
                            type: this.props.selectedOrientation,
                            _id: getObjectId(),
                            position: position * 100
                        });
                    }
                }}
                style={{maxWidth: (this.guidelinesContainer.current?.clientHeight) + "px"}}>
                <img
                    onLoad={this.onPhotoLoaded}
                    src={this.props.photoSrc + `?cx=${this.props.photoCrop.x}&cy=${this.props.photoCrop.y}&cw=${this.props.photoCrop.w}&ch=${this.props.photoCrop.h}&pl=${this.props.photoPadding.padLeft}&pr=${this.props.photoPadding.padRight}&pt=${this.props.photoPadding.padTop}&pb=${this.props.photoPadding.padBottom}`}/>
                {!this.props.turnOffGuidelines && !this.state.loading &&
                this.props.showGuidelines &&
                this.props.guidelines.map(line =>
                    <Guideline
                        container={this.photoContainer}
                        key={line._id}
                        onChange={this.onGuidelineChange}
                        line={line}
                        onRemove={this.onGuidelineRemove}
                        selectedColor={this.props.turnOffGuidelines ? {
                            r: 244,
                            g: 67,
                            b: 54
                        } : this.props.selectedColor}
                    />)}
            </div>
        </div>
}

export default Guidelines