import React from "react";
import Box from "../../ProductView/Common/Box/Box";
import Brochures from "./Brochures/Brochures";
import Photos from "./Photos/Photos";
import {connect} from "react-redux";
import {AppState} from "../../../Store";

interface Props {
    displayComponent: boolean
}

const UploadedFiles: React.FC<Props> = (props) => {
    return props.displayComponent ?
        <Box title={"Wgrane zdjęcie i pliki"}>
            <div className={"row"}>
                <div className={"col-6"}>
                    <Photos/>
                </div>
                <div className={"col-6"}>
                    <Brochures/>
                </div>
            </div>
        </Box> : null;
};

const mapStateToProps = (state: AppState): Props => ({
    displayComponent: !!Object.keys(state.BrandData.brandData?.photos || {}).length || !!state.BrandData.brandData?.brochures?.length
});

export default connect(mapStateToProps)(UploadedFiles);