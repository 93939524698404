import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../../Store";
import {RouteComponentProps, withRouter} from "react-router";
import styles from "./Designers.module.scss";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import AddItem from "./AddItem/AddItem";
import EditItem from "./EditItem/EditItem";
import {ItemData} from "./ItemForm/ItemForm";
import {
    addDesigner,
    BackendDesigner,
    deleteDesigner,
    editDesigner,
    fetchDesigners,
    isDesignerRemovable
} from "../../ActionCreators/Designers";
import Table from "../CommonComponents/Table/Table";
import {Column} from "../CommonComponents/Table/Column";
import {Designer} from "mesmetric-v2-common/models";
import ButtonPopper from "../CommonComponents/ButtonPopper/ButtonPopper";

interface StateProps extends RouteComponentProps {
    items: BackendDesigner[],
    isSearching: boolean
}

interface DispatchProps {
    addItem: (userData: ItemData) => Promise<string | void>,
    editItem: (id: string, itemData: ItemData) => Promise<string | void>,
    deleteItem: (id: string) => Promise<string | void>,
    fetchDesigners: () => void,
    isRemovable: (id: string) => Promise<boolean>
}

type Props = StateProps & DispatchProps;

const COLUMNS: Column<Designer>[] = [
    {
        name: "Nazwa",
        dataKey: "name",
        filterType: "TEXT",
        filter: (item, filter) => !filter || item.name.toLowerCase().includes(filter?.toLowerCase())
    },
    {name: "Aktywny", dataKey: "isActive"},
    {name: "Kolejność", dataKey: "order"},
    {name: "", dataKey: "actions"}];


class Designers extends Component<Props> {

    componentDidMount() {
        if (!this.props.items?.length) {
            this.props.fetchDesigners();
        }
    }

    public render = (): JSX.Element =>
        <Table
            items={this.props.items}
            label={"Projektanci"}
            isSearching={this.props.isSearching}
            id={"designers"}
            headerComponent={<AddItem
                className={styles.add}
                otherItems={this.props.items}
                doAction={this.props.addItem}/>}
            getActions={(item) => <div className={styles.buttons}>
                <EditItem
                    className={styles.editButton}
                    doAction={userData => this.props.editItem(item._id as string, userData)}
                    item={item}
                    otherItems={this.props.items.filter(el => el._id !== item._id)}
                />
                <ButtonPopper
                    isClickable={() => this.props.isRemovable(item._id as string)}
                    onConfirm={() => this.props.deleteItem(item._id as string)}/>
            </div>}
            columns={COLUMNS}/>;
}

const mapStateToProps = (state: AppState, externalProps: RouteComponentProps): StateProps => ({
    ...externalProps,
    items: state.Designers.designers,
    isSearching: state.Designers.isSearching,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    addItem: (userData: ItemData) => dispatch(addDesigner(userData)),
    editItem: (id: string, userData: ItemData) => dispatch(editDesigner(id, userData)),
    deleteItem: (id: string) => dispatch(deleteDesigner(id)),
    fetchDesigners: () => dispatch(fetchDesigners()),
    isRemovable: (id) => dispatch(isDesignerRemovable(id))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Designers));