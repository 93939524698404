import React, {Component} from "react";
import {CircularProgress} from "@material-ui/core";
import "./spinner.scss";

interface SpinnerProps {
    size?: number,
    withBackground?: boolean
}

class Spinner extends Component<SpinnerProps> {
    static defaultProps = {
        size: 24
    };

    public render = (): JSX.Element =>
        <div className={`spinner${this.props.withBackground ? " with-background" : ""}`}>
            <CircularProgress size={64}/>
        </div>
}

export default Spinner;