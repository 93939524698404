import React, {Component} from 'react'
import "./filters.scss";
import {AppState} from "../../../Store";
import _ from "lodash";
import {connect} from "react-redux";
import {Dictionary, ProductAttribute, ProductFilter} from "mesmetric-v2-common/models";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {updateValue} from "../../../ActionCreators/ProductData";
import AttributeDictionary from "../Common/AttributeDictionary/AttributeDictionary";
import Box from "../Common/Box/Box";

interface ExternalProps {
    path: string,
    visible: boolean
}

interface StateProps {
    productFilters: any
    filtersProvided: ProductFilter[]
    path: string,
    visible: boolean
}

interface DispatchProps {
    onFiltersChange: (filterId: string, value: string[]) => void
    onDictionaryUpdated: (index: number, value: Dictionary) => void
}

class Filters extends Component<StateProps & DispatchProps> {
    static defaultProps = {
        productFilters: {},
        filtersProvided: []
    };

    private isEven = (value: number): boolean => value % 2 === 0;

    private mapFilter = (filterProvided: ProductFilter, index: number): JSX.Element =>
        <AttributeDictionary key={index}
                             markWhenIncomplete
                             index={index}
                             providedAttribute={filterProvided as ProductAttribute.Dictionary}
                             path={`filters.${filterProvided._id}`}
                             onDictionaryItemAdded={this.props.onDictionaryUpdated}/>;

    render = (): JSX.Element => {
        return <div hidden={!this.props.visible}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <Box title={"Filtry"}>
                        <div className={"row"}>
                            <div className={"col-6"}>
                                <div className={"filters-elements"}>
                                    {this.props.filtersProvided.map((filter, index) => {
                                        return this.isEven(index) && this.mapFilter(filter, index)
                                    })}
                                </div>
                            </div>
                            <div className={"col-6"}>
                                <div className={"filters-elements"}>
                                    {this.props.filtersProvided.map((filter, index) => {
                                        return !this.isEven(index) && this.mapFilter(filter, index)
                                    })}
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => {
    return ({
        productFilters: _.get(state.ProductData.productData, externalProps.path),
        filtersProvided: _.get(state.ProductData.productData, "primaryCategory.template.filterGroup.filters"),
        path: externalProps.path,
        visible: externalProps.visible
    });
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onFiltersChange: (filterId: string, value: string[]) =>
        dispatch(updateValue(`${externalProps.path}.${filterId}`, value)),
    onDictionaryUpdated: (index: number, value: Dictionary) =>
        dispatch(updateValue(`primaryCategory.template.filterGroup.filters[${index}].dictionary`, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);