import React, {Component} from "react";
import styles from "./Login.module.scss";
import Box from "../ProductView/Common/Box/Box";
import {LinearProgress, TextField as MaterialTextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../Store";
import {Action} from "redux";
import {connect} from "react-redux";
import {logIn} from "../../ActionCreators/User";
import {Alert} from "@material-ui/lab";

type Props = DispatchProps;

interface State {
    login: string,
    password: string,
    errorMessage?: string,
    inProgress: boolean
}

interface DispatchProps {
    logIn: (login: string, password: string) => Promise<string | void>
}

class Login extends Component<Props, State> {
    state: State = {
        login: "",
        password: "",
        inProgress: false
    };

    private onClick = async () => {
        this.setState({
            errorMessage: undefined,
            inProgress: true
        });
        const errorMessage = await this.props.logIn(this.state.login, this.state.password);
        if (errorMessage) {
            this.setState({
                errorMessage,
                inProgress: false
            })
        }
    };

    public render = (): JSX.Element =>
        <div className={styles.loginWrapper}>
            <Box
                className={styles.loginForm}
                title={"Logowanie"}
            >
                <MaterialTextField
                    label={"Login"}
                    variant="outlined"
                    size={"small"}
                    name={"username"}
                    disabled={this.state.inProgress}
                    error={!!this.state.errorMessage}
                    value={this.state.login}
                    onChange={event => this.setState({login: event.target.value || "", errorMessage: undefined})}
                    type={"text"}
                    autoFocus
                />
                <MaterialTextField
                    label={"Hasło"}
                    name={"password"}
                    variant="outlined"
                    onKeyPress={ev => {
                        if (ev.key === 'Enter') {
                            this.onClick();
                        }
                    }}
                    size={"small"}
                    disabled={this.state.inProgress}
                    error={!!this.state.errorMessage}
                    value={this.state.password}
                    onChange={event => this.setState({password: event.target.value || "", errorMessage: undefined})}
                    type={"password"}
                />
                {this.state.errorMessage && <Alert severity="error">{this.state.errorMessage}</Alert>}
                {this.state.inProgress && <LinearProgress className={styles.inProgress}/>}
                {!this.state.inProgress && <Button
                    size="small"
                    color={"primary"}
                    variant="contained"
                    disabled={!(this.state.login && this.state.password)}
                    onClick={this.onClick}
                >
                    <span>Zaloguj się</span>
                </Button>}
            </Box>
        </div>
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    logIn: (login: string, password: string) => dispatch(logIn(login, password))
});

export default connect(null, mapDispatchToProps)(Login);