import React from "react";
import Paper from "@material-ui/core/Paper";
import {PaperProps} from "@material-ui/core/Paper/Paper";
import "./paddedPaper.scss";

const PaddedPaper = (props: PaperProps) => {

    const getClassName = () => {
        const classNames = ["padded-paper"];
        if (props.className) {
            classNames.push(props.className);
        }
        return classNames.join(" ");
    };

    return <Paper {...props}
                  className={getClassName()}>{props.children}</Paper>
};

export default PaddedPaper
