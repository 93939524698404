import React, {Component} from "react";
import Dialog from "@material-ui/core/Dialog";
import "./photoGallery.scss";
import {Button} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import {AppState} from "../../../../../Store";
import _ from "lodash";
import {Photo} from "mesmetric-v2-common/models";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {updateValue} from "../../../../../ActionCreators/ProductData";
import {connect} from "react-redux";
import Square from "../Square/Square";
import DeleteIcon from "@material-ui/icons/Delete";
import {getCroppedSrc} from "../../../../../Utils/PhotoUtils";

interface ExternalProps {
    path: string,
    onClose: () => void,
    croppedThumbnails: boolean
}

interface StateProps {
    photos: Photo[],
    onClose: () => void,
    croppedThumbnails: boolean
}

interface DispatchProps {
    onChange: (value: Photo[]) => void
}

type PhotoGalleryProps = StateProps & DispatchProps;

interface PhotoGalleryState {
    photos: Photo[]
}

class PhotoGallery extends Component<PhotoGalleryProps, PhotoGalleryState> {
    static defaultProps = {
        photos: []
    };

    constructor(props: PhotoGalleryProps) {
        super(props);
        this.state = {
            photos: this.props.photos
        }
    }

    public render = (): JSX.Element =>
        <Dialog
            open={true}
            fullScreen
            className={"photo-gallery"}
            onClose={this.props.onClose}
        >
            <div className={"all-photos"}>
                {this.state.photos.map(photo =>
                    <div className={"photo-with-delete"}>
                        <Square
                            style={{backgroundImage: `url(${photo.src && this.props.croppedThumbnails ? getCroppedSrc(photo, {w: 132}) : photo.srcResolved})`}}>
                        </Square>
                        <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => this.setState({photos: this.state.photos.filter(p => p._id !== photo._id)})}
                        ><DeleteIcon/>
                        </Button>
                    </div>
                )}
            </div>
            <DialogActions className={"actions"}>
                <Button color="primary"
                        variant={"contained"}
                        onClick={() => {
                            this.props.onChange(this.state.photos);
                            this.props.onClose();
                        }}>
                    Zapisz
                </Button>
                <Button color="primary" onClick={this.props.onClose}>
                    Odrzuć
                </Button>
            </DialogActions>
        </Dialog>
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    photos: _.get(state.ProductData.productData, externalProps.path),
    onClose: externalProps.onClose,
    croppedThumbnails: externalProps.croppedThumbnails
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onChange: (value: Photo[]) => dispatch(updateValue(externalProps.path, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoGallery);