import React from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../../../Store";
import {ProductAttributeGroup, ProductTemplate} from "mesmetric-v2-common/models";
import AttributeGroup from "../../ProductTemplateAttributes/AttributeGroup/AttributeGroup";
import Copy from "./Copy";

const splitArray = (arr: ProductAttributeGroup[]): any => {
    const result = [[], [], []];

    for (let i = 0; i < arr.length; i++) {
        // @ts-ignore
        result[i % 3].push(arr[i]);
    }
    return result;
};

const Attributes: React.FC = () => {
    const attributeGroupsProvided = useSelector<AppState, ProductAttributeGroup[]>(state => (state.ProductData.productData?.primaryCategory.template as ProductTemplate).attributeGroups)
    const chunkedProvidedAttributeGroups = splitArray(attributeGroupsProvided);

    const getAttributeGroupsColumn = (attributeGroups: ProductAttributeGroup[], index: number): JSX.Element => {
        return <div className={"col-4"} key={index}>
            {attributeGroups.map((providedAttributeGroup: ProductAttributeGroup, index) => {
                    const indexOfGroup = attributeGroupsProvided.indexOf(providedAttributeGroup);
                    return <AttributeGroup
                        key={providedAttributeGroup._id}
                        index={indexOfGroup}
                        attributeGroupProvided={providedAttributeGroup}
                        path={`${"shop.attributes"}.${providedAttributeGroup._id}`}/>
                }
            )}
        </div>
    };

    return <>
        <Copy/>
        <div className={"row"}>
            {chunkedProvidedAttributeGroups.map((chunk: ProductAttributeGroup[], index: number) => getAttributeGroupsColumn(chunk, index))}
        </div>
    </>;
}

export default Attributes;