import React from "react";
import ByCategory from "./ByCategory/ByCategory";
import ByBrand from "./ByBrand/ByBrand";
import {PropTypes} from "@material-ui/core";

export interface CommonItemProps {

}

interface Props {
    texts: {
        byCategory: string,
        byBrand: string
    },
    actions: {
        byCategory: (id: string | undefined) => Promise<void>
        byBrand: (id: string | undefined) => Promise<void>
    }
    icon?: JSX.Element,
    color?: PropTypes.Color
}

const Common: React.FC<Props> = ({texts, icon, color, actions}) => {
    return <>
        <ByCategory
            confirmText={texts.byCategory}
            icon={icon}
            color={color}
            onConfirm={actions.byCategory}

        />
        <ByBrand
            confirmText={texts.byBrand}
            icon={icon}
            color={color}
            onConfirm={actions.byBrand}
        />
    </>
}
export default Common;