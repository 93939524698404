import React, {Component} from "react";
import styles from "./ItemForm.module.scss";
import {TextFieldNotConnected} from "../../ProductView/Common/TextField/TextField";
import {CheckboxNotConnected} from "../../ProductView/Common/Checkbox/Checkbox";

export interface ItemData {
    name?: string,
    code?: string,
    isActive?: boolean,
    priceMultiplier?: number,
    order?: number
}

interface Props {
    userData?: ItemData,
    type: "ADD" | "EDIT",
    onChange: (user: ItemData) => void,
    disabled?: boolean
}

class ItemForm extends Component<Props> {

    private onChange = (value: boolean | string | undefined, field: keyof ItemData) => {
        this.props.onChange({
            ...this.props.userData || {},
            [field]: value
        })
    };

    public render = (): JSX.Element =>
        <div className={styles.userForm}>
            <TextFieldNotConnected
                autoFocus={this.props.type === "ADD"}
                label={"Podaj nazwę"}
                disabled={this.props.disabled}
                value={this.props.userData?.name}
                onChange={value => this.onChange(value, "name")}
                required
                type={"text"}
            />
            <TextFieldNotConnected
                label={"Podaj kod"}
                value={this.props.userData?.code}
                disabled={this.props.disabled}
                onChange={value => this.onChange(value, "code")}
                required
                type={"text"}
            />
            <TextFieldNotConnected
                label={"Mnożnik"}
                value={this.props.userData?.priceMultiplier}
                disabled={this.props.disabled}
                onChange={value => this.onChange(value, "priceMultiplier")}
                required
                type={"number"}
            />
            <TextFieldNotConnected
                label={"Kolejność"}
                value={this.props.userData?.order}
                disabled={this.props.disabled}
                onChange={value => this.onChange(value ? parseInt(value) : value, "order")}
                type={"number"}
            />
            <CheckboxNotConnected
                value={this.props.userData?.isActive}
                label={"Aktywna"}
                disabled={this.props.disabled}
                onChange={value => this.onChange(!!value, "isActive")}
            />
        </div>
}

export default ItemForm;