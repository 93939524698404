import React from "react";
import {connect} from "react-redux";
import _ from "lodash";
import {Button, PropTypes} from "@material-ui/core";
import {AppState} from "../../../../Store";
import {updateValue as productUpdateValue} from "../../../../ActionCreators/ProductData";
import {updateValue as brandUpdateValue} from "../../../../ActionCreators/BrandData";
import "./checkboxButton.scss";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckIcon from '@material-ui/icons/Check';
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";

interface ExternalProps {
    path: string,
    label: string,
    color?: PropTypes.Color,
    dataType?: "Product" | "Brand",
    className?: string
}

interface StateProps {
    value: any,
    label: string,
    color?: PropTypes.Color,
    className?: string
}

interface DispatchProps {
    onChange: (value: any) => void
}

type CheckboxButtonProps = StateProps & DispatchProps;

const CheckboxButton: React.FC<CheckboxButtonProps> = ({value, onChange, label, color, className}) => {
    return <Button
        variant="contained"
        className={"checkbox-button " + className}
        color={value ? (color || "primary") : undefined}
        size={"small"}
        onClick={() => onChange(!value)}
    >
        <>
            <div className={"checkbox-blank-square"}/>
            <CheckBoxOutlineBlankIcon className={"check-outline"}/>
            {value && <CheckIcon className={"check"}/>}
            {label}</>
    </Button>
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    value: _.get(externalProps.dataType === "Brand" ? state.BrandData.brandData : state.ProductData.productData, externalProps.path, ""),
    ...externalProps
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onChange: (value: any) => dispatch((externalProps.dataType === "Brand" ? brandUpdateValue : productUpdateValue)(externalProps.path, value))
});

export const CheckboxButtonNotConnected = CheckboxButton;
export default connect(mapStateToProps, mapDispatchToProps)(CheckboxButton)