import {Product} from "mesmetric-v2-common/models";

export interface ProductDataState {
    productData?: Product,
    fetchedProductData?: Product,
    autoSaveEnabled: boolean,
    saveInProgress: boolean,
    changesDetected: boolean
}

export const initialState: ProductDataState = {
    autoSaveEnabled: false,
    saveInProgress: false,
    changesDetected: false
};