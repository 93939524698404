import React, {useState} from "react";
import styles from "./Table.module.scss";
import Box from "../../ProductView/Common/Box/Box";
import {CircularProgress} from "@material-ui/core";
import {AutoSizer, ScrollEventData} from "react-virtualized";
import {Column as VirtualizedColumn, Table as VirtualizedTable, TableCellProps} from "react-virtualized/dist/es/Table";
import TableCell from "@material-ui/core/TableCell";
import {Model} from "mesmetric-v2-common/models";
import {Column} from "./Column";
import FilterCell from "../HeaderCell/FilterCell";
import {LocalStorageKey} from "../../../Utils/StorageUtils";
import commonStyles from "../../CommonComponents/CommonStyles.module.scss";

interface Props<T> {
    items: T[],
    label: string,
    isSearching: boolean,
    headerComponent: JSX.Element,
    columns: Column<T>[],
    getActions?: (item: T) => JSX.Element,
    onRowClick?: (item: T) => void,
    id: string,
    rowHeight?: number
}

const ROW_HEIGHT = 65;
const HEADER_HEIGHT = 65;

const Table = <T extends Model, >(
    {
        label,
        isSearching,
        headerComponent,
        columns,
        getActions,
        onRowClick,
        rowHeight = ROW_HEIGHT,
        ...props
    }: Props<T>) => {

    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem(LocalStorageKey.TABLE_FILTERS(props.id)) || "{}"));
    const [scrollTop, setScrollTop] = useState<number | undefined>(parseInt(localStorage.getItem(LocalStorageKey.TABLE_SCROLL_TOP(props.id)) || "0"))

    const saveFilter = (field: keyof T | "actions", value?: string) => {
        setFilters((prevState: any) => {
            let updatedFilters = {...prevState, [field]: value || undefined};
            if (!value) {
                delete updatedFilters[field];
            }
            if (Object.keys(updatedFilters).length) {
                localStorage.setItem(LocalStorageKey.TABLE_FILTERS(props.id), JSON.stringify(updatedFilters));
            } else {
                localStorage.removeItem(LocalStorageKey.TABLE_FILTERS(props.id));
            }
            return updatedFilters;
        });
    }

    const items = Object.keys(filters).length ? props.items.filter(item => Object.keys(filters).filter(el => columns.find(col => col.dataKey === el)?.filter?.(item, filters[el as keyof typeof filters])).length === Object.keys(filters).length) : props.items;

    const getCell = (tableProps: TableCellProps): JSX.Element => {
        const item = tableProps.rowData;

        const getContent = (key: string): JSX.Element | string => {
            switch (key) {
                case "actions":
                    return getActions?.(item) || "";
                default: {
                    const foundColumn = columns.find(col => col.dataKey === key);
                    return foundColumn?.format ? foundColumn.format(tableProps.rowData) : tableProps.cellData
                }

            }
        };

        return <TableCell
            variant={"body"}
            component={"div"}
            align={"left"}
            valign={"middle"}
            className={`${styles.cell} ${tableProps.rowIndex % 2 === 0 ? styles.even : styles.odd} ${localStorage.getItem(LocalStorageKey.TABLE_LAST_OPENED_ID(props.id)) === item._id ? styles.lastActive : ""}`}
            style={{height: rowHeight}}>
            {getContent(tableProps.dataKey)}
        </TableCell>;
    };

    const renderColumn = (column: Column<T>, index: number): JSX.Element =>
        <VirtualizedColumn
            key={index}
            maxWidth={column.maxWidth}
            flexGrow={1}
            className={styles.column}
            headerRenderer={(headerProps) => {
                return <TableCell
                    variant={"head"}
                    align={"left"}
                    valign={"middle"}
                    component={"div"}
                    className={`${styles.cell} ${styles.column}`}
                    style={{height: HEADER_HEIGHT}}>
                    {column.filter ?
                        <FilterCell
                            name={column.name}
                            value={filters[column.dataKey as keyof typeof filters]}
                            type={column.filterType}
                            onChange={value => {
                                saveFilter(column.dataKey, value);
                            }}/> : column.name}
                </TableCell>
            }}
            cellRenderer={(props: TableCellProps) => getCell(props)}
            dataKey={column.dataKey as string}
            width={500}
        />;

    const handleScroll = (newScrollTop: number) => {
        scrollTop !== undefined && setScrollTop(undefined);
        localStorage.setItem(LocalStorageKey.TABLE_SCROLL_TOP(props.id), newScrollTop.toString());
    };

    const onRowClicked = (item: T): void => {
        const itemId = items[items.indexOf(item)]._id;
        localStorage.setItem(LocalStorageKey.TABLE_LAST_OPENED_ID(props.id), itemId as string);
        onRowClick?.(item);
    };

    return <div className={commonStyles.content}>
        <Box title={label}
             className={styles.box}
             headerComponent={<>{headerComponent}</>}
        >
            {(isSearching || items.length > 6) &&
            <div className={styles.loading}><CircularProgress size={24}/></div>}
            <AutoSizer>
                {({height, width}) => (
                    <VirtualizedTable
                        height={height}
                        width={width}
                        rowHeight={rowHeight!}
                        headerHeight={HEADER_HEIGHT!}
                        rowGetter={({index}) => items[index]}
                        rowCount={items.length}
                        scrollTop={scrollTop}
                        className={styles.table}
                        onScroll={(data: ScrollEventData) => handleScroll(data.scrollTop)}
                        rowClassName={`${styles.row} ${onRowClick ? styles.clickable : ""}`}
                        onRowClick={item => onRowClicked(item.rowData)}
                        headerClassName={styles.header}
                    >
                        {columns.map(renderColumn)}
                    </VirtualizedTable>
                )}
            </AutoSizer>
        </Box>
    </div>;
}

export default Table;