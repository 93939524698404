import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../../../Store";
import {RouteComponentProps, withRouter} from "react-router";
import styles from "./Users.module.scss";
import Box from "../../ProductView/Common/Box/Box";
import {BackendUser, UserData} from "../../../ActionCreators/User";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {TableContainer} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import AbsoluteSpinner from "../../../Components/UI/AbsoluteSpinner/AbsoluteSpinner";
import TableBody from "@material-ui/core/TableBody";
import AddUser from "./AddUser/AddUser";
import EditUser from "./EditUser/EditUser";
import RemoveUser from "./RemoveUser/RemoveUser";
import {addUser, deleteUser, editUser, getUsers} from "../../../ActionCreators/Admin";

interface StateProps {
    isAdmin: boolean
}

interface DispatchProps {
    getUsers: () => Promise<BackendUser[]>,
    addUser: (userData: UserData) => Promise<string | void>,
    editUser: (id: string, userData: UserData) => Promise<string | void>,
    deleteUser: (id: string) => Promise<string | void>
}

type Props = StateProps & DispatchProps & RouteComponentProps & {
    hidden?: boolean
};

interface State {
    isLoading: boolean,
    users?: BackendUser[]
}

class Users extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        if (!props.isAdmin) {
            props.history.push("/");
        }

        this.state = {
            isLoading: true
        }
    }

    public componentDidMount = async (): Promise<void> => {
        this.setState({
            users: await this.props.getUsers(),
            isLoading: false
        });
    };

    private mapPermissions = (permission: any) => {
        switch (permission) {
            case "admin":
                return "administrator";
            default:
                return permission
        }
    };

    private sortUsers = (user1: BackendUser, user2: BackendUser): number => {
        if (user1.permissions?.admin && user2.permissions?.admin) {
            return user1.login.localeCompare(user2.login);
        } else if (user1.permissions?.admin) {
            return -1;
        } else if (user2.permissions?.admin) {
            return 1;
        } else {
            return user1.login.localeCompare(user2.login);
        }
    };

    private isRemovable = (user: BackendUser) => {
        return !user.permissions?.admin || (this.state.users?.filter?.(user => user.permissions?.admin) || []).length > 1;
    };

    private mapDate = (dateString: string) => new Date(dateString).toLocaleString("pl-PL", {
        minute: "numeric",
        hour: "numeric",
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    private addUser = async (userData: UserData): Promise<string | void> => {
        const errorMessage = await this.props.addUser(userData);
        if (errorMessage) {
            return errorMessage;
        }
        this.setState({
            users: undefined,
            isLoading: true
        }, async () => this.setState({
            users: await this.props.getUsers(),
            isLoading: false
        }));
    };

    private editUser = async (id: string, userData: UserData): Promise<string | void> => {
        const errorMessage = await this.props.editUser(id, userData);
        if (errorMessage) {
            return errorMessage;
        }
        this.setState({
            users: undefined,
            isLoading: true
        }, async () => this.setState({
            users: await this.props.getUsers(),
            isLoading: false
        }));
    };

    private deleteUser = async (id: string): Promise<string | void> => {
        const errorMessage = await this.props.deleteUser(id);
        if (errorMessage) {
            return errorMessage;
        }
        this.setState({
            users: undefined,
            isLoading: true
        }, async () => this.setState({
            users: await this.props.getUsers(),
            isLoading: false
        }));
    };

    private getUserRow = (user: BackendUser, index: number) => {
        return <TableRow
            key={user.login}
            className={index % 2 === 0 ? styles.evenRow : styles.oddRow}
        >
            <TableCell>{user.login}</TableCell>
            <TableCell>{this.mapDate(user.created)}</TableCell>
            <TableCell>{user.created !== user.passwordChangedAt ? this.mapDate(user.passwordChangedAt) : ""}</TableCell>
            <TableCell>{user.permissions ? Object.keys(user.permissions).map(this.mapPermissions).join(", ") : "brak"}</TableCell>
            <TableCell
                className={styles.buttons}
            >
                <EditUser
                    doAction={userData => this.editUser(user._id, userData)}
                    user={user}
                />
                {this.isRemovable(user) &&
                <RemoveUser
                    deleteUser={() => this.deleteUser(user._id)}
                />}
            </TableCell>
        </TableRow>;
    }


    public render = (): JSX.Element =>
        <div className={styles.content} hidden={this.props.hidden}>
            {this.state.isLoading ?
                <AbsoluteSpinner/>
                : <Box title={"Użytkownicy"}
                       className={styles.header}
                       headerComponent={<AddUser
                           className={styles.add}
                           doAction={this.addUser}/>}
                >
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow className={styles.row}>
                                    <TableCell>Login</TableCell>
                                    <TableCell>Konto utworzone</TableCell>
                                    <TableCell>Hasło zmienione</TableCell>
                                    <TableCell>Uprawnienia</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={styles.row}>
                                {this.state.users?.sort(this.sortUsers).map(this.getUserRow)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>}
        </div>;
}

const mapStateToProps = (state: AppState): StateProps => ({
    isAdmin: !!state.User.user?.permissions?.admin
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    getUsers: () => dispatch(getUsers()),
    addUser: (userData: UserData) => dispatch(addUser(userData)),
    editUser: (id: string, userData: UserData) => dispatch(editUser(id, userData)),
    deleteUser: (id: string) => dispatch(deleteUser(id))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));