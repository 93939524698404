import React, {useState} from "react";
import * as Styled from "../../MassChanges.styled";
import {PrimaryCategoryNotConnected} from "../../../../ProductView/ProductDetails/Categories/PrimaryCategory/PrimaryCategory";
import ButtonPopper from "../../../../CommonComponents/ButtonPopper/ButtonPopper";
import {Category} from "mesmetric-v2-common/models";
import {CommonItemProps} from "../Common.types";

const ByCategory: React.FC<CommonItemProps> = ({confirmText, icon, color, onConfirm}) => {
    const [category, setCategory] = useState<Category>();

    return <Styled.ContentItem>
        <PrimaryCategoryNotConnected
            value={category}
            onChange={setCategory}
            label={"Po kategorii"}
        />
        <ButtonPopper
            disabled={!category}
            confirmText={confirmText}
            color={color}
            icon={icon}
            onConfirm={() => onConfirm(category?._id)}
        />
    </Styled.ContentItem>
}

export default ByCategory;