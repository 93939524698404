import styled from "styled-components";
import {Button as ButtonMui, LinearProgress as LinearProgressMui} from "@material-ui/core";

export const Content = styled.div`
  padding: 24px 24px;
`

export const ContentItem = styled.div`
  display: flex;

  > *:first-child {
    margin-right: 8px;
  }

  > *:not(button) {
    flex-grow: 1;
  }

  button {
    margin-left: 8px;
  }
`

export const HiddenInput = styled.input`
  display: none;
`

const buttonWidth = 95;

export const LinearProgress = styled(LinearProgressMui)`
  width: ${buttonWidth}px;
  height: 36px !important;
  border-radius: 4px;
`

export const UploadButton = styled(ButtonMui)`
  width: ${buttonWidth}px;
  height: 36px !important;
`

