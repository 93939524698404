import axios from "axios";
import {getAxiosConfig} from "./User";
import {parseError} from "./Error";
import {ThunkAction} from "redux-thunk";
import {AppState} from "../Store";
import {ItemData} from "../Views/Brands/ItemForm/ItemForm";
import {Brand} from "mesmetric-v2-common/models";

export interface BackendBrand extends Brand {
    isRemovable?: boolean
    _id: string
}

export enum ActionTypes {
    setBrands = "setBrands",
    setIsSearchingBrands = "setIsSearchingBrands",
}

export interface SetBrandsAction {
    type: ActionTypes.setBrands
    brands: BackendBrand[]
}

export interface SetIsSearchingAction {
    type: ActionTypes.setIsSearchingBrands,
    isSearching: boolean
}

function setBrands(brands: BackendBrand[]): SetBrandsAction {
    return {
        type: ActionTypes.setBrands,
        brands: brands
    };
}

export const MESSAGES_FROM_SERVER: { [key: string]: string } = {
    BRAND_NOT_FOUND: "Nie ma takiej marki",
    BRAND_HAVE_PRODUCTS: "Istnieją produkty przypisane do tej marki"
};

export const fetchBrands = (): ThunkAction<void, AppState, {}, SetIsSearchingAction | SetBrandsAction> => {
    return async (dispatch: any) => {
        dispatch({
            type: ActionTypes.setIsSearchingBrands,
            isSearching: true
        });
        dispatch({
            type: ActionTypes.setBrands,
            brands: []
        });
        try {
            const brands = (await axios.get<BackendBrand[]>(`${process.env.REACT_APP_DATA_ENDPOINT}/brands`, getAxiosConfig())).data;
            dispatch(setBrands(brands));
        } catch (e) {
            parseError(e);
        }
        dispatch({
            type: ActionTypes.setIsSearchingBrands,
            isSearching: false
        });
    }
};

export const getBrands = (): ThunkAction<Promise<BackendBrand[]>, AppState, {}, never> =>
    async () => {
        try {
            return (await axios.get(`${process.env.REACT_APP_DATA_ENDPOINT}/brands`, getAxiosConfig())).data;
        } catch (e) {
            parseError(e);
            return []
        }
    };

export const addBrand = (userData: ItemData): ThunkAction<Promise<void>, AppState, {}, never> =>
    async (dispatch) => {
        try {
            await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/brands/add`, {...userData}, getAxiosConfig());
            dispatch(fetchBrands());
        } catch (e) {
            parseError(e);
        }
    };

export const deleteBrand = (): ThunkAction<Promise<void>, AppState, {}, never> =>
    async (dispatch, getState) => {
        try {
            await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/brands/delete/` + getState().BrandData.brandData?._id, {}, getAxiosConfig());
            await dispatch(fetchBrands());
        } catch (e) {
            parseError(e);
        }
    };

export const isBrandRemovable = (): ThunkAction<Promise<boolean>, AppState, {}, never> =>
    async (dispatch, getState) => {
        try {
            return (await axios.get(`${process.env.REACT_APP_DATA_ENDPOINT}/brands/isRemovable/` + getState().BrandData.brandData?._id, getAxiosConfig())).data;
        } catch (e) {
            parseError(e);
            return false
        }
    };

export type AllActions =
    SetBrandsAction |
    SetIsSearchingAction;