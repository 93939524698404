import React, {Component} from "react";
import Box from "../../Common/Box/Box";
import Checkbox from "../../Common/Checkbox/Checkbox";
import {Features} from "mesmetric-v2-common/models";

interface AdditionalProps {
}

const ADDITIONAL_CHECKBOXES: { key: keyof Features, label: string }[] = [
    {
        key: "moreDetailsInPdf",
        label: "Więcej szczegółów w broszurze pdf"
    },
    {
        key: "moreDetailsSoon",
        label: "Więcej szczegółów wkrótce"
    },
    {
        key: "showroomWarszawa",
        label: "Zapraszamy do showroomu w Warszawie"
    },
    {
        key: "showroomGdynia",
        label: "Zapraszamy do showroomu w Gdyni"
    },
    {
        key: "dimensionsAdjustable",
        label: "Wymiary mogą zostać dopasowane do potrzeb klienta"
    },
    {
        key: "colorsAdjustable",
        label: "Kolory mogą zostać dostosowane do potrzeb klienta"
    }
];

class Additional extends Component<AdditionalProps> {
    public render = (): JSX.Element =>
        <Box title={"Więcej"}>
            {ADDITIONAL_CHECKBOXES.map((checkbox, index) =>
                <Checkbox
                    key={index}
                    path={`features.${checkbox.key}`}
                    label={checkbox.label}
                />)}
        </Box>
}

export default Additional;