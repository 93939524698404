import React, {Component} from "react";
import styles from "../Products.module.scss";
import {GridCellProps} from "react-virtualized";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {connect} from "react-redux";
import {AppState} from "../../../Store";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {setFilter} from "../../../ActionCreators/ProductsFilters";
import {Column} from "../Columns";
import {ProductsFilters} from "mesmetric-v2-common/models";

interface DispatchProps {
    setFilter: (field: keyof ProductsFilters, value?: string | number | boolean) => void
}

interface ExternalProps extends GridCellProps {
    columns: Column[]
}

interface StateProps extends ExternalProps {
    value?: string | number | boolean | string[]
}

type Props = StateProps & DispatchProps;

class HeaderCell extends Component<Props> {

    private onTextInputChange = (field: keyof ProductsFilters, value: string, type: "TEXT" | "NUMBER" | "BOOLEAN") => {
        switch (type) {
            case "TEXT": {
                this.props.setFilter(field, value || undefined);
                break;
            }
            case "NUMBER": {
                this.props.setFilter(field, value === "" ? undefined : parseInt(value))
            }
        }
    };

    private onSelectChange = (field: keyof ProductsFilters, value: unknown, type: "TEXT" | "NUMBER" | "DICTIONARY" | "BOOLEAN") => {
        switch (type) {
            case "BOOLEAN": {
                this.props.setFilter(field, value !== undefined ? value === "true" : undefined);
                break;
            }
            case "DICTIONARY": {
                this.props.setFilter(field, value as any);
            }
        }
    };

    private renderCell = (): JSX.Element => {
        const column = this.props.columns[this.props.columnIndex];
        return <>
            {(column.type === "TEXT" || column.type === "NUMBER") ?
                <FormControl className={styles.select}>
                    <InputLabel shrink>{column.name}</InputLabel>
                    <TextField
                        label={" "}
                        type={column.type === "TEXT" ? "text" : "number"}
                        value={this.props.value !== undefined ? this.props.value : ""}
                        onChange={event => this.onTextInputChange(column.field, event.target.value, column.type)}
                    >
                    </TextField>
                </FormControl>
                :
                <FormControl className={styles.select}>
                    <InputLabel shrink>{column.name}</InputLabel>
                    <Select
                        value={this.props.value !== undefined ? this.props.value.toString() : ""}
                        onChange={event => this.onSelectChange(column.field, event.target.value, column.type)}
                    >
                        <MenuItem value={undefined}>-</MenuItem>
                        {column.type === "DICTIONARY" ?
                            column.options.map(option =>
                                <MenuItem value={option.key} key={option.key}>{option.value}</MenuItem>) :
                            [
                                <MenuItem key={"true"} value={"true"}>Tak</MenuItem>,
                                <MenuItem key={"false"} value={"false"}>Nie</MenuItem>
                            ]
                        }
                    </Select>
                </FormControl>}

        </>;
    };


    public render = (): JSX.Element =>
        <div
            className={`${styles.cell} ${styles.header}`}
            style={{
                ...this.props.style,
                minWidth: this.props.style.width,
                maxWidth: this.props.style.width
            }}
            key={this.props.key}
        >
            {this.renderCell()}
        </div>;
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    ...externalProps,
    value: state.ProductsFilters[externalProps.columns[externalProps.columnIndex].field]
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    setFilter: (field: keyof ProductsFilters, value?: string | number | boolean) => dispatch(setFilter(field, value))
});

export const HeaderCellNotConnected = HeaderCell;

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCell);