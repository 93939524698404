import React, {useCallback, useState} from "react";
import styles from "./FilterCell.module.scss";
import {debounce, FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";

export type FilterType = "TEXT" | "BOOLEAN" | "NUMBER";

interface Props {
    onChange: (value?: string) => void,
    name: string,
    type?: FilterType,
    value?: string
}

const FilterCell: React.FC<Props> = ({name, onChange, type, ...props}) => {
    const [value, setValue] = useState(props.value);

    const updateValue = useCallback(debounce((value?: string) => onChange(value), 500), []);

    const onInputChange = (value?: string) => {
        setValue(value);
        updateValue(value);
    }

    return <FormControl className={styles.filter}>
        <InputLabel shrink>{name}</InputLabel>
        {(type === "TEXT" || type === "NUMBER") &&
        <TextField
            label={" "}
            type={type === "TEXT" ? "text" : "number"}
            value={value || ""}
            onChange={event => onInputChange(event.target.value || undefined)}
        />}
        {type === "BOOLEAN" &&
        <Select
            value={value || ""}
            onChange={event => onInputChange(event.target.value as string)}
        >
            <MenuItem value={undefined}>-</MenuItem>
            [
            <MenuItem key={"true"} value={"true"}>Tak</MenuItem>,
            <MenuItem key={"false"} value={"false"}>Nie</MenuItem>
            ]

        </Select>}
    </FormControl>;
};

export default FilterCell;