import React, {Component} from "react";
import {connect} from "react-redux";
import _ from "lodash";
import {AppState} from "../../../../../Store";
import {updateValue} from "../../../../../ActionCreators/ProductData";
import {Photo, TemplateGuideLine} from "mesmetric-v2-common/models";
import Square from "../Square/Square";
import "./photo.scss";
import DragAndDropArray from "../../../../../Helpers/DragAndDropArray";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import List from "@material-ui/core/List";
import PhotoCrop from "./PhotoCrop/PhotoCrop";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {getCroppedSrc} from "../../../../../Utils/PhotoUtils";
import {DeleteButton, DownloadButton, FreeButton, SquareButton} from "../../../Shop/Photos/Buttons";

interface ExternalProps {
    productId: string,
    path: string,
    guidelinesPath: string,
    croppedThumbnails: boolean
}

interface StateProps {
    productId: string,
    photos: Photo[],
    guidelines: TemplateGuideLine[],
    croppedThumbnails: boolean
}

interface DispatchProps {
    onChange: (value: Photo[]) => void
    onGuidelinesChange: (value: TemplateGuideLine[]) => void
}

type PhotosProps = StateProps & DispatchProps;

interface PhotosState {
    croppedPhoto?: Photo,
    photoCropModalOpen: boolean,
    aspectName: "1:1" | "free"
}

class Photos extends Component<PhotosProps, PhotosState> {
    static defaultProps = {
        photos: [],
        guidelines: []
    };

    constructor(props: PhotosProps) {
        super(props);

        this.state = {
            photoCropModalOpen: false,
            aspectName: 'free',
        }
    }

    public render = (): JSX.Element =>
        <>
            {this.state.photoCropModalOpen &&
            this.state.croppedPhoto &&
            <PhotoCrop
                photo={this.state.croppedPhoto}
                aspectName={this.state.aspectName}
                guidelines={this.props.guidelines}
                onCancel={() => this.setState({photoCropModalOpen: false})}
                onChange={(photo, guidelines) => {
                    const index = this.props.photos.findIndex(p => p._id === photo._id);
                    const photos = [...this.props.photos.filter(p => p._id !== photo._id)];
                    this.setState({photoCropModalOpen: false});
                    photos.splice(index, 0, photo);
                    this.props.onChange(photos);
                    this.props.onGuidelinesChange(guidelines);
                }}
            />}

            <DragDropContext onDragEnd={(result) => {
                if (!result.destination) {
                    return;
                }
                DragAndDropArray.moveItem(this.props.photos, result.source.index, result.destination.index, this.props.onChange);
            }}>
                <Droppable droppableId="photos-droppable-list">
                    {(provided, snapshot) => (
                        <List ref={provided.innerRef}>
                            {this.props.photos.map((photo, index) =>
                                <Draggable key={`photo-${index}`} draggableId={`photo-${index}`} index={index}>
                                    {(provided, snapshot) => (
                                        <div className={"row photo-preview"}
                                             ref={provided.innerRef}
                                             {...provided.draggableProps}
                                             {...provided.dragHandleProps}
                                        >
                                            <div className={"col-8"}>
                                                <Square
                                                    style={{backgroundImage: `url(${photo.src && this.props.croppedThumbnails ? getCroppedSrc(photo, {w: 132}) : photo.srcResolved})`}}>
                                                </Square>
                                            </div>
                                            <div className={"col-4"}>
                                                <div className={"buttons"}>
                                                    <SquareButton photo={photo} onClick={() => {
                                                        this.setState({
                                                            croppedPhoto: photo,
                                                            photoCropModalOpen: true,
                                                            aspectName: '1:1'
                                                        });
                                                    }}/>
                                                    <FreeButton photo={photo} onClick={() => {
                                                        this.setState({
                                                            croppedPhoto: photo,
                                                            photoCropModalOpen: true,
                                                            aspectName: 'free'
                                                        });
                                                    }}/>
                                                    <DownloadButton photo={photo}/>
                                                    <DeleteButton photos={this.props.photos} photo={photo}
                                                                  update={this.props.onChange}/>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>)}
                            {provided.placeholder}
                        </List>
                    )}</Droppable>
            </DragDropContext>
        </>
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    productId: externalProps.productId,
    photos: _.get(state.ProductData.productData, externalProps.path),
    guidelines: _.get(state.ProductData.productData, externalProps.guidelinesPath),
    croppedThumbnails: externalProps.croppedThumbnails
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onChange: (value: Photo[]) => dispatch(updateValue(externalProps.path, value)),
    onGuidelinesChange: (value: TemplateGuideLine[]) => dispatch(updateValue(externalProps.guidelinesPath, value))

});

export default connect(mapStateToProps, mapDispatchToProps)(Photos);