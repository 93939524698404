import React from "react";
import {Button, InputAdornment} from "@material-ui/core";
import TextField from "../../Common/TextField/TextField";
import styles from "./Details.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../Store";
import {updateValue} from "../../../../ActionCreators/ProductData";
import {Label} from "mesmetric-v2-common/models";
import Box from "../../Common/Box/Box";
import FileCopy from "@material-ui/icons/FileCopy";

const PRICE_PATH = "shop.price";

const Details: React.FC = () => {
    const dispatch = useDispatch();
    const {
        price,
        description,
        currency
    } = useSelector<AppState, { price?: number, description?: Label, currency?: string }>(state => ({
        price: state.ProductData.productData?.price.value,
        description: state.ProductData.productData?.description,
        currency: state.ProductData.productData?.price?.currency?.code
    }));

    const copy = () => {
        dispatch(updateValue(PRICE_PATH, price));
        dispatch(updateValue("shop.description", {...description}));
    };

    return <Box
        title={"Dane"}
        className={styles.box}
        headerComponent={
            <Button
                className={styles.copy}
                variant={"contained"}
                size={"small"}
                color={"default"}
                onClick={copy}
            >
                <FileCopy/>
            </Button>}>

        <TextField
            label={"Cena"}
            path={PRICE_PATH}
            type={"number"}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <>{currency?.toUpperCase()}</>
                    </InputAdornment>
                )
            }}
        />
        <TextField
            className={styles.textArea}
            multiline
            valueConverter={value => value ? value : ""}
            label={"Opis polski"}
            path={"shop.description.pl"}
        />
        <TextField
            className={styles.textArea}
            multiline
            valueConverter={value => value ? value : ""}
            label={"Opis angielski"}
            path={"shop.description.en"}
        />
    </Box>;
};

export default Details;