import React, {Component} from "react";
import {CheckboxButtonNotConnected} from "../../Views/ProductView/Common/CheckboxButton/CheckboxButton";
import {Button} from "@material-ui/core";
import {connect} from "react-redux";
import {AppState} from "../../Store";
import {revertProductChanges, saveProduct, setAutoSave} from "../../ActionCreators/ProductData";

type DispatchProps = {
    setAutoSave: (enabled: boolean) => void,
    revertProductChanges: () => void,
    saveProduct: () => void
}

type StateProps = {
    productLoaded: boolean,
    productId: string,
    autoSaveEnabled: boolean,
    isSaving: boolean,
    changesDetected: boolean
}

type ProductControlsProps = DispatchProps & StateProps;

class ProductControls extends Component<ProductControlsProps> {

    public render = (): JSX.Element | null =>
        this.props.productLoaded ?
            <div className={"product-controls"}>
                <CheckboxButtonNotConnected
                    value={this.props.autoSaveEnabled}
                    onChange={this.props.setAutoSave}
                    label={this.props.autoSaveEnabled ? (this.props.isSaving ? "Zapisuję..." : "Automatyczny zapis") : "Automatyczny zapis"}/>
                {!this.props.autoSaveEnabled &&
                <Button
                    variant="contained"
                    disabled={!this.props.changesDetected}
                    onClick={this.props.isSaving ? undefined : this.props.saveProduct}
                >
                    {this.props.isSaving ? "Zapisuję" : "Zapisz"}
                </Button>}
                {!this.props.autoSaveEnabled &&
                <Button
                    disabled={!this.props.changesDetected || this.props.isSaving}
                    variant="contained"
                    color={"secondary"}
                    onClick={this.props.revertProductChanges}>
                    Odrzuć
                </Button>}
            </div> : null;
}

const mapStateToProps = (state: AppState): StateProps => ({
    productLoaded: state.ProductData.productData !== undefined,
    productId: state.ProductData.productData?._id || "",
    autoSaveEnabled: state.ProductData.autoSaveEnabled,
    isSaving: state.ProductData.saveInProgress,
    changesDetected: state.ProductData.changesDetected
});

const mapDispatchToProps: DispatchProps = ({
    setAutoSave,
    revertProductChanges,
    saveProduct
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductControls);

