import React, {Component} from "react";
import {connect} from "react-redux";
import {File} from "mesmetric-v2-common/models";
import PictureAsPdfOutlined from "@material-ui/icons/PictureAsPdfOutlined";

import Button from "@material-ui/core/Button";
import CloudDownloadOutlined from "@material-ui/icons/CloudDownloadOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import "./brochures.scss";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {AppState} from "../../../../Store";
import Square from "../../../ProductView/ProductDetails/FilesUploaded/Square/Square";
import {updateValue} from "../../../../ActionCreators/BrandData";

interface StateProps {
    brochures?: File[]
}

interface DispatchProps {
    onChange: (value: File[]) => void
}

type Brochure2D3DProps = StateProps & DispatchProps;

interface FileUploadedProps {
    fileSrc: string,
    onDeleteClicked: () => void,
    icon: JSX.Element,
    fileName?: string
}

const FileUploaded: React.FC<FileUploadedProps> = ({icon, fileSrc, onDeleteClicked, fileName}) =>
    <div className={"row brochure-2D-3D"}>
        <div className={"col-8"}>
            <Square className={"uploaded-file"}>
                {icon}
                {fileName && <div className={"file-name"}>
                    <span
                        className={"name"}>{fileName.slice(0, -4)}</span>.<span>{fileName.slice(fileName.length - 3, fileName.length)}</span>
                </div>}
            </Square>
        </div>
        <div className={"col-4"}>
            <div className={"buttons"}>
                <Button
                    className={"download-button"}
                    size="small"
                    href={fileSrc}
                    target={"_blank"}
                    variant="contained">
                    <CloudDownloadOutlined/>
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    onClick={onDeleteClicked}
                    color="secondary">
                    <DeleteIcon/>
                </Button>
            </div>
        </div>
    </div>;

class Brochures extends Component<Brochure2D3DProps> {
    static defaultProps = {
        brochures: []
    };

    public render = (): JSX.Element =>
        <>
            {this.props.brochures?.map((brochure, index) =>
                <FileUploaded
                    key={index}
                    icon={<PictureAsPdfOutlined/>}
                    fileSrc={brochure.srcResolved || ""}
                    fileName={brochure.originalFilename}
                    onDeleteClicked={() => {
                        const brochures = this.props.brochures?.filter(el => el !== brochure) || [];
                        this.props.onChange(brochures);
                    }
                    }/>)}
        </>
}

const mapStateToProps = (state: AppState): StateProps => ({
    brochures: state.BrandData.brandData?.brochures
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    onChange: (value: File[]) => dispatch(updateValue("brochures", value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Brochures);