import React, {useState} from "react";
import commonStyles from "../CommonComponents/CommonStyles.module.scss";
import Box from "../ProductView/Common/Box/Box";
import {AutocompleteSelectFieldNotConnected} from "../ProductView/Common/AutocompleteSelectField/AutocompleteSelectField";
import styles from "./Dictionaries.module.scss";
import ProductElementsDictionary from "./ProductElementsDictionary/ProductElementsDictionary";

interface DictionaryOption {
    name: string,
    _id: "PRODUCT_ELEMENTS" | "CHUJ"
}

const DICTIONARIES: DictionaryOption[] = [
    {
        _id: "PRODUCT_ELEMENTS",
        name: "Elementy produktów",
    }
];

const Dictionaries: React.FC = () => {
    const [selectedDictionary, setSelectedDictionary] = useState<DictionaryOption | undefined>(DICTIONARIES[0]);
    return <div className={commonStyles.content}>
        <Box title={"Słowniki"} className={styles.box}>
            <div className={"row"}>
                <div className={"col-4"}>
                    <AutocompleteSelectFieldNotConnected
                        label={"Wybierz słownik"}
                        onChange={item => setSelectedDictionary(item)}
                        value={selectedDictionary}
                        optionsMapper={item => ({
                            label: item.name,
                            value: item
                        })}
                        optionsProvider={async () => [...DICTIONARIES]}
                    />
                </div>
            </div>
            {selectedDictionary?._id === "PRODUCT_ELEMENTS" &&
            <ProductElementsDictionary/>}
        </Box>
    </div>
}

export default Dictionaries;