import Dialog, {DialogProps} from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React, {ReactNode} from "react";
import AbsoluteSpinner from "./AbsoluteSpinner/AbsoluteSpinner";


const enterKeyCode = 13;

export interface FormDialogProps extends DialogProps {
    title?: string
    children: ReactNode
    onSave?: (data?: any) => void
    onClose?: () => void
    isLoading?: boolean
    canSave?: boolean,
    saveLabel?: string,
    cancelLabel?: string
}

export default React.memo(({
                               title,
                               children,
                               onClose,
                               onSave,
                               isLoading,
                               canSave,
                               saveLabel,
                               cancelLabel,
                               ...dialogProps
                           }: FormDialogProps) => {
    return (
        <Dialog {...dialogProps} onKeyPress={e => {
            if (e.charCode !== enterKeyCode) {
                return;
            }
            onSave && onSave();
        }} aria-labelledby="form-dialog-title" fullWidth={true}>
            {title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
            <DialogContent style={{height: '67%'}}>
                {children}
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={() => {
                    onClose && onClose();
                }} color="primary">
                    {cancelLabel || "Anuluj"}
                </Button>
                <Button disabled={isLoading || !canSave} onClick={canSave ? onSave : undefined} color="primary">
                    {saveLabel || "Zapisz"}
                </Button>
                {isLoading && <AbsoluteSpinner/>}
            </DialogActions>
        </Dialog>
    )
}, () => false);