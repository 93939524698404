import {Column} from "../CommonComponents/Table/Column";
import {Brand, Photo} from "mesmetric-v2-common/models";
import styles from "./Brands.module.scss";
import {getCroppedSrc} from "../../Utils/PhotoUtils";
import AspectRatio from "@material-ui/icons/AspectRatio";
import {BadIcon, OKIcon} from "../../Common/Icons";
import Crop169Icon from "@material-ui/icons/Crop169";
import CropDinIcon from "@material-ui/icons/CropDin";
import React from "react";

export const COLUMNS: Column<Brand>[] = [
    {
        name: "Aktywna",
        dataKey: "isActive",
        format: item => item.isActive ? <OKIcon/> : <BadIcon/>,
        filterType: "BOOLEAN",
        filter: (item, filter) => {
            if (filter === "") {
                return true;
            }
            return item.isActive === (filter === "true");
        },
        maxWidth: 83,
    },
    {
        name: "Zdjęcie",
        dataKey: "_id",
        maxWidth: 132,
        format: item => {
            let photo;
            if (item.photos?.square?.crops.length) {
                photo = item.photos?.square;
            } else if (item.photos?.rectangle?.crops.length) {
                photo = item.photos?.rectangle;
            } else {
                photo = item.photos?.fullScreen;
            }
            if (!photo) {
                return <span className={styles.image}>Brak zdjęcia</span>;
            }
            return <img alt={item.name} className={styles.image} src={getCroppedSrc(photo, {h: 100})}/>
        },
        filterType: "BOOLEAN",
        filter: (item, filter) => {
            if (filter === "") {
                return true;
            }
            const filterValue = filter === "true";
            if (filterValue) {
                return !!item.photos?.fullScreen?.src
            }
            return !item.photos?.fullScreen?.src;
        },
    },
    {
        name: "Nazwa",
        dataKey: "name",
        filterType: "TEXT",
        filter: (item, filter) => !filter || item.name.toLowerCase().includes(filter?.toLowerCase())
    },
    {
        name: "Kod",
        dataKey: "code",
        filterType: "TEXT",
        maxWidth: 150,
        filter: (item, filter) => !filter || item.code.toLowerCase().includes(filter?.toLowerCase())
    },
    {
        name: "Mnożnik",
        dataKey: "priceMultiplier",
        filterType: "NUMBER",
        maxWidth: 150,
        filter: (item, filter) => !filter || item.priceMultiplier === parseFloat(filter)
    },
    {
        name: "Kolejność",
        dataKey: "order",
        filterType: "NUMBER",
        maxWidth: 150,
        filter: (item, filter) => !filter || item.order === parseInt(filter)
    },
    {
        name: "Krótki opis",
        dataKey: "shortDescription",
        filterType: "BOOLEAN",
        maxWidth: 150,
        filter: (item, filter) => {
            if (!filter) {
                return true;
            }
            const filterTrue = filter === "true";
            if (filterTrue) {
                return !!item.shortDescription?.pl === (filter === "true") && !!item.shortDescription?.en === (filter === "true")
            }
            return !!item.shortDescription?.pl === (filter === "true") || !!item.shortDescription?.en === (filter === "true")
        },
        format: item => <div className={styles.photosIcons}>
            <div className={styles.icon}>
                <span>PL </span>{item.shortDescription?.pl ? <OKIcon/> : <BadIcon/>}
            </div>
            <div className={styles.icon}>
                <span>EN </span>{item.shortDescription?.en ? <OKIcon/> : <BadIcon/>}
            </div>
        </div>
    },
    {
        name: "Długi opis",
        dataKey: "description",
        filterType: "BOOLEAN",
        maxWidth: 150,
        filter: (item, filter) => {
            if (!filter) {
                return true;
            }
            const filterTrue = filter === "true";
            if (filterTrue) {
                return !!item.description?.pl === (filter === "true") && !!item.description?.en === (filter === "true")
            }
            return !!item.description?.pl === (filter === "true") || !!item.description?.en === (filter === "true")
        },
        format: item => <div className={styles.photosIcons}>
            <div className={styles.icon}>
                <span>PL </span>{item.description?.pl ? <OKIcon/> : <BadIcon/>}
            </div>
            <div className={styles.icon}>
                <span>EN </span>{item.description?.en ? <OKIcon/> : <BadIcon/>}
            </div>
        </div>
    },
    {
        name: "Wszystkie kadrowania",
        dataKey: "photos", format: item => {
            return <div className={styles.photosIcons}>
                <div className={styles.icon}>
                    <AspectRatio titleAccess={"Pełny ekran"}
                                 fontSize={"small"}/>{item.photos?.fullScreen?.crops.length ? <OKIcon/> : <BadIcon/>}
                </div>
                <div className={styles.icon}>
                    <Crop169Icon titleAccess={"Prostokąt"} fontSize={"small"}/>{item.photos?.rectangle?.crops.length ?
                    <OKIcon/> : <BadIcon/>}
                </div>
                <div className={styles.icon}>
                    <CropDinIcon titleAccess={"Kwadrat"} fontSize={"small"}/>{item.photos?.square?.crops.length ?
                    <OKIcon/> : <BadIcon/>}
                </div>
            </div>
        },
        filterType: "BOOLEAN",
        filter: (item, filter) => {
            if (filter === "") {
                return true;
            }
            const haveCrop = (photo?: Photo) => !!photo?.crops.length

            const filterValue = filter === "true";
            if (filterValue) {
                return haveCrop(item.photos?.fullScreen) && haveCrop(item.photos?.rectangle) && haveCrop(item.photos?.square)
            }
            return !haveCrop(item.photos?.fullScreen) || !haveCrop(item.photos?.rectangle) || !haveCrop(item.photos?.square);
        },
        maxWidth: 250,
    },
    {
        name: "PDFy",
        dataKey: "brochures",
        format: item => {
            return item.brochures?.length ? item.brochures.length.toString() : <BadIcon/>;
        },
        filterType: "BOOLEAN",
        filter: (item, filter) => {
            if (filter === "") {
                return true;
            }
            return (!!item.brochures?.length) === (filter === "true");
        },
        maxWidth: 83,
    }];