import React, {useState} from "react";
import Popper from '@material-ui/core/Popper';
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Fade from "@material-ui/core/Fade";
import "./popper.scss"
import AbsoluteSpinner from "../UI/AbsoluteSpinner/AbsoluteSpinner";

interface Props {
    size?: 'small' | 'medium' | 'large',
    onConfirm: () => Promise<void>
}

const ID = "deleteItem";

const DeleteItem: React.FC<Props> = props => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
    const [inProgress, setInProgress] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const deleteItem = async () => {
        setInProgress(true);
        await props.onConfirm();
        setAnchorEl(null);
        setInProgress(false);
    };

    const onClosePopper = () => {
        setAnchorEl(null);
    };

    return <>
        <Button
            variant="contained"
            color={"secondary"}
            size={props.size}
            className={"delete-button"}
            aria-describedby={ID}
            onClick={handleClick}>
            <DeleteIcon/>
        </Button>
        <Popper id={ID} open={Boolean(anchorEl)} anchorEl={anchorEl}
                transition>
            {({TransitionProps}) => (
                <Fade {...TransitionProps} timeout={350}>
                    <div className="popper">
                        <span>Potwierdź usunięcie</span>
                        <div className={"buttons"}>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={deleteItem}>
                                <span>POTWIERDŹ</span>
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={onClosePopper}>
                                <span>ANULUJ</span>
                            </Button>
                        </div>
                        {inProgress && <AbsoluteSpinner/>}
                    </div>
                </Fade>
            )}
        </Popper>
    </>
}

export default DeleteItem;