import React, {Component} from "react";
import {debounce, Slider as MaterialSlider, TextField} from "@material-ui/core";
import "./slider.scss";

interface SliderProps {
    value: number,
    max: number,
    onChange: (value: number) => void,
    label: string
}

interface SliderState {
    value: number,
    valueSource: ValueOrigin
}

enum ValueOrigin {
    TEXT_FIELD = "TEXT_FIELD",
    SLIDER = "SLIDER"
}

class Slider extends Component<SliderProps, SliderState> {
    constructor(props: SliderProps) {
        super(props);

        this.state = {
            value: this.props.value,
            valueSource: ValueOrigin.SLIDER
        }
    }

    public componentDidUpdate(prevProps: Readonly<SliderProps>): void {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value})
        }
    }

    private onChange = () => {
        this.props.onChange(this.state.value)
    };

    private debounce = debounce(this.onChange, 100);

    public render = (): JSX.Element =>
        <div className={"slider"}>
            <span className={"label"}>{this.props.label}</span>
            <TextField
                value={this.state.valueSource === ValueOrigin.SLIDER ? this.state.value : (this.state.value || "")}
                variant={"outlined"}
                size={"small"}
                onBlur={() => this.setState({valueSource: ValueOrigin.SLIDER})}
                onChange={event => {
                    const value = event.target.value;
                    if ((value && !value.match(/^[0-9]*$/))) {
                        return;
                    }
                    this.setState({value: parseInt(event.target.value || "0"), valueSource: ValueOrigin.TEXT_FIELD});
                    this.debounce();
                }
                }
            />
            <MaterialSlider
                value={this.state.value}
                max={this.props.max}
                onChange={(event, value) => {
                    if (!Array.isArray(value)) {
                        this.setState({value, valueSource: ValueOrigin.SLIDER});
                        this.debounce();
                    }
                }}
            />
        </div>

}

export default Slider;