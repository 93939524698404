interface ClassNameParam {
    [key: string]: boolean | undefined
}

type Class = undefined | string | ClassNameParam | string[];

export const cls = (...args: Class[]): string => {
    const getClassName = (classNameParam?: ClassNameParam | string[]): string => {
        if (!classNameParam) {
            return "";
        } else if (Array.isArray(classNameParam)) {
            return classNameParam.filter(Boolean).join(" ");
        } else {
            const className = Object.getOwnPropertyNames(classNameParam)[0];
            const show = classNameParam[className];
            return show ? className : "";
        }
    };

    return args.map(el => typeof el === "string" ? el : getClassName(el)).filter(Boolean).join(" ");
};
