import {Product} from "mesmetric-v2-common/models";

export const LocalStorageKey = {
    HIDDEN_COLUMNS: "hidden-columns",
    VIEW_TYPE: "view-type",
    TOKEN: "token",
    AUTO_SAVE_DISABLED_FOR_PRODUCTS: "auto-save-enabled",
    PRODUCTS_FILTERS: "products-filters",
    PRODUCTS_SCROLL_TOP: "products-scroll-top",
    PRODUCTS_LAST_OPENED_ID: "products-last-opened-id",
    TABLE_FILTERS: (id: string) => `${id}-filters`,
    PRODUCT: (product?: Product) => {
        let _a;
        return `product-${(_a = product) === null || _a === void 0 ? void 0 : _a._id}}`;
    },
    TABLE_SCROLL_TOP: (id: string) => `${id}-scroll-top`,
    TABLE_LAST_OPENED_ID: (id: string) => `${id}-last-opened-id`
}
