import * as Styled from "./Tools.styled";
import Box from "../../ProductView/Common/Box/Box";
import React, {useState} from "react";
import {parseError} from "../../../ActionCreators/Error";
import axios from "axios";
import {getAxiosConfig} from "../../../ActionCreators/User";

const CSVUpload: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const fileInput = React.createRef<HTMLInputElement>();
    const uploadFile = async (file: File | undefined) => {
        if (file) {
            setLoading(true);
            const formData = new FormData();
            formData.append("file", file);
            try {
                await axios.post(process.env.REACT_APP_DATA_ENDPOINT + "/admin/migrate-prices", formData, getAxiosConfig());
            } catch (e) {
                parseError(e);
            }
            setLoading(false);
        }
    };

    return <Box title={"Import cen i walut z pliku CSV"}>
        <Styled.HiddenInput
            accept={".csv"}
            id={"contained-button-file"}
            type={"file"}
            ref={fileInput}
            onChange={async event => {
                const target = event.target;
                await uploadFile(event.target.files?.[0]);
                target.value = "";
            }}
        />
        <label htmlFor={"contained-button-file"}>
            {loading && <Styled.LinearProgress
                color={"primary"}/>}
            {!loading &&
            // @ts-ignore
            <Styled.UploadButton variant={"contained"} color={"primary"} component={"span"}>
                Upload
            </Styled.UploadButton>}
        </label>
    </Box>;
}

export default CSVUpload;