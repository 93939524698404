import React from "react";
import * as Styled from "./MassChanges.styled";
import DeactivateProducts from "./DeactivateProducts/DeactivateProducts";
import RemoveProducts from "./RemoveProducts/RemoveProducts";
import ActivateProducts from "./ActivateProducts/ActivateProducts";

interface Props {
    hidden?: boolean
}

const MassChanges: React.FC<Props> = ({hidden}) => {
    return <Styled.Content hidden={hidden}>
        <ActivateProducts/>
        <DeactivateProducts/>
        <RemoveProducts/>
    </Styled.Content>
}

export default MassChanges;