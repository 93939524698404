import React, {Component} from "react";
import Box from "../Common/Box/Box";
import TextField from "../Common/TextField/TextField";
import "./descriptionAndNotes.scss";

interface DescriptionAndNotesProps {
    visible: boolean
}

const YandexLogo = <svg xmlns="http://www.w3.org/2000/svg" width="107" height="43" viewBox="0 0 107 43">
    <g fill="none">
        <path
            d="M29.244 33.263c-.685 1.044-2.005 1.898-3.325 1.898-1.957 0-2.886-1.803-2.886-4.555 0-3.085 1.027-5.125 5.77-5.125h.441v7.782zm3.766-.712V21.305c0-5.789-2.397-7.592-6.75-7.592-2.493 0-4.743.997-5.867 1.898l.733 3.274c1.272-1.091 2.934-2.088 4.89-2.088 2.152 0 3.228 1.33 3.228 4.46v1.424h-.538c-6.895 0-9.585 3.227-9.585 8.352 0 4.697 2.396 7.26 5.917 7.26 2.152 0 3.521-.902 4.597-2.23h.245c.049.616.195 1.375.342 1.897h3.228a33.41 33.41 0 0 1-.44-5.409zm8.434-13.114c.88-1.187 2.25-2.373 3.864-2.373 1.467 0 2.298.617 2.298 2.42V37.99h3.863V19.152c0-3.654-1.711-5.41-4.939-5.41-2.298 0-4.157 1.472-4.841 2.373h-.245v-2.088H37.63V37.99h3.814V19.437zm25.847 16.625h.244l.294 1.898h2.836V4.02H66.85v10.595c-.733-.522-1.956-.902-3.13-.902-4.939 0-8.362 4.318-8.362 13.191 0 7.403 2.641 11.389 7.482 11.389 2.005 0 3.375-.902 4.45-2.23zm-.44-2.752c-.636.95-1.663 1.85-3.57 1.85-2.788 0-3.864-3.416-3.864-9.015 0-4.887 1.467-9.395 4.548-9.395 1.272 0 2.103.38 2.885 1.139V33.31zm21.761 3.037l-.88-2.847c-.978.664-2.543 1.613-4.695 1.613-3.032 0-4.596-2.847-4.596-8.256h10.27v-2.04c0-8.114-2.69-11.104-6.798-11.104-5.233 0-7.434 5.6-7.434 13.286 0 7.355 3.13 11.294 8.216 11.294 2.445 0 4.352-.76 5.917-1.946zm-6.7-19.597c2.201 0 2.886 2.99 2.886 7.07h-6.309c.245-4.27 1.076-7.07 3.424-7.07zm19.66-2.705l-3.375 8.684-3.178-8.684h-3.962l4.646 11.53-5.135 12.385h3.766l3.717-9.87 3.814 9.87h3.961l-5.135-12.716 4.597-11.199h-3.716z"
            fill="#000"/>
        <path fill="#F00"
              d="M12.858 38.003V25.998L22.59.043h-4.318l-7.273 20.83-6.182-16.83H.438l8.556 21.813v12.147z"/>
    </g>
</svg>;

const PoweredByYandex = <a className={"yandex-info"} href={"https://translate.yandex.com/"} target={"_blank"}>Powered
    by Yandex.Translate{YandexLogo}</a>;

class DescriptionAndNotes extends Component<DescriptionAndNotesProps> {
    public render = (): JSX.Element =>
        <div className={"description-and-notes"} hidden={!this.props.visible}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <Box title={"Opis"}
                    >
                        <TextField
                            multiline
                            valueConverter={value => value ? value : ""}
                            label={"Polski"}
                            path={"description.pl"}
                        />
                        {/*<Translate*/}
                        {/*    sourcePath={"description.pl"}*/}
                        {/*    destinationPath={"description.en"}*/}
                        {/*/>*/}
                        <TextField
                            multiline
                            valueConverter={value => value ? value : ""}
                            label={"Angielski"}
                            path={"description.en"}
                        />
                    </Box>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-12"}>
                    <Box title={"Notatki"}>
                        <TextField
                            multiline
                            valueConverter={value => value ? value : ""}
                            label={"Notatki"}
                            path={"notes"}
                        />
                    </Box>
                </div>
            </div>
        </div>
}

export default DescriptionAndNotes;