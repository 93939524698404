import {ThunkAction} from "redux-thunk";
import {AppState} from "../Store";
import {loggedIn} from "./User";

export enum ActionTypes {
    initializeApp = "initializeApp"
}

export interface InitializeAppAction {
    type: ActionTypes.initializeApp
}

export const initializeApp = (): ThunkAction<void, AppState, {}, InitializeAppAction> =>
    async (dispatch, getState) => {
        await dispatch(loggedIn());
        dispatch({
            type: ActionTypes.initializeApp
        });
    };