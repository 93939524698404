import {Brand} from "mesmetric-v2-common/models";

export interface BrandDataState {
    brandData?: Brand,
    fetchedBrandData?: Brand,
    saveInProgress: boolean,
    changesDetected: boolean
}

export const initialState: BrandDataState = {
    saveInProgress: false,
    changesDetected: false
};