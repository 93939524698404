import React from 'react'
import {Dictionary, ProductAttribute, ProductAttributeVariant} from "mesmetric-v2-common/models";
import "./variant.scss"
import VariantCard from "./Card/VariantCard";
import _ from "lodash";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import Attributes from "../../../Attributes/Attributes";
import {AppState} from "../../../../../../Store";
import NameFields from "./NameFields/NameFields";
import {Action} from "redux";
import {updateValue} from "../../../../../../ActionCreators/ProductData";

interface ExternalProps {
    path: string
    attributesProvided: ProductAttribute.Any[]
    variantId: string
    onVariantRemove: () => void
    onDictionaryUpdated: (attributeId: number, value: Dictionary) => void
    isNewlyAdded: boolean,
    onVariantClone: () => void
}

interface StateProps {
    path: string
    attributesProvided: ProductAttribute.Any[]
    variant: ProductAttributeVariant
    variantId: string
    onVariantRemove: () => void
    onVariantClone: () => void
    onDictionaryUpdated: (attributeId: number, value: Dictionary) => void
    isNewlyAdded: boolean
}

interface DispatchProps {
    onVariantUpdate: (value: ProductAttributeVariant) => void
}

type Props = StateProps & DispatchProps

const Variant: React.FC<Props> = ({variant = {} as ProductAttributeVariant, ...props}) => {

    const onLabelChange = (value: any): any => {
        const updatedVariant = {...variant};
        const newVariant = {
            attributes: updatedVariant.attributes,
            label: value
        };
        props.onVariantUpdate(newVariant)
    };

    return <VariantCard
        key={props.variantId}
        title={variant?.label?.pl}
        variantId={props.variantId}
        onVariantRemove={props.onVariantRemove}
        onVariantClone={props.onVariantClone}
        newlyAdded={props.isNewlyAdded}
        attributesProvided={props.attributesProvided}
        path={`${props.path}.attributes`}>
        <NameFields
            onLabelChange={onLabelChange}
            path={`${props.path}.label`}/>
        <div className={"variant-elements"}>
            <Attributes
                path={`${props.path}.attributes`}
                attributesProvided={props.attributesProvided}
                onDictionaryUpdated={props.onDictionaryUpdated}
            />
        </div>
    </VariantCard>
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    variant: _.get(state.ProductData.productData, externalProps.path),
    attributesProvided: externalProps.attributesProvided,
    path: externalProps.path,
    onVariantRemove: externalProps.onVariantRemove,
    variantId: externalProps.variantId,
    onDictionaryUpdated: externalProps.onDictionaryUpdated,
    isNewlyAdded: externalProps.isNewlyAdded,
    onVariantClone: externalProps.onVariantClone
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onVariantUpdate: (value: ProductAttributeVariant) => dispatch(updateValue(`${externalProps.path}`, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Variant);