import React, {useRef, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, LinearProgress} from "@material-ui/core";
import {TextFieldNotConnected} from "../../../../Common/TextField/TextField";
import styles from "./NewVariant.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../../../../../Store";
import {addElementVariant, findElementsVariants} from "../../../../../../ActionCreators/ProductElements";
import {AutocompleteSelectFieldNotConnected} from "../../../../Common/AutocompleteSelectField/AutocompleteSelectField";
import {postTransform} from "../../../../../../Utils/PhotoUtils";
import {Brand, ProductElement, ProductElementVariant} from "mesmetric-v2-common/models";

interface Props {
    onClose: (newElement?: ProductElementVariant) => void,
    productElement: ProductElement,
    filterOut?: ProductElementVariant[]
}

const NewVariant: React.FC<Props> = ({onClose, productElement, filterOut}) => {
    const [name, setName] = useState("");
    const [file, setFile] = useState<File>();
    const dispatch = useDispatch<ThunkDispatch<AppState, never, never>>();
    const [isSaving, setIsSaving] = useState(false);
    const [previousVariant, setPreviousVariant] = useState();
    const inputRef = useRef<HTMLInputElement>(null);
    const brand = useSelector<AppState, Brand | undefined>(state => state.ProductData.productData?.brand);
    const onAdd = async () => {
        if (previousVariant) {
            onClose(previousVariant);
            return;
        }

        if (name && file && productElement && brand) {
            setIsSaving(true)
            const element = await dispatch(addElementVariant(name, file, productElement, brand));
            setIsSaving(false);
            onClose(element);
        }
    };

    return <Dialog open>
        <DialogContent className={styles.form}>
            <AutocompleteSelectFieldNotConnected
                value={previousVariant}
                onChange={item => setPreviousVariant(item)}
                label={"Wybierz istniejący z tej samej marki"}
                optionsProvider={async () => {
                    if (!brand) {
                        return [];
                    }

                    const options = await dispatch(findElementsVariants(productElement, brand));
                    if (filterOut) {
                        return options?.filter(option => !filterOut.some(f => option._id === f._id));
                    }
                    return options;
                }}
                optionsMapper={(item: ProductElementVariant) => ({
                    label: item.name.pl,
                    value: item
                })}
                renderOption={item => {
                    return <div className={styles.otherProductElement}>
                        <img
                            alt={item.value.name.pl}
                            src={item.value.image.srcResolved + "?" + postTransform({w: 50, h: 50}).join("&")}/>
                        {item.value.name.pl}
                    </div>
                }
                }
            />

            <div className={styles.or}>lub dodaj nowy</div>

            <TextFieldNotConnected
                label={"Nazwa wariantu"}
                value={name}
                disabled={!!previousVariant}
                onChange={value => setName(value)}
                type={"text"}
            />
            <span className={styles.imageTitle}>Zdjęcie</span>
            <div className={styles.upload}>
                <input
                    className={styles.hide}
                    type={"file"}
                    accept="image/*"
                    disabled={!!previousVariant}
                    id={"upload"}
                    ref={inputRef}
                    onChange={event => {
                        setFile(event.target.files?.[0]);
                    }}
                />
                <Button
                    variant={"contained"}
                    onClick={() => inputRef.current?.click()}>
                    Upload
                </Button>
                {file && <span>{file.name}</span>}
            </div>

        </DialogContent>
        <DialogActions className={styles.actions}>
            {isSaving && <LinearProgress className={styles.add}/>}
            {!isSaving &&
            <Button
                className={styles.add}
                color={"primary"}
                variant={"contained"}
                disabled={!previousVariant && (!name || !file)}
                onClick={onAdd}
            >
                Dodaj
            </Button>}
            <Button
                color={"primary"}
                disabled={isSaving}
                onClick={() => onClose()}
            >
                Odrzuć
            </Button>
        </DialogActions>
    </Dialog>
};

export default NewVariant;