import React, {Component} from "react";
import styles from "./ItemForm.module.scss";
import PrimeCategory from "./PrimeCategory";

export interface ItemData {
    categoryId?: string
}

interface Props {
    userData?: ItemData,
    type: "ADD" | "EDIT",
    onChange: (user: ItemData) => void,
    disabled?: boolean
}

class ItemForm extends Component<Props> {

    private onChange = (value: string | undefined, field: keyof ItemData) => {
        this.props.onChange({
            ...this.props.userData || {},
            [field]: value
        })
    };

    public render = (): JSX.Element =>
        <div className={styles.itemForm}>
            <PrimeCategory
                label={"Kategoria produktu"}
                path={"primaryCategory"}
                fullWidth
                onChange={id => this.onChange(id, "categoryId")}
            />
        </div>
}

export default ItemForm;