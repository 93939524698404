import React, {Component} from "react";
import {Product} from "mesmetric-v2-common/models";
import Box from "../../Common/Box/Box";
import "./relatedProduct.scss";
import Square from "../../ProductDetails/FilesUploaded/Square/Square";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import {getCroppedSrc} from "../../../../Utils/PhotoUtils";
import {BadIcon, OKIcon} from "../../../../Common/Icons";

interface RelatedProductProps {
    onRemove?: (product: Product) => void,
    product: Product,
}

class RelatedProduct extends Component<RelatedProductProps> {

    private renderDetail = (title: string, value?: string) =>
        value ? <div className={"detail"}><span className={"title"}>{title}</span>{value}</div> : null

    public render = (): JSX.Element =>
        <Box
            title={this.props.product.name}
            className={"related-product"}
            hideHeader
        >
            {this.props.product.photos.length &&
            <Square
                style={{backgroundImage: `url(${getCroppedSrc(this.props.product.photos[0])})`}}
            />}
            {this.props.onRemove &&
            <Button
                variant="contained"
                color={"secondary"}
                size={"small"}
                className={"delete-button"}
                onClick={() => this.props.onRemove?.(this.props.product)}>
                <DeleteIcon/>
            </Button>}
            <div className={"related-product-details"}>
                {this.renderDetail("Nazwa", this.props.product.name)}
                {this.renderDetail("Kategoria", this.props.product.primaryCategory.name.pl)}
                {this.renderDetail("Kod", this.props.product.code)}
                {this.renderDetail("Marka", this.props.product.brand?.name)}
                <div className={"detail"}>
                    <span className={"title"}>{"Aktywny"}</span>{this.props.product.isActive ? <OKIcon/> : <BadIcon/>}
                </div>
            </div>
        </Box>
}

export default RelatedProduct