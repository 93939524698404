import React, {Component} from "react";
import Box from "../../Common/Box/Box";
import PrimaryCategory from "./PrimaryCategory/PrimaryCategory";
import AdditionalCategories from "./AdditionalCategories/AdditionalCategories";

interface ProductFeaturesProps {
}

class Categories extends Component<ProductFeaturesProps> {
    public render = (): JSX.Element =>
        <Box title={"Kategorie"}>
            <PrimaryCategory
                label={"Kategoria Główna"}
                path={"primaryCategory"}/>
            <AdditionalCategories
                label={"Kategorie Dodatkowe"}
                path={"categories"}/>
        </Box>
}

export default Categories;