import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../../../../Store";
import _ from "lodash";
import {getValuesForGenerator, updateValue} from "../../../../ActionCreators/ProductData";
import {Button, InputAdornment, TextField as MaterialTextField} from "@material-ui/core";
import "./generateField.scss";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";

interface ExternalProps {
    path: string,
    label: string,
    type?: string,
    pathsForGenerator: string[],
    generate: (values: any[]) => string
}

interface StateProps {
    value: any,
    label: string,
    type?: string,
    generate: (values: any[]) => string
}

interface DispatchProps {
    onChange: (value: any) => void,
    getValuesForGenerator: () => any[]
}

type GenerateFieldProps = StateProps & DispatchProps;

class GenerateField extends Component<GenerateFieldProps> {
    public render = () =>
        <MaterialTextField
            className={"generate-field"}
            label={this.props.label}
            variant="outlined"
            size={"small"}
            value={this.props.value}
            onChange={event => this.props.onChange(event.target.value)}
            type={this.props.type}
            InputProps={{
                endAdornment:
                    <InputAdornment position="end">
                        <Button variant="contained"
                                onClick={() => this.props.onChange(this.props.generate(this.props.getValuesForGenerator()))}>
                            generuj
                        </Button>
                    </InputAdornment>
            }}
        />

}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    value: _.get(state.ProductData.productData, externalProps.path, ""),
    label: externalProps.label,
    type: externalProps.type,
    generate: externalProps.generate
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onChange: (value: any) => dispatch(updateValue(externalProps.path, value)),
    getValuesForGenerator: () => dispatch(getValuesForGenerator(externalProps.pathsForGenerator))
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerateField)