import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './Fonts/Jenson.otf';
import {HashRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./Store";
import {initializeApp} from "./ActionCreators/App";

const Content = (): JSX.Element => {
    const createdStore = store;
    // @ts-ignore
    createdStore.dispatch(initializeApp());
    return <Router>
        <Provider store={store}>
            <App/>
        </Provider>
    </Router>;
};

ReactDOM.render(<Content/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
