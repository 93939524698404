import {ActionTypes, AllActions, FAKE_PRODUCT} from "../ActionCreators/Products";
import {initialState, ProductsState} from "../State/Products";

export default function ProductsReducer(state = initialState, action: AllActions): ProductsState {
    switch (action.type) {
        case ActionTypes.setViewType: {
            return {
                ...state,
                viewType: action.viewType
            }
        }
        case ActionTypes.setIsSearchingProducts: {
            return {
                ...state,
                isSearching: action.isSearching
            }
        }
        case ActionTypes.setProducts: {
            return {
                ...state,
                products: action.products.length === 0 ? [FAKE_PRODUCT] : action.products,
                count: action.count
            }
        }
        case ActionTypes.setHiddenColumns: {
            return {
                ...state,
                hiddenColumns: action.hiddenColumns
            }
        }
        default:
            return state;
    }
}

