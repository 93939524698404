import Button from "@material-ui/core/Button";
import React, {useState} from "react";
import PhotoGallery from "../../ProductDetails/FilesUploaded/PhotoGallery/PhotoGallery";

const Gallery: React.FC<{ croppedThumbnails: boolean, path: string }> = ({croppedThumbnails, path}) => {
    const [galleryOpened, setGalleryOpened] = useState(false);

    return <>
        {galleryOpened &&
        <PhotoGallery
            onClose={() => setGalleryOpened(false)}
            croppedThumbnails={croppedThumbnails}
            path={path}
        />}
        <Button
            className={"gallery"}
            size={"small"}
            variant={"contained"}
            onClick={() => setGalleryOpened(true)}
        >
            <span>Galeria</span>
        </Button></>
}
export default Gallery;