import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import MaterialDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import "./dialog.scss";

interface ModalButton {
    key: string,
    label: string
}

interface DialogProps {
    onClose: () => void,
    getTitle: () => string,
    open: boolean,
    buttons: ModalButton[],
    onButtonClick: (key: string) => void,
    getMessage: () => string
}

export default class Dialog extends Component<DialogProps> {

    private onClose = () => {

    };

    public render = () =>
        <MaterialDialog
            onClose={this.props.onClose}
            className={"dialog"}
            open={this.props.open}>
            {this.props.open && <>
                <DialogTitle disableTypography className={"dialog-title"}>
                    <Typography variant="h6">{this.props.getTitle()}</Typography>
                    <IconButton aria-label="close" className={"close-button"} onClick={this.props.onClose}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {this.props.getMessage()}
                </DialogContent>
                <DialogActions>
                    {this.props.buttons.map(button =>
                        <Button key={button.key}
                                variant="contained"
                                onClick={() => this.props.onButtonClick(button.key)}>{button.label}</Button>)}
                </DialogActions>
            </>}

        </MaterialDialog>

}