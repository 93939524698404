import React, {Component} from "react";
import {default as Dropzone} from "react-dropzone";
import {File as FileModel, Files as FilesModel, Photo, Product} from "mesmetric-v2-common/models";
import axios from "axios";
import "./photosAndFilesDropzone.scss";
import {getObjectId} from "../../../../../Common/Utility";
import AbsoluteSpinner from "../../../../../Components/UI/AbsoluteSpinner/AbsoluteSpinner";
import {getAxiosConfig} from "../../../../../ActionCreators/User";
import {parseError} from "../../../../../ActionCreators/Error";
import Dialog from "../../../../../Components/Dialog/Dialog";

const pictureExtensions = ["jpg", "jpeg", "png", "tiff", "tif"];

interface PhotosAndFilesDropzoneProps {
    productId: Product["_id"],
    onPictureAdded: (photo: Photo) => void,
    onFileAdded: (file: FilesModel) => void,
    showTitle: boolean
}

interface PhotosAndFilesDropzoneState {
    filesNotSpecified: File[],
    filesToBeUploaded: File[],
    filesNotUploaded: File[]
}

class PhotosAndFilesDropzone extends Component<PhotosAndFilesDropzoneProps, PhotosAndFilesDropzoneState> {
    constructor(props: PhotosAndFilesDropzoneProps) {
        super(props);
        this.state = {
            filesToBeUploaded: [],
            filesNotSpecified: [],
            filesNotUploaded: []
        }
    }

    private uploadFile = (file: File, type: string) => {
        const formData = new FormData();
        formData.append("file", file);
        axios.post<FileModel>(process.env.REACT_APP_DATA_ENDPOINT + `/products/files/${this.props.productId}/${type}`, formData, getAxiosConfig())
            .then(result => {
                const uploadedFile = result.data;
                this.props.onFileAdded({[type]: uploadedFile});
                this.setState({filesToBeUploaded: this.state.filesToBeUploaded.filter(up => up !== file)})
            }).catch(e => {
            parseError(e);
            this.setState({
                filesNotUploaded: this.state.filesNotUploaded.concat(file),
                filesToBeUploaded: this.state.filesToBeUploaded.filter(up => up !== file)
            });
        });
    };

    private uploadPhoto = (file: File) => {
        const fileReader = new FileReader();
        const formData = new FormData();
        let newPhoto: Photo = {
            _id: getObjectId(),
            src: '',
            crops: [],
            srcResolved: fileReader.result as string,
            meta: {
                isLegacy: false
            },
        };
        formData.append("image", file);
        axios.post(process.env.REACT_APP_DATA_ENDPOINT + '/products/photos', formData, getAxiosConfig())
            .then(r => {
                newPhoto.srcResolved = r.data.srcResolved;
                newPhoto.src = r.data.src;
                this.props.onPictureAdded(newPhoto);
                this.setState({filesToBeUploaded: this.state.filesToBeUploaded.filter(up => up !== file)})
            }).catch(e => {
            parseError(e);
            this.setState({
                filesNotUploaded: this.state.filesNotUploaded.concat(file),
                filesToBeUploaded: this.state.filesToBeUploaded.filter(up => up !== file)
            })
        });
    };

    private onDrop = (acceptedFiles: Array<File>) => {
        this.setState({filesToBeUploaded: acceptedFiles, filesNotUploaded: []});

        const isPdf = (file: File) => file.name.toLowerCase().endsWith(".pdf");
        const isImage = (file: File) => pictureExtensions.some(extension => file.name.toLowerCase().endsWith(extension));

        acceptedFiles.filter(isPdf).forEach(file => this.uploadFile(file, "brochure"));
        acceptedFiles.filter(isImage).forEach(file => this.uploadPhoto(file));
        this.setState({filesNotSpecified: acceptedFiles.filter(file => !isImage(file) && !isPdf(file))});
    };

    public render = (): JSX.Element =>
        <>
            <Dialog onClose={() => this.setState({
                filesNotSpecified: this.state.filesNotSpecified.splice(1),
                filesToBeUploaded: this.state.filesToBeUploaded.filter(up => up !== this.state.filesNotSpecified[0])
            })}
                    open={!!this.state.filesNotSpecified.length}
                    buttons={[{key: "2d", label: "2D"}, {key: "3d", label: "3D"}]}
                    onButtonClick={key => {
                        this.uploadFile(this.state.filesNotSpecified[0], key);
                        this.setState({filesNotSpecified: this.state.filesNotSpecified.splice(1)});
                    }}
                    getMessage={() => `Jakiego typu jest plik: ${this.state.filesNotSpecified[0].name}?`}
                    getTitle={() => "Wybierz typ"}>
            </Dialog>
            {this.props.showTitle && "Upload plików"}
            <Dropzone multiple={true} onDrop={this.onDrop}>
                {({getRootProps, getInputProps, isDragActive}) => (<>
                        <div {...getRootProps()} className={"dropzone-target"}>
                            {this.state.filesToBeUploaded.length > 0 &&
                            <AbsoluteSpinner
                                label={<>
                                    {'Liczba plików w kolejce oczekujących na wgranie: ' + this.state.filesToBeUploaded.length + '. Proszę czekać...'}
                                </>}/>}
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <div className={"drag-box active"}>Upuść tutaj ...</div> :
                                    <div className={"drag-box"}>Przeciągnij tutaj pliki lub kliknij aby otworzyć
                                        okno wyboru</div>
                            }
                        </div>
                        {this.state.filesNotUploaded.length > 0 &&
                        <>
                            Pliki, których nie udało się wgrać:
                            <div className={"row"}>
                                {this.state.filesNotUploaded.map(notUploaded =>
                                    <div className={"col-8"} key={notUploaded.name}>
                                        {notUploaded.name}
                                    </div>)}
                            </div>
                        </>
                        }
                    </>
                )}
            </Dropzone>
        </>
}

export default PhotosAndFilesDropzone;