import React from "react";
import Box from "../../ProductView/Common/Box/Box";
import TextField from "../../ProductView/Common/TextField/TextField";

const Descriptions: React.FC = () => {
    return <>
        <Box title={"Opis krótki"}>
            <TextField
                multiline
                valueConverter={value => value ? value : ""}
                label={"Polski"}
                path={"shortDescription.pl"}
                dataType={"Brand"}
            />
            <TextField
                multiline
                valueConverter={value => value ? value : ""}
                label={"Angielski"}
                path={"shortDescription.en"}
                dataType={"Brand"}
            />
        </Box>
        <Box title={"Opis długi"}>
            <TextField
                multiline
                valueConverter={value => value ? value : ""}
                label={"Polski"}
                path={"description.pl"}
                dataType={"Brand"}
            />
            <TextField
                multiline
                valueConverter={value => value ? value : ""}
                label={"Angielski"}
                path={"description.en"}
                dataType={"Brand"}
            />
        </Box>
    </>;
}

export default Descriptions;