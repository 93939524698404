import React, {useState} from "react";
import * as Styled from "./Tools.styled";
import * as CommonStyled from "./Tools.styled";
import Box from "../../ProductView/Common/Box/Box";
import ButtonPopper from "../../CommonComponents/ButtonPopper/ButtonPopper";
import {TextFieldNotConnected} from "../../ProductView/Common/TextField/TextField";
import FileCopy from '@material-ui/icons/FileCopy';
import axios from "axios";
import {getAxiosConfig} from "../../../ActionCreators/User";
import {parseError} from "../../../ActionCreators/Error";
import CSVUpload from "./CSVUpload";

interface Props {
    hidden?: boolean
}

const Tools: React.FC<Props> = ({hidden}) => {
    const [templateId, setTemplateId] = useState("");
    const [attributeName, setAttributeName] = useState("");
    const [saving, setSaving] = useState(false);

    return <Styled.Content hidden={hidden}>
        <Box title={"Konwersja atrybutu z wariantem na bez wariantu"}>
            <CommonStyled.ContentItem>
                <TextFieldNotConnected
                    value={templateId}
                    onChange={setTemplateId}
                    label={"ID szablonu"}
                />
                <TextFieldNotConnected
                    value={attributeName}
                    onChange={setAttributeName}
                    label={"Nazwa atrybutu"}
                />
                <ButtonPopper
                    disabled={saving || !templateId || !attributeName}
                    confirmText={"Na pewno chcesz dokonać konwersji?"}
                    icon={<FileCopy/>}
                    onConfirm={async () => {
                        setSaving(true);
                        try {
                            await axios.post(process.env.REACT_APP_DATA_ENDPOINT + '/templates/convert', {
                                templateId,
                                attributeName
                            }, getAxiosConfig());
                        } catch (e) {
                            parseError(e);
                        }
                        setSaving(false);
                    }}
                />
            </CommonStyled.ContentItem>
        </Box>
        <CSVUpload/>
    </Styled.Content>
}

export default Tools;