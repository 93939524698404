import React, {useState} from "react";
import * as Styled from "../../MassChanges.styled";
import ButtonPopper from "../../../../CommonComponents/ButtonPopper/ButtonPopper";
import {AutocompleteSelectFieldNotConnected} from "../../../../ProductView/Common/AutocompleteSelectField/AutocompleteSelectField";
import Dictionary from "../../../../../DataProviders/Dictionary";
import {basicOptionsMapper} from "../../../../../Utils/DataUtils";
import {CommonItemProps} from "../Common.types";
import {BackendBrand} from "../../../../../ActionCreators/Brands";

const ByBrand: React.FC<CommonItemProps> = ({confirmText, icon, color, onConfirm}) => {
    const [brand, setBrand] = useState<BackendBrand>();

    return <Styled.ContentItem>
        <AutocompleteSelectFieldNotConnected
            onChange={setBrand}
            value={brand}
            label={"Po marce"}
            optionsProvider={Dictionary.getBrands}
            optionsMapper={basicOptionsMapper}
            disableRemove
        />
        <ButtonPopper
            disabled={!brand}
            confirmText={confirmText}
            icon={icon}
            color={color}
            onConfirm={() => onConfirm(brand?._id)}
        />
    </Styled.ContentItem>
}

export default ByBrand;