import styles from "./Attributes.module.scss";
import FileCopy from "@material-ui/icons/FileCopy";
import {Button} from "@material-ui/core";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../Store";
import _ from 'lodash';
import {updateValue} from "../../../../ActionCreators/ProductData";

const Copy: React.FC = () => {
    const attributes = useSelector<AppState>(state => state.ProductData.productData?.attributes)
    const dispatch = useDispatch();
    const copy = () => {
        dispatch(updateValue("shop.attributes", _.cloneDeep(attributes)))
    }

    return <Button
        variant={"contained"}
        size={"small"}
        color={"default"}
        onClick={copy}
        className={styles.copy}
    >
        <FileCopy/>
    </Button>;
}

export default Copy;