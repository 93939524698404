export const basicOptionsMapper = (item: any) => {
    return {
        label: item.name,
        value: item
    };
};

export const multiLanguageLabelMapper = (item: any) => {
    return {
        label: item.label.pl,
        value: item
    };
};

export const multiLanguageNameMapper = (item: any) => {
    return {
        label: item.name.pl,
        value: item
    };
};

export const polishPlural = (singularNominativ: string, pluralNominativ: string, pluralGenitive: string, value: number): string => {
    if (value === 1) {
        return singularNominativ;
    } else if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20)) {
        return pluralNominativ;
    } else {
        return pluralGenitive;
    }
};