export interface User {
    login: string,
    permissions?: {
        admin?: boolean
    }
}

export interface UserState {
    user?: User,
    loggedInChecking: boolean
}

export const initialState: UserState = {
    loggedInChecking: true
};