import {ThunkAction} from "redux-thunk";
import {AppState} from "../Store";
import axios from "axios";
import {getAxiosConfig} from "./User";
import {parseError} from "./Error";
import {Brand, ProductElement, ProductElementVariant} from "mesmetric-v2-common/models";

export enum ActionTypes {
    setProductElements = "setProductElements"
}

export interface SetProductElementsAction {
    type: ActionTypes.setProductElements
    elements: ProductElement[]
}

const setVariants = (elements: ProductElement[]): SetProductElementsAction => ({
    type: ActionTypes.setProductElements,
    elements
});

export const fetchProductsElements = (): ThunkAction<Promise<void>, AppState, {}, SetProductElementsAction> => {
    return async (dispatch) => {
        try {
            const variants = (await axios.get<ProductElement[]>(`${process.env.REACT_APP_DATA_ENDPOINT}/productElements`, getAxiosConfig())).data;
            dispatch(setVariants(variants));
        } catch (e) {
            parseError(e);
        }
    }
};

export const addProductElement = (name: string): ThunkAction<Promise<ProductElement | undefined>, AppState, {}, never> =>
    async (dispatch) => {
        try {
            const variant = (await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/productElements/add`, {name}, getAxiosConfig())).data;
            await dispatch(fetchProductsElements());
            return variant;
        } catch (e) {
            parseError(e);
        }
        return undefined;
    };

export const addElementVariant = (name: string, file: File, productElement: ProductElement, brand: Brand): ThunkAction<Promise<ProductElementVariant | undefined>, AppState, {}, never> =>
    async () => {
        try {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("name", name);
            formData.append("elementId", productElement._id as string);
            formData.append("brandId", brand._id as string);
            return (await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/productElements/variants/add`, formData, getAxiosConfig())).data;
        } catch (e) {
            parseError(e);
        }
        return undefined;
    };

export const findElementsVariants = (element: ProductElement, brand?: Brand): ThunkAction<Promise<ProductElementVariant[] | undefined>, AppState, {}, never> =>
    async () => {
        try {
            return (await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/productElements/variants`,
                {
                    element: element._id,
                    brand: brand?._id
                }, getAxiosConfig())).data;
        } catch (e) {
            parseError(e);
        }
        return undefined;
    };

export const saveProductElements = (elements: ProductElement[], refresh: boolean = true): ThunkAction<Promise<void>, AppState, {}, never> =>
    async (dispatch) => {
        try {
            await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/productElements/edit`, elements, getAxiosConfig());
            refresh && await dispatch(fetchProductsElements());
        } catch (e) {
            parseError(e);
        }
        return undefined;
    };

export const saveProductElementsVariants = (variants: ProductElementVariant[]): ThunkAction<Promise<void>, AppState, {}, never> =>
    async () => {
        try {
            await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/productElements/variants/edit`, variants, getAxiosConfig());
        } catch (e) {
            parseError(e);
        }
        return undefined;
    };

export const removeProductElements = (ids: string[], refresh: boolean = true): ThunkAction<Promise<void>, AppState, {}, never> =>
    async (dispatch) => {
        if (!ids.length) {
            return;
        }
        try {
            await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/productElements/remove`, ids, getAxiosConfig());
            refresh && await dispatch(fetchProductsElements());
        } catch (e) {
            parseError(e);
        }
        return undefined;
    };

export const removeProductElementsVariants = (ids: string[]): ThunkAction<Promise<void>, AppState, {}, never> =>
    async () => {
        if (!ids.length) {
            return;
        }
        try {
            await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/productElements/variants/remove`, ids, getAxiosConfig());
        } catch (e) {
            parseError(e);
        }
        return undefined;
    };

export type AllActions =
    SetProductElementsAction