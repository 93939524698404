import {ActionTypes, AllActions} from "../ActionCreators/User";
import {initialState, UserState} from "../State/User";

export default function UserReducer(state = initialState, action: AllActions): UserState {
    switch (action.type) {
        case ActionTypes.setUser: {
            return {
                ...state,
                user: action.user,
                loggedInChecking: false
            }
        }
        case ActionTypes.notLoggedIn: {
            return {
                ...state,
                loggedInChecking: false,
                user: undefined
            }
        }
        default:
            return state;
    }
}

