import React, {useState} from "react";
import Box from "../../ProductView/Common/Box/Box";
import AbsoluteSpinner from "../../../Components/UI/AbsoluteSpinner/AbsoluteSpinner";
import {default as Dropzone} from "react-dropzone";
import axios from "axios";
import {File as FileModel, Photo} from "mesmetric-v2-common/models";
import {getAxiosConfig} from "../../../ActionCreators/User";
import {parseError} from "../../../ActionCreators/Error";
import {connect} from "react-redux";
import {AppState} from "../../../Store";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {addBrochure, updateValue as brandUpdateValue} from "../../../ActionCreators/BrandData";
import {getObjectId} from "../../../Common/Utility";

const pictureExtensions = ["jpg", "jpeg", "png", "tiff", "tif"];

export interface BrandPhotos {
    square?: Photo,
    rectangle?: Photo,
    fullScreen?: Photo
}

type StateProps = {
    brandId?: string,
    brochures: FileModel[],
    photos?: BrandPhotos
}

interface DispatchProps {
    onChange: (path: string, value: any) => void,
    addBrochure: (brochure: FileModel) => void
}

const FilesUpload: React.FC<StateProps & DispatchProps> = (props) => {
    const [filesToBeUploaded, setFilesToBeUploaded] = useState<File[]>([]);
    const [filesNotUploaded, setFilesNotUploaded] = useState<{ file: File, reason: string }[]>([]);

    const uploadFile = async (file: File) => {
        const formData = new FormData();
        formData.append("file", file);
        try {
            const response = await axios.post<FileModel>(process.env.REACT_APP_DATA_ENDPOINT + `/brands/files/${props.brandId}`, formData, getAxiosConfig());
            const uploadedFile = response.data;
            props.addBrochure(uploadedFile);
            setFilesToBeUploaded(state => state.filter(up => up !== file))
        } catch (e) {
            parseError(e);
            setFilesNotUploaded(state => state.concat({file, reason: e.message}));
            setFilesToBeUploaded(state => state.filter(up => up !== file))
        }
    };

    const uploadPhoto = async (file: File) => {
        const formData = new FormData();
        formData.append("image", file);
        try {
            const response = await axios.post(process.env.REACT_APP_DATA_ENDPOINT + '/brands/photos', formData, getAxiosConfig());
            const defaults = {
                src: response.data.src,
                crops: [],
                srcResolved: response.data.srcResolved,
                meta: {
                    isLegacy: false
                },
            }
            let fullScreen: Photo = {
                _id: getObjectId(),
                ...defaults
            };
            let rectangle: Photo = {
                _id: getObjectId(),
                ...defaults
            };
            let square: Photo = {
                _id: getObjectId(),
                ...defaults
            };
            props.onChange("photos", {
                fullScreen,
                rectangle,
                square
            })
            setFilesToBeUploaded(filesToBeUploaded.filter(up => up !== file))
        } catch (e) {
            parseError(e);
            setFilesNotUploaded(state => state.concat({file, reason: e.message}));
            setFilesToBeUploaded(state => state.filter(up => up !== file))
        }
    };

    const onDrop = async (acceptedFiles: Array<File>) => {
        setFilesToBeUploaded(prevState => [...prevState, ...acceptedFiles]);

        const isPdf = (file: File) => file.name.toLowerCase().endsWith(".pdf");
        const isImage = (file: File) => pictureExtensions.some(extension => file.name.toLowerCase().endsWith(extension));
        const pdfs = acceptedFiles.filter(isPdf);
        if ((props.brochures?.length || 0) + pdfs.length <= 3) {
            pdfs.forEach(uploadFile);
        } else {
            setFilesNotUploaded(state => state.concat(pdfs.map(file => ({
                file,
                reason: "Max 3 pdfy mogą być wgrane"
            }))));
            setFilesToBeUploaded(state => state.filter(up => !pdfs.includes(up)))
        }
        const images = acceptedFiles.filter(isImage);
        if (Object.keys(props.photos || {}).length || images.length > 1) {
            setFilesNotUploaded(state => state.concat(images.map(file => ({
                file,
                reason: "Tylko jedno zdjęcie może być wgrane"
            }))));
            setFilesToBeUploaded(state => state.filter(up => !images.includes(up)))
        } else {
            images.forEach(uploadPhoto);
        }
    };

    return <Box title={"Upload plików"}>
        <Dropzone multiple={true} onDrop={onDrop}>
            {({getRootProps, getInputProps, isDragActive}) => (<>
                    <div {...getRootProps()} className={"dropzone-target"}>
                        {filesToBeUploaded.length > 0 &&
                        <AbsoluteSpinner
                            label={<>
                                {'Liczba plików w kolejce oczekujących na wgranie: ' + filesToBeUploaded.length + '. Proszę czekać...'}
                            </>}/>}
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <div className={"drag-box active"}>Upuść tutaj ...</div> :
                                <div className={"drag-box"}>Przeciągnij tutaj pliki lub kliknij aby otworzyć
                                    okno wyboru</div>
                        }
                    </div>
                    {filesNotUploaded.length > 0 &&
                    <>
                        Pliki, których nie udało się wgrać:
                        <div className={"row"}>
                            {filesNotUploaded.map(notUploaded =>
                                <div className={"col-8"} key={notUploaded.file.name}>
                                    {notUploaded.file.name} - {notUploaded.reason}
                                </div>)}
                        </div>
                    </>
                    }
                </>
            )}
        </Dropzone>
    </Box>
};

const mapStateToProps = (state: AppState): StateProps => {
    if (!state.BrandData.brandData) {
        throw new Error("NO_BRAND");
    }
    return {
        brandId: state.BrandData.brandData._id,
        brochures: state.BrandData.brandData.brochures || [],
        photos: state.BrandData.brandData.photos
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    onChange: (path: string, value: any) => dispatch(brandUpdateValue(path, value)),
    addBrochure: (brochure: FileModel) => dispatch(addBrochure(brochure))
});

export default connect(mapStateToProps, mapDispatchToProps)(FilesUpload);
