import React, {Component} from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogActions, DialogContent, LinearProgress} from "@material-ui/core";
import styles from "./ChangeUser.module.scss";
import UserForm from "../UserForm/UserForm";
import {Alert} from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import {UserData} from "../../../../ActionCreators/User";

interface Props {
    doAction: (userData: UserData) => Promise<string | void>,
    className?: string
}

interface State {
    showModal: boolean,
    userData?: UserData,
    inProgress: boolean,
    errorMessage?: string
}

class ChangeUser<T extends Props = Props> extends Component<T, State> {
    protected readonly type: "ADD" | "EDIT" = "ADD";

    constructor(props: T, type: "ADD" | "EDIT", userData?: UserData) {
        super(props);
        this.type = type;
        this.state = {
            userData,
            showModal: false,
            inProgress: false
        }
    }

    protected getButton = (func: () => void): JSX.Element => {
        throw new Error("Not implemented")
    };

    protected buttonDisabled = (): boolean => false;

    protected getSaveButtonLabel = (): string => "";

    public render = (): JSX.Element =>
        <>
            {this.getButton(() => this.setState({showModal: true}))}
            {this.state.showModal &&
            <Dialog
                open={true}
            >
                <DialogContent className={styles.body}>
                    <UserForm
                        disabled={this.state.inProgress}
                        type={this.type}
                        userData={this.state.userData}
                        onChange={userData => this.setState({userData})}
                    />
                    {this.state.errorMessage && <Alert severity="error">{this.state.errorMessage}</Alert>}
                </DialogContent>
                <DialogActions className={styles.actions}>
                    {this.state.inProgress && <LinearProgress className={styles.add}/>}
                    {!this.state.inProgress && <Button
                        className={styles.add}
                        color={"primary"}
                        variant={"contained"}
                        disabled={this.buttonDisabled()}
                        onClick={async () => {
                            if (!this.state.userData) {
                                return;
                            }
                            this.setState({
                                errorMessage: undefined,
                                inProgress: true
                            });
                            const errorMessage = await this.props.doAction(this.state.userData);
                            if (errorMessage) {
                                this.setState({
                                    errorMessage,
                                    inProgress: false
                                })
                            } else {
                                this.setState({
                                    showModal: false,
                                    userData: undefined,
                                    inProgress: false
                                })
                            }
                        }}
                    >
                        {this.getSaveButtonLabel()}
                    </Button>}
                    <Button
                        disabled={this.state.inProgress}
                        color="primary"
                        onClick={() => this.setState({showModal: false, userData: undefined, errorMessage: undefined})}>
                        Odrzuć
                    </Button>
                </DialogActions>
            </Dialog>}
        </>

}

export default ChangeUser;