import styles from "./Photos.module.scss";
import {Draggable, Droppable} from "react-beautiful-dnd";
import Square from "../../ProductDetails/FilesUploaded/Square/Square";
import {getCroppedSrc} from "../../../../Utils/PhotoUtils";
import Box from "../../Common/Box/Box";
import React, {useState} from "react";
import {Photo} from "mesmetric-v2-common/models";
import {CheckboxButtonNotConnected} from "../../Common/CheckboxButton/CheckboxButton";

export const PRODUCT_PHOTOS = "product-photos";

const ProductPhotos: React.FC<{ shopPhotos: Photo[], productPhotos: Photo[] }> = (
    {
        shopPhotos,
        productPhotos
    }) => {
    const [croppedThumbnails, setCroppedThumbnails] = useState(true);
    return <Box title={"Zdjęcia prezentacyjne"} className={styles.productPhotos}>
        <div className={styles.controls}>
            <CheckboxButtonNotConnected
                label={"Przycięte miniatury"}
                value={croppedThumbnails}
                onChange={value => setCroppedThumbnails(value)}
            />
        </div>
        <Droppable droppableId={PRODUCT_PHOTOS}>
            {(provided) =>
                <div ref={provided.innerRef} className={styles.list}>
                    {productPhotos?.filter(photo => !shopPhotos.some(sp => sp._id === photo._id))?.map((photo, index) =>
                        <Draggable key={photo._id} draggableId={photo._id} index={index}>
                            {provided =>
                                <div
                                    className={styles.photoDraggableWrapper}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    <Square
                                        className={styles.photo}
                                        style={{backgroundImage: `url(${photo.src && croppedThumbnails ? getCroppedSrc(photo, {w: 132}) : photo.srcResolved})`}}>
                                    </Square>
                                </div>
                            }
                        </Draggable>
                    )}
                </div>}
        </Droppable>
    </Box>
}

export default ProductPhotos;