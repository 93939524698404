import React, {Component, CSSProperties} from "react";
import "./square.scss";

interface SquareProps {
    style?: CSSProperties,
    className?: string
}

export class Square extends Component<SquareProps> {
    public render = (): JSX.Element =>
        <div className={["square", this.props.className].filter(Boolean).join(" ")}>
            <div className={"square-content"} style={this.props.style}>
                {this.props.children}
            </div>
        </div>;
}

export default Square