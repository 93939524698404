import React, {useEffect, useState} from "react";
import {Label} from "mesmetric-v2-common/models/Label";
import {TextFieldNotConnected} from "../../../ProductView/Common/TextField/TextField";
import styles from "./TranslationInputs.module.scss";

const TranslationInputs: React.FC<{ label: Label, className?: string, disabled?: boolean, onChange: (label: Label) => void }> = (
    {
        label,
        className,
        onChange,
        disabled
    }) => {
    const [polish, setPolish] = useState(label.pl);
    const [english, setEnglish] = useState(label.en || "");

    useEffect(() => {
        setPolish(label.pl);
        setEnglish(label.en || "");
    }, [label]);

    return <div className={"row " + className}>
        <div className={"col-6"}>
            <TextFieldNotConnected
                className={styles.input}
                label={"Polski"}
                disabled={disabled}
                value={polish}
                onBlur={() => onChange({
                    pl: polish,
                    en: english
                })}
                onChange={value => setPolish(value || "")}
            />
        </div>
        <div className={"col-6"}>
            <TextFieldNotConnected
                className={styles.input}
                label={"Angielski"}
                value={english}
                disabled={disabled}
                error={!english}
                helperText={english ? "" : "Brak tłumaczenia"}
                onBlur={() => onChange({
                    pl: polish,
                    en: english
                })}
                onChange={value => setEnglish(value || "")}
            />
        </div>
    </div>
}

export default TranslationInputs;