import {
    Category,
    ProductAttribute,
    ProductAttributeGroup,
    ProductFilter,
    ProductTemplate
} from "mesmetric-v2-common/models";
import * as actions from "./actions";
import {actionTypes} from "./actions";


export const setTemplateData = (productTemplate: ProductTemplate): actions.setDataAction => ({
    type: actions.actionTypes.SetData,
    data: productTemplate
});

export const setCategories = (categories: Category[]): actions.setCategoriesAction => ({
    type: actions.actionTypes.SetCategories,
    data: categories
});


export const updateGroup = (attributeGroup: ProductAttributeGroup): actions.updateGroupAction => ({
    type: actionTypes.UpdateGroup,
    data: attributeGroup
});

export const removeGroup = (attributeGroup: ProductAttributeGroup): actions.removeGroupAction => ({
    type: actionTypes.RemoveGroup,
    data: {
        groupId: attributeGroup._id
    }
});

export const updateAttribute = (attribute: ProductAttribute.Any, path: string): actions.updateAttributeAction => ({
    type: actionTypes.UpdateAttribute,
    data: {
        path: path,
        attribute: attribute
    }
});

export const removeAttribute = (attribute: ProductAttribute.Any, attributeGroupId: string): actions.removeAttributeAction => ({
    type: actionTypes.RemoveAttribute,
    data: {
        groupId: attributeGroupId,
        attributeId: attribute._id
    }
});

export const updateFilter = (filter: ProductFilter, path: string): actions.updateFilterAction => ({
    type: actionTypes.UpdateFilter,
    data: {
        path: path,
        filter: filter
    }
});

export const removeFilter = (filter: ProductFilter): actions.removeFilterAction => ({
    type: actionTypes.RemoveFilter,
    data: {
        filterId: filter._id
    }
});