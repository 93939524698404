import {ActionTypes, AllActions} from "../ActionCreators/Designers";
import {DesignersState, initialState} from "../State/Designers";

export default function DesignersReducer(state = initialState, action: AllActions): DesignersState {
    switch (action.type) {
        case ActionTypes.setDesigners: {
            return {
                ...state,
                designers: action.designers
            };
        }
        case ActionTypes.setIsSearchingDesigners: {
            return {
                ...state,
                isSearching: action.isSearching
            }
        }
        default:
            return state;
    }
}

