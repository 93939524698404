import React, {useState} from "react";
import styles from "./Element.module.scss";
import AutocompleteSelectField from "../../Common/AutocompleteSelectField/AutocompleteSelectField";
import {multiLanguageNameMapper} from "../../../../Utils/DataUtils";
import ElementsVariants from "./ElementsVariants/ElementsVariants";
import {useDispatch, useSelector} from "react-redux";
import {addProductElement} from "../../../../ActionCreators/ProductElements";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../../../Store";
import Dictionary from "../../../../DataProviders/Dictionary";
import {ProductElement} from "mesmetric-v2-common/models";
import commonStyles from "../../../CommonComponents/CommonStyles.module.scss";

interface Props {
    className?: string,
    path: string,
    excludeElementIndex: number
}

const Element: React.FC<Props> = ({className, path, excludeElementIndex}) => {
    const excludedVariant = useSelector<AppState, ProductElement | undefined>(state => state.ProductData.productData?.shop?.elements?.[excludeElementIndex]);
    const dispatch = useDispatch<ThunkDispatch<AppState, never, never>>();
    const [productElements, setProductElements] = useState<ProductElement[]>();
    const onNewItem = async (name: string): Promise<ProductElement | undefined> => {
        return await dispatch(addProductElement(name));
    }

    const renderOption = (item: any) => {
        if (item.value === "NEW") {
            return item.label;
        }
        return <>
            {item.value.name?.pl}
            {item.value.name?.en ? "" : <span className={commonStyles.incompleteOption}>(Brak tłumaczenia)</span>}
        </>;
    }


    const noTranslations = productElements?.some(el => !el.name.en);

    return <div className={styles.variant + " " + className}>
        <AutocompleteSelectField
            path={path}
            label={<>Nazwa elementu{noTranslations ?
                <span className={commonStyles.incompleteOption}>(Brak tłumaczeń)</span> : ""}</>}
            renderOption={renderOption}
            invalid={noTranslations}
            optionsProvider={async () => {
                const elements: ProductElement[] = await Dictionary.getProductsElements();
                if (!elements) {
                    return [];
                }
                let returnedElements;
                if (excludedVariant) {
                    returnedElements = elements.filter(element => element._id !== excludedVariant._id);
                } else {
                    returnedElements = elements;
                }
                setProductElements(returnedElements);
                return returnedElements;

            }}
            optionsMapper={multiLanguageNameMapper}
            onNewItem={onNewItem}
        />
        <ElementsVariants
            path={"shop.elementsVariants"}
            elementPath={path}
            buttonDisabledByPath={path}
            className={styles.elements}
        />

    </div>;
};

export default Element;