import {ActionTypes, AllActions} from "../ActionCreators/Categories";
import {CategoriesState, initialState} from "../State/Categories";

export default function CategoriesReducer(state: CategoriesState = initialState, action: AllActions): CategoriesState {
    switch (action.type) {
        case ActionTypes.setCategories: {
            return {
                ...state,
                categories: action.categories
            };
        }
        case ActionTypes.setCategoriesTree: {
            return {
                ...state,
                tree: action.tree
            };
        }
        default:
            return state;
    }
}

