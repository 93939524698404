import {ActionTypes, AllActions} from "../ActionCreators/Brands";
import {BrandsState, initialState} from "../State/Brands";

export default function BrandsReducer(state = initialState, action: AllActions): BrandsState {
    switch (action.type) {
        case ActionTypes.setBrands: {
            return {
                ...state,
                brands: action.brands
            };
        }
        case ActionTypes.setIsSearchingBrands: {
            return {
                ...state,
                isSearching: action.isSearching
            }
        }
        default:
            return state;
    }
}

