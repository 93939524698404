import React, {Component} from "react";
import Box from "../../Common/Box/Box";
import TextField from "../../Common/TextField/TextField";
import AutocompleteSelectField from "../../Common/AutocompleteSelectField/AutocompleteSelectField";
import Dictionary from "../../../../DataProviders/Dictionary";
import GenerateField from "../../Common/GenerateField/GenerateField";
import {basicOptionsMapper, multiLanguageLabelMapper} from "../../../../Utils/DataUtils";

interface ProductFeaturesProps {
}

class ProductFeatures extends Component<ProductFeaturesProps> {
    public render = (): JSX.Element =>
        <Box title={"Cechy produktu"}>
            <TextField
                label={"Nazwa"}
                path={"name"}
            />
            <TextField
                label={"Kolejność"}
                path={"order"}
                type={"number"}
                valueConverter={value => value ? parseInt(value) : 0}
            />
            <AutocompleteSelectField
                label={"Marka"}
                path={"brand"}
                optionsProvider={Dictionary.getBrands}
                optionsMapper={basicOptionsMapper}
                disableRemove
            />
            <AutocompleteSelectField
                label={"Projektanci"}
                path={"designers"}
                optionsProvider={Dictionary.getDesigners}
                optionsMapper={basicOptionsMapper}
                multi
            />
            <AutocompleteSelectField
                label={"Style"}
                path={"styles"}
                optionsProvider={Dictionary.getProductStyles}
                optionsMapper={multiLanguageLabelMapper}
                multi
            />
            <AutocompleteSelectField
                label={"Wartości"}
                path={"values"}
                optionsProvider={Dictionary.getProductValues}
                optionsMapper={multiLanguageLabelMapper}
                multi
            />
            <GenerateField
                label={"Kod produktu"}
                path={"code"}
                pathsForGenerator={["brand.code", "primaryCategory.code", "name"]}
                generate={(values: any[]): string => {
                    const brandCode = values[0];
                    const primaryCategoryCode = values[1];
                    const productName = (values[2] || "").replace(/[\s-]/g, '_').toLowerCase();
                    return [brandCode, primaryCategoryCode, productName].filter(Boolean).join("_");
                }}
            />
            <TextField
                label={"ID ze starej bazy"}
                path={"legacyData.id"}
            />
        </Box>
}

export default ProductFeatures;