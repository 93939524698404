import lodash from 'lodash';
import update from 'immutability-helper';
export default {
    removeItem: (items, itemForRemoval, updateCallback, findClosure) => {
        const cloned = lodash.cloneDeep(items);
        const removeIndex = (typeof findClosure === "function") ? items.findIndex(findClosure) : items.indexOf(itemForRemoval);
        cloned.splice(removeIndex, 1);
        updateCallback(cloned);
    },
    moveItem: (items, from, to, updateCallback) => {
        updateCallback(
            update(items, {$splice: [[from,1], [to,0,items[from]]]})
        );
    },
}