import {Component} from "react";
import {connect} from "react-redux";
import {get} from "lodash";
import {AppState} from "../../../Store";

interface PreviewElementProps {
    path: string,
    children: (value: any) => JSX.Element | JSX.Element[] | null
}

interface StateProps {
    value: any
}

class GetValue extends Component<PreviewElementProps & StateProps> {
    public render = (): JSX.Element | JSX.Element[] | null => this.props.children(this.props.value);
}

const mapStateToProps = (state: AppState, ownProps: PreviewElementProps): StateProps => ({
    value: get(state.ProductData.productData, ownProps.path)
});

export default connect(mapStateToProps)(GetValue);