import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../../Store";
import {RouteComponentProps, withRouter} from "react-router";
import styles from "./Brands.module.scss";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import AddItem from "./AddItem/AddItem";
import {addBrand, BackendBrand, fetchBrands} from "../../ActionCreators/Brands";
import {ItemData} from "./ItemForm/ItemForm";
import Table from "../CommonComponents/Table/Table";
import {COLUMNS} from "./Columns";

interface StateProps extends RouteComponentProps {
    items: BackendBrand[],
    isSearching: boolean
}

export const EDIT_SUB_PATH = "/brands/edit";

interface DispatchProps {
    addItem: (userData: ItemData) => Promise<string | void>,
    fetchBrands: () => void
}

type Props = StateProps & DispatchProps;


class Brands extends Component<Props> {

    componentDidMount() {
        if (!this.props.items?.length) {
            this.props.fetchBrands();
        }
    }

    public render = (): JSX.Element =>
        <Table
            items={this.props.items}
            label={"Marki"}
            isSearching={this.props.isSearching}
            id={"brands"}
            rowHeight={138}
            headerComponent={<AddItem
                className={styles.add}
                otherItems={this.props.items}
                doAction={this.props.addItem}/>}
            columns={COLUMNS}
            onRowClick={item => this.props.history.push(`${EDIT_SUB_PATH}/${item._id}`)}
        />;
}

const mapStateToProps = (state: AppState, externalProps: RouteComponentProps): StateProps => ({
    ...externalProps,
    items: state.Brands.brands,
    isSearching: state.Brands.isSearching,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    addItem: (userData) => dispatch(addBrand(userData)),
    fetchBrands: () => dispatch(fetchBrands())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Brands));