import {SvgIconProps} from "@material-ui/core/SvgIcon/SvgIcon";
import {Clear, Done} from "@material-ui/icons";
import React from "react";
import {green, red} from "@material-ui/core/colors";

export const okColor = green[700];
export const badColor = red[700];

export const OKIcon = (props: SvgIconProps) => {
    return <Done style={{color: okColor}} {...props}/>
};

export const BadIcon = (props: SvgIconProps) => {
    return <Clear style={{color: badColor}} {...props}/>
};
