import React, {Component} from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Dictionary from "../../../DataProviders/Dictionary";
import {Category} from "mesmetric-v2-common/models";

interface Props {
    path: string,
    label: string,
    onChange: (id: string) => void,
    fullWidth?: boolean
}


type OptionType = {
    [key: string]: any
}

interface State {
    loaded: boolean,
    options: OptionType[],
    value: any
}

class PrimeCategory extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loaded: false,
            options: [],
            value: undefined
        };
    }

    private categoriesOptionMapper = (item: Category) => {
        return {
            labelDropdown: item.name?.pl,
            labelPill: item.path?.replace(/-/g, " "),
            selectable: item?.children?.length === 0,
            parentCategory: "inne",
            children: item.children,
            value: item
        };
    };

    private getOptions = () =>
        Dictionary.getCategories().then((result: Array<any>) => {
            const options = result.map(this.categoriesOptionMapper);

            // complete options with parent category
            options.forEach(option => {
                if (option.children?.length !== 0) {
                    const childrenIds = option.children;
                    childrenIds?.forEach(childId => {
                        const opt = options.find(option => option.value._id === childId.toString());
                        if (opt) {
                            opt.parentCategory = option.value.path
                        }
                    });
                }
            });

            this.setState({
                options: options.filter(option => option.selectable),
                loaded: true
            });

        });

    public render = () => {
        const props = {
            value: this.state.options.find(option => option.value?._id === this.state.value?._id) || {},
            onChange: (event: any, newValue: any) => {
                this.setState({
                    value: newValue?.value
                });
                this.props.onChange(newValue?.value);
            }
        };

        return <Autocomplete
            options={this.state.options.sort((a, b) => ('' + a.parentCategory).localeCompare(b.parentCategory))}
            onOpen={() => !this.state.loaded && this.getOptions()}
            getOptionLabel={option => option.labelPill || ""}
            noOptionsText={"Brak opcji"}
            groupBy={option => option.parentCategory}
            loading={!this.state.loaded}
            renderOption={option => option.labelDropdown || ""}
            fullWidth={this.props.fullWidth}
            renderInput={(params) =>
                <TextField {...params}
                           label={this.props.label}
                           variant="outlined"
                           size={"small"}
                />
            }
            {...props}
        />
    }
}

export default PrimeCategory