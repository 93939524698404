import 'react-dropzone-uploader/dist/styles.css'
import React, {useState} from 'react';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {connect} from "react-redux";

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {Route} from "react-router-dom";
import CategoryListing from "./Views/CategoryListing";
import SideMenu from "./Components/SideMenu/SideMenu";
import TemplatesListing from "./Views/TemplateListing";
import TemplateEditor from "./Views/TemplateEditor";
import {ThemeProvider} from '@material-ui/styles'
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import "./content.scss";
import PaddedPaper from "./Components/PaddedPaper/PaddedPaper";
import ProductView from "./Views/ProductView/ProductView";
import ProductControls from "./Components/ProductControls/ProductControls";
import Title from "./Components/Title/Title";
import {Redirect, Switch} from "react-router";
import Login from "./Views/Login/Login";
import {loggedIn, logout} from "./ActionCreators/User";
import AbsoluteSpinner from "./Components/UI/AbsoluteSpinner/AbsoluteSpinner";
import Notifications from "./Components/Notifications/Notifications";
import Brands from "./Views/Brands/Brands";
import Products from "./Views/Products/Products";
import Designers from "./Views/Designers/Designers";
import BrandView from "./Views/BrandView/BrandView";
import BrandControls from "./Components/BrandControls/BrandControls";
import Dictionaries from "./Views/Dictionaries/Dictionaries";
import Admin from "./Views/Admin/Admin";

const drawerWidth = 200;
const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        flexGrow: 1,
        width: 'calc(100vh - 240px)',
        padding: theme.spacing(3),
    },
}));

const mesmetricTheme = createMuiTheme({
    overrides: {
        MuiInputBase: {
            root: {
                fontSize: '0.875rem'
            }
        },
    }
});

function App(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [subtitle, setSubtitle] = useState('')

    function handleDrawerOpen() {
        setOpen(true);
    }

    function handleDrawerClose() {
        setOpen(false);
    }

    const wrapInOldMain = (component) =>
        <main className={classes.content}>
            <div className={classes.toolbar}/>
            {component}
        </main>;

    return (
        <ThemeProvider theme={mesmetricTheme}>
            <Notifications/>
            {props.loggedInChecking && <AbsoluteSpinner/>}


            {!props.loggedInChecking && (props.isLogged ? <>
                        <CssBaseline/>
                        <AppBar
                            position="fixed"
                            className={clsx(classes.appBar, {
                                [classes.appBarShift]: open,
                            })}
                        >
                            <Toolbar id={"toolbar"} className={"toolbar"}>
                                <Title
                                    openMenu={handleDrawerOpen}
                                    subTitle={subtitle}
                                    menuOpened={open}
                                />
                                <ProductControls/>
                                <BrandControls/>
                            </Toolbar>
                        </AppBar>
                        <Drawer
                            variant="permanent"
                            className={clsx(classes.drawer, {
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            })}
                            classes={{
                                paper: clsx({
                                    [classes.drawerOpen]: open,
                                    [classes.drawerClose]: !open,
                                }),
                            }}
                            open={open}
                        >
                            <div className={classes.toolbar}>
                                <IconButton onClick={handleDrawerClose}>
                                    {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                                </IconButton>
                            </div>
                            <Divider/>
                            <SideMenu open={open} isAdmin={props.isAdmin} logout={props.logout}/>
                        </Drawer>
                        <Switch>
                            <Route exact path='/' render={() => wrapInOldMain(
                                <PaddedPaper>
                                    <Typography paragraph>
                                        Wybierz sekcję z menu po lewej stronie
                                    </Typography>
                                </PaddedPaper>
                            )}>
                            </Route>
                            <Route exact path='/categories' render={() => wrapInOldMain(<CategoryListing/>)}/>
                            <Route exact path='/dictionaries' component={Dictionaries}/>
                            <Route exact path='/admin/:tab?' component={Admin}/>
                            <Route exact path='/brands' component={Brands}/>
                            <Route exact path='/brands/edit/:id' component={BrandView}/>
                            <Route exact path='/designers' component={Designers}/>
                            <Route exact path='/templates'
                                   render={(props) =>
                                       wrapInOldMain(
                                           <PaddedPaper>
                                               <TemplatesListing {...props}
                                                                 setSubtitle={setSubtitle}/>
                                           </PaddedPaper>)}/>
                            <Route exact path='/templates/edit/:id'
                                   render={props => wrapInOldMain(<TemplateEditor {...props}/>)}/>
                            <Route exact path='/products' component={Products}/>
                            <Route exact path='/products/edit/:id/:tab?' component={ProductView}/>
                            <Redirect to={"/"}/>
                        </Switch>

                    </> :
                    <>
                        <Switch>
                            <Route exact path='/login' component={Login}/>
                            <Redirect to={"/login"}/>
                        </Switch>
                    </>
            )}
        </ThemeProvider>
    );
}

const mapStateToProps = (state) => ({
    isLogged: !!state.User.user,
    loggedInChecking: state.User.loggedInChecking,
    isAdmin: !!state.User.user?.permissions?.admin
});

const mapDispatchToProps = {
    loggedIn,
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(App)
