import React, {Component} from "react";
import Box from "../../Common/Box/Box";
import Brochure2D3D from "./Brochure2D3D/Brochure2D3D";
import "./filesUploaded.scss";
import Photos from "./Photos/Photos";
import {GUIDELINES_PATH} from "../../../../ActionCreators/ProductData";
import {CheckboxButtonNotConnected} from "../../Common/CheckboxButton/CheckboxButton";
import Gallery from "../../Shop/Photos/Gallery";
import ProductPreview from "../../Shop/ProductPreview/ProductPreview";

interface FilesUploadedProps {
    productId: string
}

interface FilesUploadedState {
    croppedThumbnails: boolean,
    productPreviewOpened: boolean
}

class FilesUploaded extends Component<FilesUploadedProps, FilesUploadedState> {
    constructor(props: FilesUploadedProps) {
        super(props);

        this.state = {
            croppedThumbnails: true,
            productPreviewOpened: false
        }
    }

    public render = (): JSX.Element =>
        <>
            <Box title={"Wgrane zdjęcia i pliki"} className={"files-uploaded"}>
                <div className={"row files-control"}>
                    <CheckboxButtonNotConnected
                        label={"Przycięte miniatury"}
                        value={this.state.croppedThumbnails}
                        onChange={value => this.setState({croppedThumbnails: value})}
                    />
                    <Gallery croppedThumbnails={this.state.croppedThumbnails} path={"photos"}/>
                    <ProductPreview show/>
                </div>
                <div className={"row"}>
                    <div className={"col-6"}>
                        <Photos
                            croppedThumbnails={this.state.croppedThumbnails}
                            productId={this.props.productId}
                            path={"photos"}
                            guidelinesPath={GUIDELINES_PATH}
                        />
                    </div>
                    <div className={"col-6"}>
                        <Brochure2D3D
                            productId={this.props.productId}
                            path={"files"}
                        />
                    </div>
                </div>
            </Box></>
}

export default FilesUploaded;