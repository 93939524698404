import React, {Component} from "react";
import styles from "./ItemForm.module.scss";
import {TextFieldNotConnected} from "../../ProductView/Common/TextField/TextField";
import {CheckboxNotConnected} from "../../ProductView/Common/Checkbox/Checkbox";
import {Label} from "mesmetric-v2-common/models";

export interface ItemData {
    name?: string,
    isActive?: boolean,
    order?: number,
    description?: Label
}

interface Props {
    userData?: ItemData,
    type: "ADD" | "EDIT",
    onChange: (user: ItemData) => void,
    disabled?: boolean
}

class ItemForm extends Component<Props> {

    private onChange = (value: boolean | string | undefined | Label, field: keyof ItemData) => {
        this.props.onChange({
            ...this.props.userData || {},
            [field]: value
        })
    };

    public render = (): JSX.Element =>
        <div className={styles.userForm}>
            <TextFieldNotConnected
                autoFocus={this.props.type === "ADD"}
                label={"Podaj nazwę"}
                disabled={this.props.disabled}
                value={this.props.userData?.name}
                onChange={value => this.onChange(value, "name")}
                required
                type={"text"}
            />
            <TextFieldNotConnected
                label={"Kolejność"}
                value={this.props.userData?.order}
                disabled={this.props.disabled}
                onChange={value => this.onChange(value ? parseInt(value) : value, "order")}
                type={"number"}
            />
            <TextFieldNotConnected
                label={"Opis polski"}
                value={this.props.userData?.description?.pl}
                multiline
                disabled={this.props.disabled}
                onChange={value => this.onChange({...this.props.userData?.description, pl: value}, "description")}
            />
            <TextFieldNotConnected
                label={"Opis angielski"}
                value={this.props.userData?.description?.en}
                multiline
                disabled={this.props.disabled}
                onChange={value => this.onChange({
                    pl: this.props.userData?.description?.pl || "",
                    en: value
                }, "description")}
            />
            <CheckboxNotConnected
                value={this.props.userData?.isActive}
                label={"Aktywny"}
                disabled={this.props.disabled}
                onChange={value => this.onChange(!!value, "isActive")}
            />
        </div>
}

export default ItemForm;