import React, {useEffect, useState} from 'react'
import "./attributeDictionary.scss";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import {DictionaryItem, ProductAttribute, ProductAttributeType} from "mesmetric-v2-common/models";
import {Dictionary} from "mesmetric-v2-common/models/Dictionary";
import _ from "lodash";
import {connect} from "react-redux";
import {updateValue} from "../../../../ActionCreators/ProductData";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../../../Store";
import {Action} from "redux";
import {getObjectId} from "../../../../Common/Utility";
import axios from "axios";
import {getAxiosConfig} from "../../../../ActionCreators/User";
import {parseError} from "../../../../ActionCreators/Error";

interface ExternalProps {
    index: number
    providedAttribute: ProductAttribute.Dictionary
    path: string
    onDictionaryItemAdded: (attributeIndex: number, value: Dictionary) => void,
    markWhenIncomplete?: boolean
}

interface StateProps {
    index: number
    productAttribute: string[]
}

interface DispatchProps {
    onAttributeUpdate: (value: string[]) => void
}

interface AttributeOption {
    value: string,
    refId: string,
    selected: boolean
    inputValue?: string,
    incomplete: boolean
}

type Props = StateProps & DispatchProps & ExternalProps;

const getSelectedFilterOptionsByIds = (ids: string[], availableOptions: DictionaryItem[]): DictionaryItem[] => {
    let selectedOptions: DictionaryItem[] = [];
    ids?.forEach(id => {
        const foundItem = availableOptions.find(option => option._id === id);
        if (foundItem) {
            selectedOptions.push(foundItem);
        }
    });
    return selectedOptions;
};

const AttributeDictionary: React.FC<Props> = (props) => {

    const getOptions = (availableOptions: DictionaryItem[], selectedOptions: DictionaryItem[]): AttributeOption[] =>
        availableOptions?.map(option => {
            return {
                refId: option._id,
                value: option.label?.pl,
                incomplete: !option.label?.en,
                selected: !!selectedOptions.find(selectedOption => selectedOption?._id === option._id)
            }
        });

    const calculateOptions = () => {
        const availableOptions = props.providedAttribute?.dictionary?.items;
        if (!availableOptions || availableOptions.length < 1) {
            return {
                attributeId: props.providedAttribute._id as string,
                options: []
            }
        }
        const selectedOptions = getSelectedFilterOptionsByIds(props.productAttribute, availableOptions);
        return {
            attributeId: props.providedAttribute._id as string,
            options: getOptions(availableOptions, selectedOptions),
        };
    }

    const [state, setState] = useState(calculateOptions());

    useEffect(() => {
        setState(calculateOptions())
    }, [props])

    const onUpdateOptions = (newValues: AttributeOption[], updatedOptions: AttributeOption[]): any => {
        updatedOptions.forEach(option =>
            option.selected = !!newValues.find(
                (newValue: AttributeOption) => (!newValue.refId && newValue.inputValue && newValue.inputValue === option.value) || newValue.refId === option.refId
            ));
        setState({
            ...state,
            options: updatedOptions
        })
        props.onAttributeUpdate(updatedOptions.filter(option => option.selected).map(option => option.refId))
    };

    const onCreateOption = (newValues: AttributeOption[], value: string): void => {
        const itemId = getObjectId();
        axios.post(process.env.REACT_APP_DATA_ENDPOINT + '/dictionaries/add/', {
            dictionaryId: props.providedAttribute.dictionary._id,
            label: value,
            type: ProductAttributeType.Dictionary,
            itemId
        }, getAxiosConfig()).then((result): AttributeOption[] => {
            props.onDictionaryItemAdded(props.index, result.data);
            const updatedOptions = result.data?.items;
            const selectedOptions = getSelectedFilterOptionsByIds(props.productAttribute, updatedOptions);
            return getOptions(updatedOptions, selectedOptions);
        }).then((updatedOptions: AttributeOption[]) => onUpdateOptions(newValues, updatedOptions)).catch(parseError);
    };

    const onChange = (newValues: AttributeOption[]): void => {
        newValues.forEach(newOption => {
            if (newOption?.inputValue) {
                onCreateOption(newValues, newOption.inputValue);
                return;
            }
        });

        onUpdateOptions(newValues, [...state.options]);
    };

    const getAutocompleteClassname = (incomplete: boolean): string => {
        const classNames = ["autocomplete-dropdown"];
        incomplete && classNames.push("incomplete");
        return classNames.join(" ");
    };

    const renderOption = (option: AttributeOption): string | JSX.Element => {
        if (option.incomplete) {
            return <>{option.value}<span className={"incomplete-option"}>(Brak tłumaczenia)</span></>;
        }
        return option.value;
    };

    const getOptionLabel = (option: AttributeOption | string): string => {
        if (typeof option === 'string') {
            return option;
        }
        if (option.inputValue) {
            return option.inputValue;
        }
        return option.value;
    };

    const attributeOptions = createFilterOptions<AttributeOption>();


    const getAttributeOptions = (options: AttributeOption[], params: any): AttributeOption[] => {
        const filtered = attributeOptions(options, params) as AttributeOption[];
        if (params.inputValue !== '') {
            if (!props.providedAttribute.dictionary.items.find(item => item.label.pl === params.inputValue)) {
                filtered.push({
                    inputValue: params.inputValue,
                    value: `Dodaj "${params.inputValue}"`,
                    refId: '',
                    selected: false,
                    incomplete: false
                });
            }
        }

        return filtered;
    };

    let incomplete: boolean = !!props.markWhenIncomplete && state.options.some(option => option.incomplete);
    const getLabel = incomplete ? renderOption : (option: AttributeOption) => option.value;

    return <Autocomplete
        className={getAutocompleteClassname(incomplete)}
        noOptionsText={"Brak opcji"}
        options={state.options}
        value={state.options.filter(option => option.selected)}
        getOptionLabel={(option: AttributeOption | string) => getOptionLabel(option)}
        renderOption={getLabel}
        onChange={(event: any, newValues: any) => onChange(newValues)}
        filterOptions={(options, params) => getAttributeOptions(options, params)}
        selectOnFocus
        multiple
        renderInput={(params) =>
            <TextField {...params}
                       label={props.providedAttribute.label.pl + (incomplete ? " (Brak tłumaczeń)" : "")}
                       variant="outlined"
                       error={incomplete}
                       size={"medium"}/>
        }
    />
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    index: externalProps.index,
    productAttribute: _.get(state.ProductData.productData, externalProps.path)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onAttributeUpdate: (value: string[]) => dispatch(updateValue(externalProps.path, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttributeDictionary);