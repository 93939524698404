import React, {useState} from "react";
import CheckboxButton from "../Common/CheckboxButton/CheckboxButton";
import Box from "../Common/Box/Box";
import Element from "./Element/Element";
import Combinations from "./Combinations/Combinations";
import {useSelector} from "react-redux";
import {AppState} from "../../../Store";
import Photos from "./Photos/Photos";
import UploadPhotos from "./UploadPhotos/UploadPhotos";
import Details from "./Details/Details";
import {Tab, Tabs} from "@material-ui/core";
import styles from "./Shop.module.scss";
import Attributes from "./Attributes/Attributes";
import ProductPreview from "./ProductPreview/ProductPreview";

interface Props {
    visible?: boolean
}

const TABS: { key: number, name: string }[] = [
    {
        key: 0,
        name: "GŁÓWNE"
    },
    {
        key: 1,
        name: "ATRYBUTY"
    }
];

const Shop: React.FC<Props> = ({visible}) => {
    const {displayCombinations} = useSelector<AppState, { displayCombinations: boolean }>(state => ({
        displayCombinations: !!(state.ProductData.productData?.shop?.elementsVariants || []).length
    }));

    const [tab, setTab] = useState(0);

    return <div hidden={!visible}>
        <div className={"row"}>
            <div className={"col-12"}>
                <div className={styles.controls}>
                    <CheckboxButton
                        className={styles.forSale}
                        path={"shop.forSale"}
                        label={"Do kupienia"}
                    />
                    <ProductPreview/>
                    <Tabs
                        className={styles.tabs}
                        value={tab}
                        onChange={(_, tab) => setTab(tab)}
                    >
                        {TABS.map(tab => <Tab key={tab.key} label={tab.name}/>)}
                    </Tabs>
                </div>
            </div>
        </div>

        {tab === 0 &&
        <div className={"row"}>
            <div className={"col-7"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <Details/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-6"}>
                        <Box title={"Element 1"}>
                            <Element
                                path={"shop.elements[0]"}
                                excludeElementIndex={1}
                            />
                        </Box>
                    </div>
                    <div className={"col-6"}>
                        <Box title={"Element 2"}>
                            <Element
                                path={"shop.elements[1]"}
                                excludeElementIndex={0}
                            />
                        </Box>
                    </div>
                </div>
                {displayCombinations &&
                <div className={"row"}>
                    <div className={"col-12"}>
                        <Box title={"Kombinacje"}>
                            <Combinations/>
                        </Box>
                    </div>
                </div>}
            </div>
            <div className={"col-5"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <Box title={"Upload zdjęć"}>
                            <UploadPhotos/>
                        </Box>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <Photos/>
                    </div>
                </div>
            </div>
        </div>}
        {tab === 1 &&
        <div className={"row"}>
            <div className={"col-12"}>
                <Attributes/>
            </div>
        </div>}
    </div>;
};

export default Shop;