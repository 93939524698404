import React, {Component, CSSProperties} from "react";
import ReactCrop, {Crop} from "react-image-crop";
import {PhotoPaddingDetails} from "../PhotoCrop";
import {debounce} from "@material-ui/core";

interface CropperProps {
    loaded: () => void,
    loading: boolean,
    aspect: number,
    cropDetails: PhotoCropDetails,
    initialPadding: PhotoPaddingDetails,
    actualPadding: PhotoPaddingDetails,
    photoSrc: string,
    parentRef: React.RefObject<HTMLDivElement>,
    onChange: (cropDetails: PhotoCropDetails) => void
}

export interface PhotoCropDetails {
    x: number
    y: number
    w: number
    h: number
}

interface CropperState {
    loading: boolean,
    photoCropDetails: Crop & {
        x: number,
        y: number,
        width: number,
        height: number
    },
    aspect: number
}

const emptyPhotoDetails = {
    y: 0,
    x: 0,
    width: 0,
    height: 0
};

class Cropper extends Component<CropperProps, CropperState> {
    private photo: HTMLImageElement | undefined;
    private readonly initialPadding: PhotoPaddingDetails;

    constructor(props: CropperProps) {
        super(props);
        this.initialPadding = this.props.initialPadding;
        this.state = {
            loading: this.props.loading,
            photoCropDetails: emptyPhotoDetails,
            aspect: this.props.aspect
        }
    }


    public componentDidMount(): void {
        window.addEventListener("resize", this.debounce);
    }

    public componentWillUnmount(): void {
        window.removeEventListener("resize", this.debounce);
    }

    private resizeListener = () => {
        const rate = this.buildCropStyle().rate;
        this.setState({
            photoCropDetails: {
                width: Math.floor(this.props.cropDetails.w * rate),
                height: Math.floor(this.props.cropDetails.h * rate),
                y: Math.floor(this.props.cropDetails.y * rate),
                x: Math.floor(this.props.cropDetails.x * rate),
            },
        })
    };

    private debounce = debounce(this.resizeListener, 100);


    private buildCropStyle = (): { css: CSSProperties, rate: number } => {
        const cropWrapperWidth = this.props.parentRef.current?.clientWidth || 0;
        const cropWrapperHeight = this.props.parentRef.current?.clientHeight || 0;
        const cropWrapperRatio = cropWrapperWidth / cropWrapperHeight;
        const photoWidth = this.photo?.naturalWidth || 0;
        const photoHeight = this.photo?.naturalHeight || 0;
        const photoRatio = photoWidth / photoHeight;
        if (cropWrapperRatio > photoRatio) {
            return {
                css: {
                    height: '100%',
                    width: String(100 / (cropWrapperWidth / (cropWrapperHeight * photoRatio))) + '%'
                },
                rate: cropWrapperHeight / photoHeight
            };
        } else {
            return {
                css: {
                    height: String(100 / (cropWrapperHeight / (cropWrapperWidth / photoRatio))) + '%',
                    width: '100%'
                },
                rate: cropWrapperWidth / photoWidth
            };
        }
    };

    private onCropChange = (crop: Crop): void => {
        this.setState({
            photoCropDetails:
                {
                    y: (crop.y || 0),
                    x: (crop.x || 0),
                    width: (crop.width || 0),
                    height: (crop.height || 0)
                }
        })
    };

    private getCrop = (): Crop => ({
        x: this.state.photoCropDetails.x,
        y: this.state.photoCropDetails.y,
        height: this.state.photoCropDetails.height,
        width: this.state.photoCropDetails.width,
        aspect: this.state.aspect
    });

    public render = () => {
        let cropStyle: { css: CSSProperties, rate: number } | undefined;
        if (!this.state.loading) {
            cropStyle = this.buildCropStyle();
        }
        return <ReactCrop
            src={this.props.photoSrc}
            crop={this.getCrop()}
            style={this.photo && cropStyle ? cropStyle.css : undefined}
            imageStyle={{
                height: '100%',
                width: '100%',
                objectFit: 'contain'
            }}
            onImageLoaded={target => {
                target.addEventListener('mousedown', (event) => event.button < 2);
                target.addEventListener('click', (event) => event.button < 2);
                if (this.state.loading) {
                    this.photo = target;
                    const rate = this.buildCropStyle().rate;
                    this.setState({
                        photoCropDetails: {
                            width: Math.floor(this.props.cropDetails.w * rate),
                            height: Math.floor(this.props.cropDetails.h * rate),
                            y: Math.floor(this.props.cropDetails.y * rate),
                            x: Math.floor(this.props.cropDetails.x * rate),
                        },
                        loading: false
                    }, this.props.loaded)
                }
            }}
            onComplete={() => {
                if (this.state.loading) {
                    return;
                }
                const rate = this.buildCropStyle().rate;
                this.props.onChange({
                    x: Math.floor(this.state.photoCropDetails.x / rate),
                    y: Math.floor(this.state.photoCropDetails.y / rate),
                    h: Math.floor(this.state.photoCropDetails.height / rate),
                    w: Math.floor(this.state.photoCropDetails.width / rate)
                })
            }
            }
            onChange={crop => cropStyle && this.onCropChange(crop)}
        />
    }
}

export default Cropper;