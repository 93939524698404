import {Reducer} from "react";
import {ProductTemplate} from "mesmetric-v2-common/models";
import {actionTypes, allActions} from "./actions";
import update from "immutability-helper"
import {set} from "lodash";

// @ts-ignore
const templateDataReducer: Reducer<ProductTemplate, allActions> = (prevState, action) => {
    function getGroupIndexById(_id: string) {
        return getItemIndexById(prevState.attributeGroups, _id);
    }

    function getItemIndexById(items: { _id: string }[], _id: string): number {
        return items.findIndex(item => {
            return item._id === _id;
        });
    }

    switch (action.type) {
        case actionTypes.SetData: {
            return update(prevState, {$set: action.data});
        }
        case actionTypes.SetCategories: {
            return update(prevState, {
                categories: {
                    $set: action.data
                }
            })
        }
        case actionTypes.UpdateGroup: {
            const groupIndex = getGroupIndexById(action.data._id);
            return update(prevState, {
                attributeGroups: {
                    [groupIndex]: {
                        $set: action.data
                    }
                }
            });
        }
        case actionTypes.AddGroup: {
            return update(prevState, {
                attributeGroups: {
                    $push: [action.data]
                }
            });
        }
        case actionTypes.RemoveGroup: {
            return update(prevState, {
                attributeGroups: {
                    $splice: [[getGroupIndexById(action.data.groupId), 1]]
                }
            });
        }
        case actionTypes.UpdateAttribute: {
            return update(prevState, set({}, action.data.path, {
                $set: action.data.attribute
            }));
        }
        case actionTypes.AddAttribute: {
            const groupIndex = getGroupIndexById(action.data.groupId);
            return update(prevState, {
                attributeGroups: {
                    [groupIndex]: {
                        attributes: {
                            $push: [action.data.attribute]
                        }
                    }
                }
            });
        }
        case actionTypes.RemoveAttribute: {
            const groupIndex = getGroupIndexById(action.data.groupId);
            const attributeGroup = prevState.attributeGroups[groupIndex];
            const attributeIndex = getItemIndexById(attributeGroup.attributes, action.data.attributeId);
            return update(prevState, {
                attributeGroups: {
                    [groupIndex]: {
                        attributes: {
                            $splice: [[attributeIndex, 1]]
                        }
                    }
                }
            });
        }
        case actionTypes.UpdateFilterGroup: {
            return update(prevState, {
                filterGroup: {
                    $set: action.data
                }
            });
        }
        case actionTypes.UpdateFilter: {
            return update(prevState, set({}, action.data.path, {
                $set: action.data.filter
            }));
        }

        case actionTypes.RemoveFilter: {
            const filterGroup = prevState.filterGroup;
            if (!filterGroup) {
                return
            }
            const filterIndex = getItemIndexById(filterGroup.filters, action.data.filterId);
            return update(prevState, {
                filterGroup: {
                    filters: {
                        $splice: [[filterIndex, 1]]
                    }
                }
            });
        }
        case actionTypes.AddFilter: {
            return {
                ...prevState,
                filterGroup: {
                    ...prevState.filterGroup,
                    filters: prevState.filterGroup?.filters ? [...prevState.filterGroup.filters, action.data] : [action.data]
                }
            };
        }

        default: {
            return prevState;
        }
    }
}

export default templateDataReducer;