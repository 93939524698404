import React, {Component} from "react";
import Box from "../../Common/Box/Box";
import {AppState} from "../../../../Store";
import {updateValue} from "../../../../ActionCreators/ProductData";
import {connect} from "react-redux";
import _ from "lodash";
import {Files, Photo} from "mesmetric-v2-common/models";
import "./files-upload.scss";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import PhotosAndFilesDropzone from "./PhotosAndFilesDropzone/PhotosAndFilesDropzone";

interface ExternalProps {
    productId: string,
    filesPath: string,
    photosPath: string
}

interface StateProps {
    productId: string,
    photos: Photo[],
    files: Files
}

interface DispatchProps {
    onPhotoAdded: (value: Photo[]) => void,
    onFileAdded: (value: Files) => void
}

type FilesUploadProps = StateProps & DispatchProps;

class FilesUpload extends Component<FilesUploadProps> {
    static defaultProps = {
        files: {},
        photos: []
    };

    private onFileAdded = (file: Files) => {
        if (file.brochure) {
            const files = {
                ...this.props.files
            };
            if (!files.brochures) {
                files.brochures = [];
            }
            if (files.brochure) {
                files.brochures = [files.brochure, ...files.brochures];
                files.brochure = undefined;
            }
            files.brochures = [...files.brochures, file.brochure];
            this.props.onFileAdded(files);
        } else {
            this.props.onFileAdded({...this.props.files, ...file})
        }
    }

    public render = (): JSX.Element =>
        <Box
            className={"files-upload"}
            title={"Upload plików"}>
            <PhotosAndFilesDropzone
                showTitle={false}
                onPictureAdded={(photo) => this.props.onPhotoAdded([...this.props.photos, photo])}
                onFileAdded={this.onFileAdded}
                productId={this.props.productId}
            />
        </Box>
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    productId: externalProps.productId,
    files: _.get(state.ProductData.productData, externalProps.filesPath),
    photos: _.get(state.ProductData.productData, externalProps.photosPath),

});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onPhotoAdded: (value: Photo[]) => dispatch(updateValue(externalProps.photosPath, value)),
    onFileAdded: (value: Files) => dispatch(updateValue(externalProps.filesPath, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(FilesUpload);