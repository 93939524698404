import styled from "styled-components";

export const Content = styled.div`
  padding: 24px 24px;
`

export const ContentItem = styled.div`
  display: flex;

  > *:first-child {
    flex-grow: 1;
  }

  button {
    margin-left: 8px;
  }

`