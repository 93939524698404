import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../../../Store";
import {ProductElementVariant, ProductElementVariantsCombination} from "mesmetric-v2-common/models";
import styles from "./Combinations.module.scss";
import {TextFieldNotConnected} from "../../Common/TextField/TextField";
import {updateValue} from "../../../../ActionCreators/ProductData";
import {getCroppedSrc} from "../../../../Utils/PhotoUtils";
import {InputAdornment} from "@material-ui/core";
import commonStyles from "../../../CommonComponents/CommonStyles.module.scss";

const Combination: React.FC<{ elementsVariants: ProductElementVariant[], price: number, savePrice: (price: number) => void }> = (
    {
        elementsVariants,
        price: savedPrice,
        savePrice
    }) => {
    const [price, setPrice] = useState<number | undefined>(savedPrice || 0);
    const currencyCode = useSelector<AppState, string | undefined>(state => state.ProductData.productData?.price?.currency?.code)

    return <div className={styles.combination}>
        {elementsVariants.map((el, index) =>
            <div key={index} className={styles.combinationElement}>
                {el.image && <img alt={el.name.pl} src={getCroppedSrc(el.image, {w: 100})}/>}
                <div>{el.name.pl}{el.name.en ? "" :
                    <span className={commonStyles.incompleteOption}>(Brak tłumaczenia)</span>}</div>
            </div>)}
        <div className={styles.combinationElement}>
            <TextFieldNotConnected
                type={"number"}
                value={price?.toString()}
                onChange={value => setPrice(value ? parseFloat(value) : undefined)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <>{currencyCode?.toUpperCase()}</>
                        </InputAdornment>
                    )
                }}
                onBlur={() => {
                    setPrice(price || 0)
                    savePrice(price || 0);
                }}
            />
        </div>
    </div>
};

const Combinations: React.FC = () => {
    const combinations = useSelector<AppState, ProductElementVariantsCombination[]>(state => state.ProductData.productData?.shop?.combinations || []);
    const elementsVariants = useSelector<AppState, ProductElementVariant[]>(state => state.ProductData.productData?.shop?.elementsVariants || [])
    const dispatch = useDispatch<ThunkDispatch<AppState, never, never>>();

    return <div className={styles.combinations}>
        <div className={styles.combination}>
            {combinations[0].variantsIds.map((el, index) => {
                    const foundElement = elementsVariants.find(fEl => fEl._id === el);
                    return <div
                        className={styles.combinationElement} key={index}>{foundElement?.element.name.pl}</div>
                }
            )}

            <div className={styles.combinationElement}>Cena</div>
        </div>

        {combinations.map((combination) =>
            <Combination
                elementsVariants={elementsVariants.filter(el => combination.variantsIds.includes(el._id as string))}
                key={combination.variantsIds.join()}
                savePrice={price => {
                    combination.price = price;
                    dispatch(updateValue("shop.combinations", combinations))
                }}
                price={combination.price}/>)}
    </div>;
};

export default Combinations;