import {ActionTypes, AllActions} from "../ActionCreators/ProductElements";
import {initialState, ProductElementsState} from "../State/ProductElements";

export default function ProductElementsReducer(state = initialState, action: AllActions): ProductElementsState {
    switch (action.type) {
        case ActionTypes.setProductElements: {
            return {
                ...state,
                elements: action.elements
            };
        }
        default:
            return state;
    }
}

