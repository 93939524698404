import React, {Component} from 'react'
import {Dictionary, ProductAttribute, ProductAttributeGroup, ProductAttributeVariant} from "mesmetric-v2-common/models";
import _ from "lodash";
import {connect} from "react-redux";
import {AppState} from "../../../../../Store";
import Variant from "./Variant/Variant";
import AttributeGroupCard from "../Card/AttributeGroupCard";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {updateValue} from "../../../../../ActionCreators/ProductData";
import {getObjectId} from "../../../../../Common/Utility";

interface ExternalProps {
    path: string
    attributesProvided: ProductAttribute.Any[]
    onDictionaryUpdated: (attributeId: number, value: Dictionary) => void
    attributeGroupProvided: ProductAttributeGroup
}

interface StateProps {
    path: string
    attributesProvided: ProductAttribute.Any[]
    variants: {
        [variantId: string]: ProductAttributeVariant
    }
    onDictionaryUpdated: (attributeId: number, value: Dictionary) => void
    attributeGroupProvided: ProductAttributeGroup
}

interface DispatchProps {
    onVariantsUpdate: (variants: any) => void
}

interface State {
    newlyAddedVariantId: string
}

class Variants extends Component<StateProps & DispatchProps, State> {
    static defaultProps = {
        variants: {},
        name: ""
    };

    constructor(props: StateProps & DispatchProps) {
        super(props);
        this.state = {
            newlyAddedVariantId: ""
        }
    }

    private onVariantAdd = (variantId: string, value: ProductAttributeVariant): void => {
        let updatedVariants = {...this.props.variants};
        updatedVariants = {
            ...updatedVariants,
            [variantId]: value
        };
        this.setState({
            newlyAddedVariantId: variantId
        });
        this.props.onVariantsUpdate(updatedVariants)
    };

    private onVariantRemove = (variantId: string): any => {
        let updatedVariants: { [p: string]: {} } = {...this.props.variants};
        updatedVariants = {
            ...updatedVariants,
            [variantId]: {}
        };
        this.props.onVariantsUpdate(updatedVariants)
    };

    private onVariantClone = (variantId: string): any => {
        const variantToClone = this.props.variants[variantId];
        let updatedVariants: { [p: string]: {} } = {...this.props.variants};
        const newVariantId = getObjectId();
        updatedVariants = {
            ...updatedVariants,
            [newVariantId]: _.cloneDeep(variantToClone)
        };
        this.setState({
            newlyAddedVariantId: newVariantId
        });
        this.props.onVariantsUpdate(updatedVariants)
    };

    private getVariantsAsArray = (variants: { [variantId: string]: ProductAttributeVariant }): string[] => {
        return Object.keys(variants).filter(key => Object.keys(variants[key]).length !== 0).reduce((arr: string[], element: string) => {
            if (element === this.state.newlyAddedVariantId) {
                return [element, ...arr];
            }
            return [...arr, element];
        }, []);
    };

    render = (): JSX.Element => {
        return <AttributeGroupCard
            title={this.props.attributeGroupProvided?.label?.pl}
            addButtonHandler={this.props.attributeGroupProvided?.withVariants ? this.onVariantAdd : undefined}
            groupId={this.props.attributeGroupProvided?._id}
            isEmptyContent={Object.keys(this.props.variants).filter(key => Object.keys(this.props.variants[key]).length !== 0).length < 1}>
            {this.getVariantsAsArray(this.props.variants).map(variantId => {
                    return <Variant
                        key={variantId}
                        attributesProvided={this.props.attributesProvided}
                        path={`${this.props.path}.${variantId}`}
                        variantId={variantId}
                        onVariantRemove={() => this.onVariantRemove(variantId)}
                        onVariantClone={() => this.onVariantClone(variantId)}
                        onDictionaryUpdated={this.props.onDictionaryUpdated}
                        isNewlyAdded={(this.state.newlyAddedVariantId === variantId)}
                    />
                }
            )}
        </AttributeGroupCard>
    }
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => {
    return ({
        variants: _.get(state.ProductData.productData, externalProps.path),
        attributesProvided: externalProps.attributesProvided,
        path: externalProps.path,
        onDictionaryUpdated: externalProps.onDictionaryUpdated,
        attributeGroupProvided: externalProps.attributeGroupProvided
    });
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onVariantsUpdate: (value: { [variantId: string]: ProductAttributeVariant }) => dispatch(updateValue(`${externalProps.path}`, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Variants);