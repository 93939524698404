import {ProductElement} from "mesmetric-v2-common/models";

export interface ProductElementsState {
    isSearching: boolean,
    elements: ProductElement[]
}

export const initialState: ProductElementsState = {
    isSearching: false,
    elements: []
};