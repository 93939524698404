import {Crop, Photo} from "mesmetric-v2-common/models";

type CropPostTransform = {
    w?: number,
    h?: number
}

export function getCroppedSrc(photo: Photo, postTransformParam?: CropPostTransform): string {
    if (!photo.src || !photo.srcResolved) {
        return '';
    }
    const paddingParameterMap = {
        'padLeft': 'pl',
        'padRight': 'pr',
        'padTop': 'pt',
        'padBottom': 'pb',
    };

    if (!Array.isArray(photo.crops)) {
        return photo.srcResolved;
    }

    const crops = photo.crops.filter(cr => Object.keys(cr).length);

    let crop = crops.find(c => c.active);
    if (!crop && crops.length > 1) {
        throw new Error("NOOOOO");
    }
    if (!crop) {
        crop = photo.crops[0];
    }
    const post = postTransformParam || {w: 100};
    const queryTokens: string[] = [];
    if (crop) {
        (['x', 'y', 'w', 'h'] as Array<keyof Crop>).forEach(prop => {
            queryTokens.push(`c${prop}=${Math.floor(Number(crop?.[prop]))}`);
        });
        (['padLeft', 'padRight', 'padTop', 'padBottom'] as Array<keyof Pick<Crop, 'padLeft' | 'padRight' | 'padTop' | 'padBottom'>>).forEach(prop => {
            if (crop?.[prop] !== undefined) {
                queryTokens.push(`${paddingParameterMap[prop]}=${crop[prop]}`)
            }
        });
    }
    postTransform(post).forEach(item => queryTokens.push(item));
    queryTokens.push('t=lanczos');
    return photo.srcResolved + '?' + queryTokens.join('&');
}

export const postTransform = (postTransform: CropPostTransform): string[] => {
    const transform: string[] = [];
    (['w', 'h'] as Array<keyof CropPostTransform>).forEach(key => {
        if (postTransform[key] === undefined) {
            return;
        }
        transform.push(`${key}=${postTransform[key]}`);
    });
    return transform;
};