import React from "react";
import {Grid, Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface LegacyDataProps {
    materials?: string,
    colors?: string,
    sizes?: string,
}

enum Labels {
    materials = "Materiały",
    colors = "Kolory",
    sizes = "Wymiary"
}

const useStyles = makeStyles({
    legacyDataDescriptionDiv: {}
})

const LegacyData: React.FC<LegacyDataProps> = (props) => {
    const classes = useStyles();
    return <Grid container spacing={2}>
        {(['sizes', 'materials', 'colors'] as Array<keyof LegacyDataProps>).map((propName, index) => {
            let propValue = props[propName];
            if (propValue !== undefined) {
                propValue = propValue.replace(/<br\/>/g, "\n");
            }
            return <Grid key={index} item
                         xs={4}><Typography>{`${Labels[propName]} (stara baza)`.toLocaleUpperCase()}</Typography>
                {propValue !== undefined ? propValue.split("\n").map((v, index) => v ?
                    <div key={index} className={classes.legacyDataDescriptionDiv}>{v}</div> : <br/>) : '-'}
            </Grid>
        })}
    </Grid>
}
export default LegacyData;