import React from 'react'
import "./nameFIelds.scss"
import {TextField} from "@material-ui/core";
import {Label} from "mesmetric-v2-common/models";
import _ from "lodash";
import {connect} from "react-redux";
import {AppState} from "../../../../../../../Store";
import {updateValue} from "../../../../../../../ActionCreators/ProductData";
import {Action} from "redux";
import {ThunkDispatch} from "redux-thunk";

interface ExternalProps {
    className?: string
    path: string
    onLabelChange: (value: Label) => void
}

interface StateProps {
    value: Label
}

interface DispatchProps {
    onLabelUpdate: (value: Label) => void
}

const NameFields: React.FC<StateProps & DispatchProps & ExternalProps> = ({value = {} as Label, ...props}) => {
    return <div className={props.className}>
        <TextField
            className={"name-field top"}
            id="standard-basic"
            label="Nazwa"
            size={"medium"}
            value={value.pl || ""}
            onChange={(event) =>
                props.onLabelChange(
                    {
                        pl: event.target.value ? event.target.value : "", en: value.en
                    })}/>
        <TextField
            className={"name-field"}
            id="standard-basic"
            label="Name"
            size={"medium"}
            value={value.en || ""}
            onChange={(event) =>
                props.onLabelChange(
                    {
                        pl: value.pl, en: event.target.value ? event.target.value : ""
                    })}/>
    </div>
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => {
    return ({
        value: _.get(state.ProductData.productData, externalProps.path),
    });
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onLabelUpdate: (value: Label) => dispatch(updateValue(externalProps.path, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NameFields);