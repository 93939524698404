import {Label} from "mesmetric-v2-common/models";

import React, {Component} from "react";
import {TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {TextFieldProps} from "@material-ui/core/TextField";

import {languageLabels, languages} from "mesmetric-v2-common";

type onLabelChangeCallback = (updatedLabel: Label) => void

export interface LabelInputProps {
    value: Label
    onLabelChange: onLabelChangeCallback,
    label?: string,
    textFieldProps?: TextFieldProps,
    emitChangeOnBlur?: boolean
}

interface MultilanguageTextFieldState {
    value: Label
}

class MultilanguageTextField extends Component<LabelInputProps, MultilanguageTextFieldState> {
    constructor(props: LabelInputProps) {
        super(props);
        const {value} = props;
        this.state = {
            value
        }
    }

    public componentDidUpdate(prevProps: Readonly<LabelInputProps>): void {
        if (prevProps.value?.en !== this.props.value?.en || prevProps.value?.pl !== this.props.value?.pl) {
            const {value} = this.props;
            this.setState({value});
        }
    }

    public render = (): JSX.Element =>
        <div>
            {this.props.label ? <Typography variant={"subtitle2"}>{this.props.label}</Typography> : false}
            {
                (Object.keys(languageLabels) as Array<languages>).map(language =>
                    <TextField
                        key={`label-input-${language}`}
                        value={this.state.value[language] || ""}
                        onChange={event => {
                            this.setState({
                                value: {
                                    ...this.state.value,
                                    [language]: event.target.value
                                }
                            }, () => this.props.emitChangeOnBlur !== true && this.props.onLabelChange({
                                ...this.state.value
                            }));
                        }}
                        onBlur={this.props.emitChangeOnBlur ? event => this.props.onLabelChange({
                            ...this.state.value
                        }) : undefined}
                        label={languageLabels[language]}
                        margin="dense"
                        {...this.props.textFieldProps}
                    />
                )}
        </div>;
}

export default MultilanguageTextField