import axios, {AxiosRequestConfig} from "axios";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {AppState} from "../Store";
import {User} from "../State/User";
import {AddNotificationAction} from "./Notifications";
import {parseError} from "./Error";
import {LocalStorageKey} from "../Utils/StorageUtils";
import {searchProducts} from "./Products";

export enum ActionTypes {
    setUser = "setUser",
    notLoggedIn = "notLoggedIn"
}

export interface UserData {
    login?: string,
    password?: string,
    admin?: boolean
}

export interface SetUserAction {
    type: ActionTypes.setUser
    user: User
}

export interface NotLoggedInAction {
    type: ActionTypes.notLoggedIn
}

export interface BackendUser {
    _id: string,
    login: string,
    passwordChangedAt: string,
    created: string,
    permissions?: {
        admin?: boolean
    }
}

export const MESSAGES_FROM_SERVER: { [key: string]: string } = {
    BAD_USERNAME_OR_PASSWORD: "Błędna nazwa użytkownika lub hasło",
    FORBIDDEN: "Brak uprawnień",
    UNAUTHORIZED: "Nieautoryzowany dostęp",
    USER_ALREADY_CREATED: "Użytkownik już istnieje",
    USER_NOT_FOUND: "Brak takiego użytkownika",
    LAST_ADMIN: "Ostatni administrator"
};

const setUser = (user: User): SetUserAction => ({
        type: ActionTypes.setUser,
        user
    }
);

export const notLoggedIn = (): NotLoggedInAction => {
    localStorage.removeItem("token");
    return {
        type: ActionTypes.notLoggedIn,
    }
};

export const getAxiosConfig = (data?: any): AxiosRequestConfig => ({
    headers: {
        Authorization: localStorage.getItem(LocalStorageKey.TOKEN)
    },
    data
});

export const logIn = (login: string, password: string): ThunkAction<Promise<string | void>, AppState, {}, never> =>
    async (dispatch, getState) => {
        try {
            const response = (await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/logIn`, {
                login,
                password
            })).data;
            try {
                localStorage.setItem(LocalStorageKey.TOKEN, response.token);
            } catch (e) {
                parseError(new Error("Nie udało się zachować sesji logowania."));
            }
            await afterLogIn(response.user, dispatch);
        } catch (e) {
            if (e.response?.status === 401) {
                return MESSAGES_FROM_SERVER[e.response.data.key];
            }
            return e.message;
        }
    };

const afterLogIn = async (user: User, dispatch: ThunkDispatch<AppState, {}, SetUserAction>) => {
    dispatch(searchProducts());
    dispatch(setUser(user));
};

export const loggedIn = (): ThunkAction<void, AppState, {}, NotLoggedInAction | AddNotificationAction> =>
    async (dispatch) => {
        if (!localStorage.getItem(LocalStorageKey.TOKEN)) {
            dispatch(notLoggedIn());
            return;
        }
        try {
            const data = (await axios.get(`${process.env.REACT_APP_DATA_ENDPOINT}/logIn/loggedIn`, getAxiosConfig())).data;
            await afterLogIn(data.user, dispatch);
        } catch (e) {
            parseError(e);
        }
    };

export const logout = (): ThunkAction<void, AppState, {}, SetUserAction | NotLoggedInAction | AddNotificationAction> =>
    async (dispatch) => {
        dispatch(notLoggedIn());
    };


export type AllActions = SetUserAction | NotLoggedInAction;