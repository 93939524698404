import React from "react";
import Box from "../../Common/Box/Box";
import TextField from "../../Common/TextField/TextField";
import AutocompleteSelectField from "../../Common/AutocompleteSelectField/AutocompleteSelectField";
import Dictionary from "../../../../DataProviders/Dictionary";
import {useSelector} from "react-redux";
import {AppState} from "../../../../Store";
import {InputAdornment} from "@material-ui/core";

const Pricing: React.FC = () => {
    const currencyCode = useSelector<AppState, string | undefined>(state => state.ProductData.productData?.price?.currency?.code)

    return <Box title={"Cenówka"}>
        <AutocompleteSelectField
            label={"Waluta"}
            path={"price.currency"}
            optionsProvider={Dictionary.getCurrencies}
            optionsMapper={((item: any) => ({
                label: `${item.code} (${item.rate})`,
                value: item
            }))}
        />
        <TextField
            label={"Cena"}
            path={"price.value"}
            type={"number"}
            InputProps={(() => {
                if (currencyCode) {
                    return {
                        endAdornment: (
                            <InputAdornment position="end">
                                <>{currencyCode?.toUpperCase()}</>
                            </InputAdornment>
                        )
                    }
                }
                return undefined
            })()}
        />
        <AutocompleteSelectField
            label={"Typ ceny"}
            path={"price.type"}
            optionsProvider={Dictionary.getPriceTypes}
            optionsMapper={((item: any) => ({
                label: `${item.name} (${item.multiplier})`,
                value: item
            }))}
            disableRemove
        />
    </Box>
}

export default Pricing;