import {Tabs as MaterialTabs} from "@material-ui/core";
import styled from "styled-components";

const headerHeight = 64;

export const Main = styled.div`
  padding-top: ${headerHeight}px;
  position: relative;
  width: 100%;
`;

export const Content = styled.div`
  margin-top: ${headerHeight - 16}px;
  overflow: auto;
  height: calc(100vh - ${headerHeight + 48}px);
`;

export const Tabs = styled(MaterialTabs)`
  padding-left: 24px;
  padding-right: 24px;
  position: absolute;
  width: 100%;
  z-index: 1;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  .MuiTab-root {
    min-width: auto;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 0;
  }

  .MuiTabs-indicator {
    background-color: #3f51b5;
    height: 3px;
  }
`