import {addNotification} from "./Notifications";
import {MESSAGES_FROM_SERVER as UserErrors, notLoggedIn} from "./User";
import {MESSAGES_FROM_SERVER as BrandErrors} from "./Brands";

export const parseError = (e: any) => {
    const {store} = require("../Store");
    if (e.response?.status === 401) {
        store.dispatch(notLoggedIn());
    } else {
        e.type = "error";
        if (e.response?.data?.key) {
            e.message = {...UserErrors, ...BrandErrors}[e.response.data.key];
        }
        store.dispatch(addNotification(e))
    }
};