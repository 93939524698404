import {BackendDesigner} from "../ActionCreators/Designers";

export interface DesignersState {
    isSearching: boolean,
    designers: BackendDesigner[]
}

export const initialState: DesignersState = {
    isSearching: false,
    designers: []
};