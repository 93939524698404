import {BackendBrand} from "../ActionCreators/Brands";

export interface BrandsState {
    isSearching: boolean,
    brands: BackendBrand[]
}

export const initialState: BrandsState = {
    isSearching: false,
    brands: []
};