import styled from "styled-components";
import {Button as MaterialButton, DialogActions, DialogContent} from "@material-ui/core";

export const Button = styled(MaterialButton)`
  flex-shrink: 0;
  margin-left: 8px;
`

export const Actions = styled(DialogActions)`
  border: 1px solid rgb(238, 238, 238);
  position: absolute;
  right: 24px;
  bottom: 24px;
  background-color: white;
`

export const Content = styled(DialogContent)`
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 8px !important;
`

export const LeftColumn = styled.div`
  flex-grow: 1;
  padding: 24px 40px;
  overflow: auto;

  img {
    width: 100%;
  }
`

export const RightColumn = styled.div`
  width: 400px;
  flex-shrink: 0;
  padding: 24px;

  .price {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .desc {
    .title {
      font-size: 16px;
      margin-bottom: 4px;
    }

    .attribute {
      border-bottom: 1px solid lightgray;
      padding-bottom: 16px;
      margin-bottom: 16px;

      .attribute-element {
        span {
          margin-right: 4px;
        }
      }
    }
  }
`

export const Element = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;

  .title {
    margin-bottom: 8px;
    line-height: 20px;
  }

  .content {
    display: flex;
    border: 1px solid black;
    padding: 8px;

    .image {
      margin-right: 8px;
      display: flex;

      img {
        width: 100px;
      }
    }

    .variant-description {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .select {
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    position: absolute;
    top: 28px;
    z-index: 1;
    background: white;
    left: 0;
    width: 100%;
    max-height: 190px;
    border: 1px solid black;
    overflow: auto;

    .content {
      border: none;
    }
  }
`