import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../Store";
import {useHistory, useParams} from "react-router";
import {Tab} from "@material-ui/core";
import * as Styled from "./Admin.styled";
import Users from "./Users/Users";
import MassChanges from "./MassChanges/MassChanges";
import Tools from "./Tools/Tools";

const ADMIN_OPTIONS: { key: number, name: string, forMasterAdmin?: boolean }[] = [
    {
        key: 0,
        name: "MASOWE ZMIANY"
    },
    {
        key: 1,
        name: "UŻYTKOWNICY"
    },
    {
        key: 2,
        name: "NARZĘDZIA",
        forMasterAdmin: true
    }
];

const Admin: React.FC = () => {
    const isAdmin = useSelector<AppState>(state => !!state.User.user?.permissions?.admin);
    const isMasterAdmin = useSelector<AppState>(state => !!state.User.user?.permissions?.admin && state.User.user.login === "michaau");
    const history = useHistory();
    const {tab} = useParams<{ tab: string }>();
    const selectedTabKey = tab ? parseInt(tab) : 0;

    useEffect(() => {
        if (!isAdmin) {
            history.replace("/");
        }
    }, [isAdmin, history])

    return <Styled.Main>
        <Styled.Tabs value={selectedTabKey}
                     onChange={(e, newTabKey) => {
                         history.replace({
                             ...history.location,
                             pathname: `/admin/${newTabKey}/`
                         })
                     }}>
            {ADMIN_OPTIONS.filter(option => !option.forMasterAdmin || option.forMasterAdmin === isMasterAdmin).map(tab =>
                <Tab key={tab.key} label={tab.name}/>)}
        </Styled.Tabs>
        <Styled.Content>
            <MassChanges hidden={selectedTabKey !== 0}/>
            <Users hidden={selectedTabKey !== 1}/>
            <Tools hidden={selectedTabKey !== 2}/>
        </Styled.Content>
    </Styled.Main>
}

export default Admin;