import React, {useEffect} from "react";
import styles from "./BrandView.module.scss";
import axios from "axios";
import {getAxiosConfig} from "../../ActionCreators/User";
import {Brand} from "mesmetric-v2-common/models";
import {RouteChildrenProps} from "react-router";
import Spinner from "../ProductView/Common/Spinner/Spinner";
import Controls from "./Controls/Controls";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../Store";
import {Action} from "redux";
import {removeBrandData, setBrandData} from "../../ActionCreators/BrandData";
import BasicData from "./BasicData/BasicData";
import Descriptions from "./Descriptions/Descriptions";
import UploadedFiles from "./UploadedFiles/UploadedFiles";
import FilesUpload from "./FilesUpload/FilesUpload";

type DispatchProps = {
    setBrandData: (brand: Brand) => void,
    removeBrandData: () => void
}

type StateProps = {
    brandLoaded: boolean
}

type BrandViewProps = RouteChildrenProps<{ id: string }> & DispatchProps & StateProps;

const BrandView: React.FC<BrandViewProps> = (props) => {

    useEffect(() => {
        (async () => {
            const response = await axios.get<Brand>(`${process.env.REACT_APP_DATA_ENDPOINT}/brands/${props.match?.params.id}`, getAxiosConfig());
            props.setBrandData(response.data);
        })()
        return () => {
            props.removeBrandData();
        }
    }, [])

    return <div className={styles.content}>
        {!props.brandLoaded && <Spinner size={64}/>}
        {props.brandLoaded && <>
            <div className={"row"}>
                <div className={"col-12"}>
                    <Controls className={styles.controls}/>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-6"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <BasicData/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <Descriptions/>
                        </div>
                    </div>
                </div>
                <div className={"col-6"}>
                    <FilesUpload/>
                    <UploadedFiles/>
                </div>
            </div>
        </>}
    </div>
}

const mapStateToProps = (state: AppState): StateProps => ({
    brandLoaded: state.BrandData.brandData !== undefined
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    setBrandData: (brand: Brand) => dispatch(setBrandData(brand)),
    removeBrandData: () => dispatch(removeBrandData())
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandView);