import React, {Component} from "react";
import AddItem from "../AddItem/AddItem";
import {IconButton} from "@material-ui/core";
import {Menu, ViewComfy} from "@material-ui/icons";
import Columns from "./Columns";
import {ItemData} from "../ItemForm/ItemForm";
import {AppState} from "../../../Store";
import {connect} from "react-redux";
import {setViewType, ViewType} from "../../../ActionCreators/Products";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import styles from "./Tools.module.scss";
import FilterControls from "./FilterControls";
import FiltersCount from "./FiltersCount";

interface ExternalProps {
    addItem: (data: ItemData) => Promise<string | void>
}

interface StateProps extends ExternalProps {
    viewType: ViewType
}

interface DispatchProps {
    setViewType: (viewType: ViewType) => void
}

type Props = StateProps & DispatchProps;

class Tools extends Component<Props> {
    public render = (): JSX.Element =>
        <div className={styles.headerComponent}>
            <div className={styles.left}>
                <AddItem
                    doAction={this.props.addItem}
                    className={`${styles.button} ${styles.add}`}
                />
                <div className={styles.view}>
                    <IconButton
                        className={styles.button}
                        title={"Widok tabeli"}
                        disabled={this.props.viewType === "TABLE"}
                        onClick={() => this.props.setViewType("TABLE")}>
                        <Menu/>
                    </IconButton>
                    <IconButton
                        className={styles.button}
                        title={"Widok grid"}
                        disabled={this.props.viewType === "GRID"}
                        onClick={() => this.props.setViewType("GRID")}>
                        <ViewComfy/>
                    </IconButton>
                </div>
                <FilterControls/>
            </div>
            <div className={styles.right}>
                <FiltersCount/>
                {this.props.viewType === "TABLE" && <Columns/>}
            </div>
        </div>
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => {
    return {
        ...externalProps,
        viewType: state.Products.viewType,
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    setViewType: viewType => dispatch(setViewType(viewType))
});

export default connect(mapStateToProps, mapDispatchToProps)(Tools);