import React, {Component} from "react";
import {connect} from "react-redux";
import _ from "lodash";
import {Files} from "mesmetric-v2-common/models";
import {AppState} from "../../../../../Store";
import {updateValue} from "../../../../../ActionCreators/ProductData";
import PictureAsPdfOutlined from "@material-ui/icons/PictureAsPdfOutlined";
import Filter2Outlined from "@material-ui/icons/Filter2Outlined";
import Filter3Outlined from "@material-ui/icons/Filter3Outlined";
import Square from "../Square/Square";
import Button from "@material-ui/core/Button";
import CloudDownloadOutlined from "@material-ui/icons/CloudDownloadOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import "./brochure2D3D.scss";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";

interface ExternalProps {
    productId: string,
    path: string,
}

interface StateProps {
    productId: string,
    files?: Files
}

interface DispatchProps {
    onChange: (value: Files) => void
}

type Brochure2D3DProps = StateProps & DispatchProps;

interface FileUploadedProps {
    fileSrc: string,
    onDeleteClicked: () => void,
    icon: JSX.Element,
    fileName?: string
}

const FileUploaded: React.FC<FileUploadedProps> = ({icon, fileSrc, onDeleteClicked, fileName}) =>
    <div className={"row brochure-2D-3D"}>
        <div className={"col-8"}>
            <Square className={"uploaded-file"}>
                {icon}
                {fileName && <div className={"file-name"}>
                    <span
                        className={"name"}>{fileName.slice(0, -4)}</span>.<span>{fileName.slice(fileName.length - 3, fileName.length)}</span>
                </div>}
            </Square>
        </div>
        <div className={"col-4"}>
            <div className={"buttons"}>
                <Button
                    className={"download-button"}
                    size="small"
                    href={fileSrc}
                    target={"_blank"}
                    variant="contained">
                    <CloudDownloadOutlined/>
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    onClick={onDeleteClicked}
                    color="secondary">
                    <DeleteIcon/>
                </Button>
            </div>
        </div>
    </div>;

class Brochure2D3D extends Component<Brochure2D3DProps> {
    static defaultProps = {
        files: {}
    };

    public render = (): JSX.Element =>
        <>
            {this.props.files?.brochure &&
            <FileUploaded
                icon={<PictureAsPdfOutlined/>}
                fileName={this.props.files.brochure.originalFilename}
                fileSrc={this.props.files.brochure.srcResolved || ""}
                onDeleteClicked={() => this.props.onChange(_.omit(this.props.files, "brochure"))}/>}
            {this.props.files?.brochures?.map((brochure, index) =>
                <FileUploaded
                    key={index}
                    icon={<PictureAsPdfOutlined/>}
                    fileSrc={brochure.srcResolved || ""}
                    fileName={brochure.originalFilename}
                    onDeleteClicked={() => {
                        const brochures = this.props.files?.brochures?.filter(el => el !== brochure);
                        this.props.onChange({...this.props.files, brochures: brochures?.length ? brochures : undefined})
                    }
                    }/>)}
            {this.props.files?.["2d"] &&
            <FileUploaded
                icon={<Filter2Outlined/>}
                fileName={this.props.files["2d"].originalFilename}
                fileSrc={this.props.files["2d"].srcResolved || ""}
                onDeleteClicked={() => this.props.onChange(_.omit(this.props.files, "2d"))}/>}
            {this.props.files?.["3d"] &&
            <FileUploaded
                icon={<Filter3Outlined/>}
                fileName={this.props.files["3d"].originalFilename}
                fileSrc={this.props.files["3d"].srcResolved || ""}
                onDeleteClicked={() => this.props.onChange(_.omit(this.props.files, "3d"))}/>}
        </>
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    productId: externalProps.productId,
    files: _.get(state.ProductData.productData, externalProps.path)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onChange: (value: Files) => dispatch(updateValue(externalProps.path, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Brochure2D3D);