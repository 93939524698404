import React, {Component, RefObject} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Popper from "@material-ui/core/Popper";
import styles from "./RemoveUser.module.scss";
import {Alert} from "@material-ui/lab";
import {LinearProgress} from "@material-ui/core";

interface Props {
    deleteUser: () => Promise<string | void>
}

interface State {
    opened: boolean,
    inProgress: boolean,
    errorMessage?: string
}

class RemoveUser extends Component<Props, State> {

    state: State = {
        opened: false,
        inProgress: false
    };

    private readonly removeButton: RefObject<HTMLButtonElement> = React.createRef();

    public render = (): JSX.Element =>
        <>
            <Button
                ref={this.removeButton}
                size="small"
                variant="contained"
                onClick={() => this.setState({opened: !this.state.opened})}
                color="secondary">
                <DeleteIcon/>
            </Button>
            <Popper open={this.state.opened} anchorEl={this.removeButton.current}
                    placement={"bottom"}
                    transition>
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <div className={styles.popper}>
                            <span>Czy na pewno chcesz usunąć tego użytkownika?</span>
                            {this.state.errorMessage &&
                            <Alert className={styles.error} severity="error">{this.state.errorMessage}</Alert>}
                            <div className={styles.buttons}>
                                {this.state.inProgress &&
                                <LinearProgress color={"secondary"} className={styles.remove}/>}
                                {!this.state.inProgress && <Button
                                    variant="contained"
                                    color="secondary"
                                    className={styles.remove}
                                    onClick={async () => {
                                        this.setState({
                                            errorMessage: undefined,
                                            inProgress: true
                                        });
                                        const errorMessage = await this.props.deleteUser();
                                        if (errorMessage) {
                                            this.setState({
                                                errorMessage,
                                                inProgress: false
                                            })
                                        }
                                    }}
                                >
                                    <span>Usuń</span>
                                </Button>}
                                <Button
                                    disabled={this.state.inProgress}
                                    color="primary"
                                    onClick={() => this.setState({opened: false})}>
                                    <span>Anuluj</span>
                                </Button>
                            </div>
                        </div>
                    </Fade>
                )}
            </Popper>
        </>
}

export default RemoveUser;