import {ActionTypes, AllActions} from "../ActionCreators/ProductsFilters";
import {initialState} from "../State/ProductsFilters";
import {ProductsFilters} from "mesmetric-v2-common/models";

export default function ProductsFiltersReducer(state = initialState, action: AllActions): ProductsFilters {
    switch (action.type) {
        case ActionTypes.setProductsFilter: {
            return {
                ...state,
                [action.field]: action.value
            }
        }
        default:
            return state;
    }
}

