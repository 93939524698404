export interface Notification {
    message: string,
    type: "error"
}

export interface NotificationsState {
    notifications: Notification[],
}

export const initialState: NotificationsState = {
    notifications: []
};