import {
    Category,
    ProductAttribute,
    ProductAttributeGroup,
    ProductFilter,
    ProductFilterGroup,
    ProductTemplate
} from "mesmetric-v2-common/models";

export type setDataAction = {
    type: actionTypes.SetData,
    data: ProductTemplate
}

export type setCategoriesAction = {
    type: actionTypes.SetCategories,
    data: Category[]
}

export type updateGroupAction = {
    type: actionTypes.UpdateGroup,
    data: ProductAttributeGroup
}

export type removeGroupAction = {
    type: actionTypes.RemoveGroup,
    data: {
        groupId: string
    }
}

export type updateAttributeAction = {
    type: actionTypes.UpdateAttribute,
    data: {
        path: string,
        attribute: ProductAttribute.Any
    }
}

export type addGroupAction = {
    type: actionTypes.AddGroup,
    data: ProductAttributeGroup
}

export type addAttributeAction = {
    type: actionTypes.AddAttribute,
    data: {
        groupId: string,
        attribute: ProductAttribute.Any
    }
}

export type removeAttributeAction = {
    type: actionTypes.RemoveAttribute,
    data: {
        groupId: string,
        attributeId: string
    }
}

export type updateFilterGroupAction = {
    type: actionTypes.UpdateFilterGroup,
    data: ProductFilterGroup
}


export type addFilterAction = {
    type: actionTypes.AddFilter,
    data: ProductFilter
}

export type removeFilterAction = {
    type: actionTypes.RemoveFilter,
    data: {
        filterId: string
    }
}

export type updateFilterAction = {
    type: actionTypes.UpdateFilter,
    data: {
        path: string,
        filter: ProductFilter
    }
}

export enum actionTypes {
    SetData = 'set-data',
    SetCategories = 'set-categories',
    AddGroup = 'add-group',
    UpdateGroup = 'update-group',
    RemoveGroup = 'remove-group',
    UpdateFilterGroup = 'update-filter-group',
    UpdateAttribute = 'update-attribute',
    AddAttribute = 'add-attribute',
    RemoveAttribute = 'remove-attribute',
    UpdateFilter = 'update-filter',
    AddFilter = 'add-filter',
    RemoveFilter = 'remove-filter'
}

export type allActions =
    setDataAction
    | setCategoriesAction
    | updateAttributeAction
    | updateGroupAction
    | addGroupAction
    | removeGroupAction
    | addAttributeAction
    | removeAttributeAction
    | updateFilterGroupAction
    | addFilterAction
    | removeFilterAction
    | updateFilterAction;
