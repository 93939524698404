import React from "react"
import PaddedPaper from "../Components/PaddedPaper/PaddedPaper";

/**
 * @return {boolean}
 */

export default function CategoryListing(props: any) {
    return (
        <PaddedPaper>
            <div>In progress</div>
        </PaddedPaper>
    );
}