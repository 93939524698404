import React from "react";
import Box from "../../ProductView/Common/Box/Box";
import TextField from "../../ProductView/Common/TextField/TextField";

const BasicData: React.FC = () => {
    return <Box title={"Dane marki"}>
        <TextField
            label={"Nazwa"}
            path={"name"}
            dataType={"Brand"}
        />
        <TextField
            label={"Kod"}
            path={"code"}
            dataType={"Brand"}
        />
        <TextField
            label={"Mnożnik"}
            path={"priceMultiplier"}
            type={"number"}
            dataType={"Brand"}
        />
        <TextField
            label={"Kolejność"}
            path={"order"}
            type={"number"}
            dataType={"Brand"}
        />
    </Box>
}

export default BasicData;