import React, {ReactElement, ReactNode, useEffect, useMemo, useRef} from "react";
import {Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import {get} from 'lodash'
import {TableProps} from "@material-ui/core/Table";

const defaultTransforms: {
    [fieldPath: string]: CellTransform<any>
} = {};

const getDefaultTransform = (column: ColumnDefinition<any>, incompleteCheck?: (toCheck: any) => boolean): CellTransform<any> | undefined => {
    if (!column.accessPath) {
        return undefined;
    }
    return defaultTransforms[column.accessPath] || (defaultTransforms[column.accessPath] = (row) => {
        if (incompleteCheck && incompleteCheck(row)) {
            return <>{get(row, column.accessPath as string, '-')}<span style={{paddingLeft: "4px", color: "red"}}>(Brak tłumaczeń)</span></>;
        }
        return get(row, column.accessPath as string, '-')
    });
};

type CellTransform<RowType> = (row: RowType) => ReactNode | undefined

export enum filterTypes {
    TextField = 'textField',
    Select = 'select',
    Toggle = 'toggle'
}


export interface ColumnDefinition<RowType> {
    label: string
    name?: string
    align?: 'center' | 'left' | 'right'
    accessPath?: string
    transform?: CellTransform<RowType>
    onCLick?: (event: any) => any
}

export interface MesmetricTableProps<RowType> {
    rows: RowType[],
    columns: ColumnDefinition<RowType>[],
    showFilter?: boolean,
    tableProps?: TableProps,
    children?: ReactNode,
    rowOnClick?: (row: RowType) => void,
    rowKeyCallback?: (row: RowType) => string,
    incompleteCheck?: (row: RowType) => boolean
}

const MesmetricTable = <RowType extends {}>(props: MesmetricTableProps<RowType>): ReactElement => {
    const lastRowRef = useRef<HTMLTableRowElement>(null);
    useEffect(() => {
        if (lastRowRef.current === null) {
            return;
        }
        const current = lastRowRef.current;
        const onScroll = () => {
            let offset = 0;
            const top = current.getBoundingClientRect().top;
            return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
        };
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [props.rows]);
    const body = useMemo(() => <TableBody>
            {props.rows.map((row, i) => (
                <TableRow ref={i === props.rows.length - 1 ? lastRowRef : undefined}
                          style={props.rowOnClick ? {cursor: 'pointer'} : undefined}
                          onClick={props.rowOnClick ? () => (props.rowOnClick && props.rowOnClick(row)) : undefined}
                          key={props.rowKeyCallback ? props.rowKeyCallback(row) : i}>
                    {props.columns.map(column => {
                        const transform = column.transform || getDefaultTransform(column, props.incompleteCheck);
                        return <TableCell key={`cell-${column.name}`}
                                          style={{width: 100}}
                                          align={column.align || 'left'}
                                          onClick={column.onCLick}>
                            {transform && transform(row)}
                        </TableCell>
                    })}
                    {props.children}
                </TableRow>
            ))}
        </TableBody>, [props.rows]
    );
    return <Table {...props.tableProps}>
        {body}
    </Table>
};

export default MesmetricTable