import React, {Dispatch, Reducer, ReducerAction, useContext, useEffect, useReducer} from "react";
import * as models from "mesmetric-v2-common/models";
import {Category} from "mesmetric-v2-common/models";
import TreeItem from "@material-ui/lab/TreeItem"
import {TreeView} from "@material-ui/lab";
import {withStyles} from "@material-ui/styles";
import {Typography} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import lodash from "lodash";
import update from "immutability-helper";
import {TreeViewProps} from "@material-ui/lab/TreeView";
import {AppState} from "../Store";
import {connect, useSelector} from "react-redux";
import Dictionary from "../DataProviders/Dictionary";

export type selectedCategoryNode = {
    category: models.Category,
    isSelected: boolean,
    isDisabled: boolean,
    path: string,
    children: selectedCategoryNodes
}

export type selectedCategoryNodes = {
    [categoryId: string]: selectedCategoryNode
}

const StyledTreeItem = React.memo(withStyles(theme => ({
    label: {
        fontSize: '0.875rem'
    },
    group: {
        marginLeft: '0px'
    }
}))(TreeItem));

const StyledCheckbox = React.memo(withStyles(theme => ({
    root: {
        '& .MuiSvgIcon-root': {
            fontSize: '1.25rem'
        }
    },

}))(Checkbox));

const setIsSelectedValueForChildren = (children: selectedCategoryNodes, isSelectedValue: boolean): selectedCategoryNodes => {
    const spec = Object.entries(children).reduce((updateSpec, [id, childNode]) => {
        const nodeUpdateSpec: any = childNode.isDisabled ? {} : {
            isSelected: {
                $set: isSelectedValue
            }
        };
        if (!lodash.isEmpty(childNode.children)) {
            nodeUpdateSpec['children'] = {
                $set: setIsSelectedValueForChildren(childNode.children, isSelectedValue)
            }
        }
        updateSpec[id] = nodeUpdateSpec;
        return updateSpec;
    }, {} as any);
    return update(children, spec);
}

enum childrenStates {
    true,
    false,
    indeterminate
}

const getChildrenState = (node: selectedCategoryNode): childrenStates => {
    const selectedValues = new Set<childrenStates>();
    Object.values(node.children).forEach(childNode => {
        selectedValues.add(childNode.isSelected ? childrenStates.true : childrenStates.false);
        if (!lodash.isEmpty(childNode.children)) {
            selectedValues.add(getChildrenState(childNode));
        }
    });
    return selectedValues.size === 1 ? selectedValues.values().next().value : childrenStates.indeterminate;
}

const getChildrenDisabledState = (node: selectedCategoryNode): childrenStates => {
    const selectedValues = new Set<childrenStates>();
    Object.values(node.children).forEach(childNode => {
        selectedValues.add(childNode.isDisabled ? childrenStates.true : childrenStates.false);
        if (!lodash.isEmpty(childNode.children)) {
            selectedValues.add(getChildrenDisabledState(childNode));
        }
    });
    return selectedValues.size === 1 ? selectedValues.values().next().value : childrenStates.indeterminate;
}


const CategoryTreeItem = React.memo(({path, currentNode}: {
    path: string,
    currentNode: selectedCategoryNode,
}) => {
    const labelCallback = useContext(LabelCallbackContext)
    let treeChildren: Array<JSX.Element> = [];
    const dispatch = useContext(SelectedNodesDispatchContext);
    if (currentNode.children && !lodash.isEmpty(currentNode.children)) {
        treeChildren = renderNodes(path, currentNode.children);
    }
    const childrenState = getChildrenState(currentNode);
    if (!lodash.isEmpty(currentNode.children)) {
        currentNode.isSelected = childrenState === childrenStates.true;
        currentNode.isDisabled = getChildrenDisabledState(currentNode) === childrenStates.true;
    }
    const label = <div>
        <Typography>{labelCallback ? labelCallback(currentNode) : currentNode.category.name.pl}</Typography>
    </div>;
    return <div style={{display: 'flex', alignItems: 'flex-start'}}>
        <StyledCheckbox
            disabled={currentNode.isDisabled}
            checked={currentNode.isSelected}
            onChange={(e, checked) => {
                dispatch(toggleNode(checked, currentNode))
            }}
            indeterminate={lodash.isEmpty(currentNode.children) ? false : childrenState === childrenStates.indeterminate}
        />
        <StyledTreeItem
            style={{marginTop: "8px"}}
            className={"category-item"}
            key={`category-${currentNode.category._id}`}
            label={label}
            nodeId={`category-${currentNode.category._id}`}>
            {treeChildren}
        </StyledTreeItem>
    </div>
});
CategoryTreeItem.displayName = 'CategoryTreeItem';
const renderNodes: (
    path: string,
    selectedNodes: selectedCategoryNodes,
) => Array<JSX.Element> =
    (path, selectedNodes) => {
        return Object.entries(selectedNodes).map(([categoryId, selectedCategory]) => {
            const itemPath = path + (path === '' ? '' : '.children.') + categoryId;
            return <CategoryTreeItem key={`category-${categoryId}`}
                                     path={itemPath}
                                     currentNode={selectedCategory}

            />
        })
    };

const getIdsWithChildren: (categories: Category[]) => string[] = ((categories) => {
    return categories.reduce((ids, currentCategory) => {
        if (currentCategory.children && currentCategory.children.length) {
            ids.push(currentCategory._id as string);
            ids = ids.concat(getIdsWithChildren(currentCategory.children as Category[]))
        }
        return ids;
    }, [] as string[]);
});


const createSelectedCategoryNodes: (categories: models.Category[], defaultSelected: string[], parentNode?: selectedCategoryNode, nodeCallback?: selectedNodeCallback) => selectedCategoryNodes = (categories, defaultSelected, parentNode, nodeCallback) => {
    return categories.reduce((selectedNodes, category) => {
        const node: selectedCategoryNode = {
            category: category,
            isDisabled: false,
            isSelected: defaultSelected.indexOf(category._id as string) !== -1,
            children: {},
            path: ((parentNode !== undefined) ? parentNode.path + '.children.' : '') + category._id
        };
        if (category.children && category.children.length) {
            node.children = createSelectedCategoryNodes(category.children as models.Category[], defaultSelected, node, nodeCallback);
        }
        selectedNodes[category._id as string] = nodeCallback ? nodeCallback(node) : node;
        return selectedNodes;
    }, {} as selectedCategoryNodes);
};


const OnCategoriesSelectedProvider = React.createContext<((categories: selectedCategoryNodes) => void) | undefined>(undefined);

type selectedNodeCallback = (categoryNode: selectedCategoryNode) => selectedCategoryNode
type labelCallback = (categoryNode: selectedCategoryNode) => string

type onCategoriesSelectedCallback = (selectedCategories: selectedCategoryNodes) => void

interface CategoryTreeProps {
    defaultSelected?: string[]
    onCategoriesSelected?: onCategoriesSelectedCallback
    selectedNodeCallback?: selectedNodeCallback
    labelCallback?: labelCallback,
    categories?: Category[]
}

export const getIdsOfSelectedCategoriesWithoutChildren: (selected: selectedCategoryNodes) => string[] = selected => {
    return getSelectedCategoriesWithoutChildren(selected).map(category => category._id || '');
}

export const getSelectedCategoriesWithoutChildren: (selected: selectedCategoryNodes) => Category[] = selected => {
    return Object.values(selected).reduce((ids, currentCategory) => {
        if (!lodash.isEmpty(currentCategory.children)) {
            ids = ids.concat(getSelectedCategoriesWithoutChildren(currentCategory.children))
        } else {
            if (currentCategory.isSelected) {
                ids.push(currentCategory.category);
            }
        }
        return ids;
    }, [] as Category[]);

}

enum nodeActionTypes {
    toggleNode = 'toggleNode'
}

interface toggleNodeAction {
    type: nodeActionTypes.toggleNode,
    value: boolean,
    node: selectedCategoryNode
}

function toggleNode(value: boolean, node: selectedCategoryNode): toggleNodeAction {
    return {
        type: nodeActionTypes.toggleNode,
        value: value,
        node: node
    }
}

type selectedNodesActions = toggleNodeAction;

const getReducerWithOnChange: (onCategoriesSelected?: onCategoriesSelectedCallback) => Reducer<selectedCategoryNodes, selectedNodesActions> = onCategoriesSelected => {
    return (state, action) => {
        switch (action.type) {
            case nodeActionTypes.toggleNode: {
                const updateSpec = lodash.set({}, action.node.path, {
                    isSelected: {
                        $set: action.value
                    },
                    children: {
                        $set: setIsSelectedValueForChildren(action.node.children, action.value)
                    }
                });
                const selectedNodes = update(state, updateSpec);
                onCategoriesSelected && onCategoriesSelected(selectedNodes);
                return selectedNodes;
            }
            default:
                return state;
        }
    }
};
const SelectedNodesDispatchContext = React.createContext<Dispatch<ReducerAction<ReturnType<typeof getReducerWithOnChange>>>>(value => {
});
const LabelCallbackContext = React.createContext<labelCallback | undefined>(undefined)
const SelectedNodeCallbackContext = React.createContext<selectedNodeCallback | undefined>(undefined)

export const CategoryTree: React.FC<CategoryTreeProps & TreeViewProps> = (
    {
        onCategoriesSelected,
        defaultSelected,
        selectedNodeCallback,
        labelCallback,
        ...treeViewProps
    }) => {
    const categories = useSelector<AppState, Category[]>(state => state.Categories.tree || []);
    const [selectedNodes, selectedNodesDispatch] = useReducer(getReducerWithOnChange(onCategoriesSelected), createSelectedCategoryNodes(categories, defaultSelected || [], undefined, selectedNodeCallback));
    return (
        <LabelCallbackContext.Provider value={labelCallback}>
            <SelectedNodesDispatchContext.Provider value={selectedNodesDispatch}>
                <OnCategoriesSelectedProvider.Provider value={onCategoriesSelected}>
                    <TreeView
                        defaultExpanded={getIdsWithChildren(categories).map(id => `category-${id}`)} {...treeViewProps}>
                        {renderNodes('', selectedNodes)}
                    </TreeView>
                </OnCategoriesSelectedProvider.Provider>
            </SelectedNodesDispatchContext.Provider>
        </LabelCallbackContext.Provider>
    );
};

export const CategoryTreeWrapper: React.FC<CategoryTreeProps & TreeViewProps> = (props) => {
    useEffect(() => {
        Dictionary.getCategoriesTree();
    }, [])

    return (
        props.categories && props.categories.length ?
            <CategoryTree {...props}/>
            :
            <div>Wczytuję kategorie</div>
    );
};


const mapStateToProps = (state: AppState) => ({
    categories: state.Categories.tree
});


export const ConnectedCategoryTree = connect(mapStateToProps)(CategoryTreeWrapper);