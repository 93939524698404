import {Brand, File as FileModel} from "mesmetric-v2-common/models";
import {ThunkAction} from "redux-thunk";
import {AppState} from "../Store";
import _ from "lodash";
import AutoSaveManager from "../Helpers/AutoSaveManager";
import axios from "axios";
import {diff} from "deep-diff";
import {getAxiosConfig} from "./User";
import {parseError} from "./Error";
import {searchProducts} from "./Products";
import {SetProductDataAction} from "./ProductData";
import {fetchBrands} from "./Brands";

export enum ActionTypes {
    setBrandData = "setBrandData",
    removeBrandData = "removeBrandData",
    updateValue = "updateValue",
    saveInProgress = "saveInProgress",
    changesDetected = "changesDetected"
}

export interface SetBrandDataAction {
    type: ActionTypes.setBrandData
    brandData: Brand
}

export interface RemoveBrandDataAction {
    type: ActionTypes.removeBrandData
}

export interface SetChangesDetectedAction {
    type: ActionTypes.changesDetected,
    changesDetected: boolean
}

export interface UpdateValueAction {
    type: ActionTypes.updateValue,
    brandData: Brand
}

export interface SaveInProgressAction {
    type: ActionTypes.saveInProgress,
    saveInProgress: boolean
}

const SaveManager = new AutoSaveManager();

export const saveBrand = (): ThunkAction<void, AppState, {}, SetChangesDetectedAction | SaveInProgressAction> =>
    async (dispatch, getState) => {
        dispatch(saveInProgress(true));
        await dispatch(sendBrand());
    };

export const sendBrand = (): ThunkAction<void, AppState, {}, SetProductDataAction | SaveInProgressAction | SetChangesDetectedAction> =>
    async (dispatch, getState) => {
        const brandData = getState().BrandData.brandData;
        if (brandData) {
            SaveManager.attemptAutoSave().then(async () => {
                dispatch(saveInProgress(true));
                try {
                    const id = brandData._id;
                    const result = await axios.put<Brand>(`${process.env.REACT_APP_DATA_ENDPOINT}/brands/edit/${id}`, brandData, getAxiosConfig());
                    await dispatch(fetchBrands());
                    dispatch(searchProducts());
                    dispatch(setBrandData(result.data));
                    dispatch(saveInProgress(false));
                    dispatch({
                        type: ActionTypes.changesDetected,
                        changesDetected: false
                    });
                } catch (e) {
                    parseError(e);
                } finally {
                    SaveManager.done();
                }
            });
        }
    };

export const setBrandData = (brandData: Brand): ThunkAction<void, AppState, {}, SetBrandDataAction> =>
    async (dispatch) => {
        dispatch({
            type: ActionTypes.setBrandData,
            brandData
        });
    };

export function removeBrandData(): RemoveBrandDataAction {
    return {
        type: ActionTypes.removeBrandData
    };
}

export const saveInProgress = (saveInProgress: boolean): SaveInProgressAction => ({
    type: ActionTypes.saveInProgress,
    saveInProgress
});

const changeDetected = (actualBrand: Brand, fetchedBrand: Brand): boolean => {
    return !!diff(actualBrand, fetchedBrand);
};

export const addBrochure = (brochure: FileModel): ThunkAction<void, AppState, {}, UpdateValueAction | SetChangesDetectedAction> =>
    async (dispatch, getState) => {
        const brandData = getState().BrandData.brandData;
        if (!brandData) {
            return;
        }
        dispatch(updateValue("brochures", [...(brandData.brochures || []), brochure]))
    };

export const updateValue = (path: string, value: any): ThunkAction<void, AppState, {}, UpdateValueAction | SetChangesDetectedAction> =>
    async (dispatch, getState) => {
        const brandData = getState().BrandData.brandData;
        const fetchedBrandData = getState().BrandData.fetchedBrandData;
        if (!brandData || !fetchedBrandData) {
            return;
        }
        _.set(brandData, path, value);
        dispatch({
            type: ActionTypes.updateValue,
            brandData
        });
        const changesDetected = changeDetected(brandData, fetchedBrandData);
        dispatch({
            type: ActionTypes.changesDetected,
            changesDetected
        })
    };

export const revertBrandChanges = (): ThunkAction<void, AppState, {}, SetBrandDataAction | SetChangesDetectedAction> =>
    async (dispatch, getState) => {
        const fetchedBrandData = getState().BrandData.fetchedBrandData;
        if (!fetchedBrandData) {
            return;
        }
        dispatch(setBrandData(fetchedBrandData));
        dispatch({
            type: ActionTypes.changesDetected,
            changesDetected: false
        })
    };

export type AllActions =
    SetBrandDataAction
    | RemoveBrandDataAction
    | UpdateValueAction
    | SaveInProgressAction
    | SetChangesDetectedAction;